import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import { PageProps } from 'gatsby'
import { ParallaxProvider } from 'react-scroll-parallax'

import RootWrapper from './RootWrapper'
import MainWrapper from './MainWrapper'
import GlobalStyle from './GlobalStyle'
import NavBar from 'src/components/NavBar'
import Footer from 'src/components/Footer'
import ErrorBoundary from 'src/components/shared/ErrorBoundary'
import { trackPageViewEvent } from 'src/utils/event-tracking'
import favicon from 'src/assets/img/logos/sitting-dog.png'
import 'src/styles/global.css'

const NO_NAV_AND_NO_FOOTER = ['/consultation', '/giveaways', '/dashboard', '/friends-of-finn']
const NO_NAVBAR = [...NO_NAV_AND_NO_FOOTER, '/affiliate', 'tv', '/calmtv']
const NO_FOOTER = [...NO_NAV_AND_NO_FOOTER, '/my-pets']

const Layout: React.FC<PageProps> = ({ children, location }) => {
  const [hideNav, setHideNav] = useState(true)
  const [hideFooter, setHideFooter] = useState(true)

  const { pathname: currentPath, href: canonical } = location

  useEffect(() => {
    setHideNav(NO_NAVBAR.some((route) => currentPath.includes(route)))
    setHideFooter(NO_FOOTER.some((route) => currentPath.includes(route)))
  }, [currentPath])

  useEffect(() => {
    trackPageViewEvent(canonical)
  }, [canonical])

  return (
    <RootWrapper location={location}>
      <GlobalStyle />
      <ErrorBoundary errorText="Sorry something went wrong">
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          link={[
            { rel: 'icon', type: 'image/png', href: favicon },
            { rel: 'canonical', href: canonical }
          ]}
        />
        {!hideNav && <NavBar pathname={currentPath} />}
        <MainWrapper>
          <ParallaxProvider>{children}</ParallaxProvider>
        </MainWrapper>
        {!hideFooter && <Footer />}
      </ErrorBoundary>
    </RootWrapper>
  )
}

export default Layout
