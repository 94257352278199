interface ItemInterface {
  [key: string]: any
}

export default (items: ItemInterface[]) => {
  if (!items || !items.length) return [false, 0]

  const total = items.reduce((acc: number, item: ItemInterface) => {
    return acc + item.quantity
  }, 0)

  return [total !== 0, total]
}
