import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { useRecoilState } from 'recoil'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gsap, Power3 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'

import DropHeader from '../shared/DropHeader'
import { Headline, LargeP, SmallP } from '../shared/text'
import { PrimaryButton } from '../shared/buttons'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'
import scrollToTop from '../../utils/scrollToTop'

import { productDetailState } from '../../actions/products'

import { trackCollectionViewEvent, trackProductClickEvent } from '../../utils/event-tracking'

const wiggle = keyframes`
  0% { transform: rotate(0deg); }
  30% { transform: rotate(2deg) scale(1.1); }
  70% { transform: rotate(-2deg) scale(1.1); }
  100% { transform: rotate(0deg); }
`

const Section = styled.section`
  width: 100%;
  height: fit-content;

  background-color: ${colors.white};
  position: relative;

  ${media.tablet`
    padding: 0 0 80px 0;
  `}
`

const Grid = styled.div`
  width: 100%;

  justify-content: center;

  margin: 0 auto;

  ${media.mobile`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 60px;
  `}
  ${media.tablet`
    // display: flex;
    // flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 60px 30px;

    max-width: 750px;
    padding-top: 60px;
  `}
  ${media.desktop`
    max-width: 1400px;
    gap: 100px 50px;
    padding: 80px 30px 40px 30px;
  `}
`

const OutOfStockBadge = styled.div`
  background-color: ${colors.orange};
  border-radius: 50%;
  display: grid;
  place-items: center;
  height: 60px;
  width: 60px;
  z-index: 1;
  position: absolute;
  top: -10px;
  right: -10px;
  color: ${colors.navy} !important;
  font-family: ${fonts.larsseit.medium};
  font-size: 16px;
  line-height: 16px;
`

const ProductGroup = styled.div`
  display: grid;
  place-items: center;
  grid-row-gap: 12px;

  text-align: center;

  & img {
    transition: 200ms all ease;
  }

  ${media.mobile`
    padding: 24px 12px 32px 12px;
    border: 1px solid ${colors.lightText};
    height: 100%;
  `}
  ${media.tablet`
    padding: 0;
    border: none;
    height: fit-content;
    width: 225px;
    grid-template-rows: auto 30px 30px 65px 60px;
  `}
  ${media.desktop`
    width: 275px;
    grid-template-rows: 200px 30px 30px 65px 60px;
  `}
`

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: auto;
  margin: 0 auto;
  position: relative;

  ${media.mobile`
    width: 130px;
  `}
  ${media.tablet`
    width: 180px;
  `}
  ${media.desktop`
    width: 200px;
  `}

  &:hover {
    animation: ${wiggle} 250ms;
  }
`

const Description = styled(SmallP)`
  color: ${colors.slate};
  line-height: 1.4 !important;
  text-align: center;
  max-width: 275px;

  ${media.mobile`
    display: none;
  `}
  ${media.tablet`
    display: block;
    font-size: 14px;
  `}
  ${media.desktop`
    font-size: 15px;
  `}
`

const PriceText = styled(LargeP)`
  line-height: 1 !important;
  color: ${colors.orange};

  ${media.mobile`
    font-size: 26px !important;
  `}
  ${media.desktop`
    font-size: 30px !important;
    margin-top: -6px;
  `}
`

const ProductName = styled(Headline)`
  white-space: nowrap;

  ${media.mobile`
    font-size: 28px !important;
  `}
  ${media.desktop`
    font-size: 30px !important;
  `}
`

const PointerWrapper = styled.div`
  cursor: pointer;
`
const query = graphql`
  query {
    calmingAid: file(relativePath: { regex: "/tins/calming-aid.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
    multiVitamin: file(relativePath: { regex: "/tins/multivitamin.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
    hipAndJoint: file(relativePath: { regex: "/tins/hip-and-joint.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
    skinAndCoat: file(relativePath: { regex: "/tins/skin-and-coat.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
    probiotics: file(relativePath: { regex: "/tins/probiotics.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
    allergyAndItch: file(relativePath: { regex: "/tins/allergy-and-itch.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
  }
`

interface SinglesProps {
  isProductLibrary?: boolean
  title?: string
  buttonCopy?: string
  isPdp?: boolean
  isAlternate?: boolean
}

const Singles = (props: SinglesProps) => {
  const { isProductLibrary = false, title, buttonCopy, isPdp, isAlternate = false } = props

  const images = useStaticQuery(query)
  const [productMap] = useRecoilState(productDetailState)
  const slugMap = productMap?.bySlug

  const calmingAid = {
    img: getImage(images.calmingAid),
    name: 'Calming Aid',
    description:
      'Help your pup through stressful times the natural way with organic herbs, chamomile & melatonin',
    slug: 'calming-aid',
    color: colors.navy,
    outOfStock: false
  }

  const products = [
    {
      img: getImage(images.multiVitamin),
      name: 'Multivitamin',
      description:
        'A nutritional foundation of essential vitamins, healthy Omega-3s & living probiotics',
      slug: 'multivitamin',
      outOfStock: false
    },
    {
      img: getImage(images.hipAndJoint),
      name: 'Hip & Joint',
      description:
        'The ultimate trio for joint support and tissue repair — Glucosamine, Chondroitin & MSM',
      slug: 'hip-and-joint',
      outOfStock: false
    },
    {
      img: getImage(images.skinAndCoat),
      name: 'Skin & Coat',
      description:
        'Promote healthy skin and a shiny coat with Wild Alaskan Salmon oil, Omega-3s & Biotin',
      slug: 'skin-and-coat',
      outOfStock: false
    },
    {
      img: getImage(images.probiotics),
      name: 'Probiotics',
      description:
        'Pre- and probiotic blend for healthy poops, proper digestion, and a more comfortable dog.',
      slug: 'digestive-probiotics',
      outOfStock: false
    },
    {
      img: getImage(images.allergyAndItch),
      name: 'Allergy & Itch',
      description:
        "The allergy support your dog's been itching for - powered by Bee Propolis, Colostrum, and Probiotics.",
      slug: 'allergy-and-itch',
      outOfStock: false
    }
  ]

  let displayedProducts = products
  if (!isAlternate) displayedProducts = [calmingAid, ...products]

  const { fetching } = productMap

  useEffect(() => {
    if (!isProductLibrary && !fetching && productMap) {
      const productSlugs = products.map((product) => product.slug)
      trackCollectionViewEvent(productMap, productSlugs, isPdp)
    }
  }, [fetching, isProductLibrary])

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.fromTo(
      '.productGroups',
      { opacity: 0, y: 50 },
      {
        scrollTrigger: '.productGroups',
        ease: Power3.easeOut,
        opacity: 1,
        duration: 1,
        y: 0,
        stagger: 0.25
      }
    )
  }, [])

  const handleCtaClick = () => {
    navigate('/products')
    scrollToTop()
  }

  const handleLinkClick = (slug: string) => {
    if (!productMap.fetching) {
      trackProductClickEvent(productMap, slug, isPdp)
    }
    navigate(`/products/${slug}`)
  }

  let currentPathname = ''
  if (typeof window !== 'undefined') currentPathname = window.location.pathname

  return (
    <Section>
      {title && (
        <DropHeader
          id="single-tins"
          title={title}
          fontSize={133}
          tabletFontSize={88}
          mobileFontSize={50}
          buttonCopy={buttonCopy}
          onClick={handleCtaClick}
        />
      )}

      <Grid className="productGroups">
        {slugMap &&
          displayedProducts.map((product) => {
            const productDetail = slugMap[product.slug]
            const OTPPrice = productDetail?.single?.pricing?.oneTime?.price || 0
            const hideProduct = currentPathname === `/products/${product.slug}`

            if (hideProduct) return null

            return (
              <ProductGroup className="productGroup" key={product.name}>
                <PointerWrapper onClick={() => handleLinkClick(product.slug)}>
                  <ImgWrapper>
                    {Boolean(product.outOfStock) && (
                      <OutOfStockBadge>
                        Sold <br />
                        Out
                      </OutOfStockBadge>
                    )}
                    <GatsbyImage
                      image={product.img}
                      alt={`${product.name} tin`}
                      style={{ height: '100%', width: '100%' }}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </ImgWrapper>
                </PointerWrapper>

                <PointerWrapper onClick={() => handleLinkClick(product.slug)}>
                  <ProductName as="p">{product.name}</ProductName>
                </PointerWrapper>

                <PriceText>{OTPPrice > 0 ? `$${OTPPrice.toFixed(2)}` : 'Out of Stock'}</PriceText>

                <Description>{product.description}</Description>

                {product.outOfStock ? (
                  <PrimaryButton copy="Out of Stock" bg={colors.mediumGrey} />
                ) : (
                  <PrimaryButton
                    bg={colors.navy}
                    color={colors.lightText}
                    copy="Shop Now"
                    onClick={() => navigate(`/products/${product.slug}`)}
                  />
                )}
              </ProductGroup>
            )
          })}
      </Grid>
    </Section>
  )
}

export default Singles
