import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useRecoilState } from 'recoil'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { LargeP, P, SmallP, MonoP } from '../../shared/text'
import { colors } from '../../../styles/variables'
import { media } from '../../../styles/util'

import { productDetailState } from '../../../actions/products'
import { storeState, removeVariantFromCart, addVariantToCart } from '../../../actions/checkout'
import {
  trackAddToCartEvent,
  trackRemoveFromCartEvent,
  trackCustomClickEvent
} from '../../../utils/event-tracking'

const enterAnimation = keyframes`
  0% { opacity: 0; transform: scale(0); }
  100% { opacity: 1; transform: scale(100%); }
`

const Container = styled('div')<{ borderColor: string }>`
  animation: ${enterAnimation} 200ms;
  cursor: pointer;

  width: 100%;
  margin-top: -26px;
  padding: 16px 18px 20px 10px;

  border: 2px solid ${({ borderColor }) => colors[borderColor]};

  display: grid;
  grid-template-columns: 80px 1fr;

  ${media.mobile`
    grid-column-gap: 9px;
  `}
  ${media.tablet`
    grid-column-gap: 15px;
  `}

  & p {
    line-height: 1;
  }
`

const RightGrid = styled.div`
  display: grid;
  grid-row-gap: 15px;
`

const FlexRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: end;
  grid-column-gap: 20px;

  & p:last-child {
    text-align: right;
  }
`

const Size16P = styled(P)`
  font-size: 16px !important;
`

const AtcText = styled(P)`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${colors.orange};
    text-decoration: none;
  }
`

const GreySmallP = styled(SmallP)`
  color: ${colors.slate};
`

const StyledMonoP = styled(MonoP)`
  color: white;
`

const query = graphql`
  query {
    calmingAid: file(relativePath: { regex: "/tins/calming-aid.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
    multivitamin: file(relativePath: { regex: "/tins/multivitamin.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
    hipAndJoint: file(relativePath: { regex: "/tins/hip-and-joint.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
    skinAndCoat: file(relativePath: { regex: "/tins/skin-and-coat.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
    chillPup: file(relativePath: { regex: "/tins/chill-pup-bundle.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 400)
      }
    }
    activePup: file(relativePath: { regex: "/tins/active-pup-bundle.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 400)
      }
    }
    prettyPup: file(relativePath: { regex: "/tins/pretty-pup-bundle.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 400)
      }
    }
  }
`

interface RecommendedInterface {
  lineItems: any
  upsellProduct: {
    productKey: string
    isSwap: boolean
  }
}

const Recommended = (props: RecommendedInterface) => {
  const { upsellProduct, lineItems } = props
  const [productKey, isSwap] = upsellProduct

  const images = useStaticQuery(query)
  const [store, setStore] = useRecoilState(storeState)
  const [productMap] = useRecoilState(productDetailState)

  const [product, setProduct] = useState(null)

  // Just uses the first line item to determine recommended product
  const lineItem = lineItems[0]
  const fullVariantId = lineItem.variant.id // e.g. "gid://shopify/ProductVariant/39848003141714"
  const variantIdBreakdown = fullVariantId.split('/')
  const variantId = variantIdBreakdown[variantIdBreakdown.length - 1]

  const productMapLoading = productMap.fetching || productMap.updating

  const { isSubscription } = !productMapLoading
    ? productMap.byVariantId[variantId]
    : { isSubscription: false }

  const productType = isSubscription && isSwap ? 'subscription' : 'oneTime'

  const price = product?.single.pricing[productType]?.price

  useEffect(() => {
    if (!productMapLoading) {
      setProduct(productMap?.bySlug[productKey])
    }
  }, [productMapLoading, productKey])

  if (!productKey) return null

  const handleAddToCart = async () => {
    const { checkout } = store

    if (!checkout.id) return

    if (isSwap) {
      await removeVariantFromCart(checkout?.id, lineItem.id, setStore)
      trackRemoveFromCartEvent(productMap, productKey, variantId, Number(lineItem.variant.price))
      trackCustomClickEvent('Upsell - Swap')
    }

    const detail = {
      productMap,
      variantId: product?.single.variantId.encoded,
      quantity: 1,
      frequency: null, // one-time
      checkoutId: checkout.id,
      setStore,
      subscription: null
    }

    await addVariantToCart(detail)
    trackAddToCartEvent(productMap, productKey, product?.single.variantId.decoded, Number(price))

    if (!isSwap) trackCustomClickEvent('Upsell - Quick add')
  }

  const products = {
    'calming-aid': {
      name: 'Calming Aid',
      img: getImage(images.calmingAid),
      color: 'teal'
    },
    'skin-and-coat': {
      name: 'Skin & Coat',
      img: getImage(images.skinAndCoat),
      color: 'pink'
    },
    multivitamin: {
      name: 'Multivitamin',
      img: getImage(images.multivitamin),
      color: 'white'
    },
    'hip-and-joint': {
      name: 'Hip & Joint',
      img: getImage(images.hipAndJoint),
      color: 'orange'
    },
    'active-pup-bundle': {
      name: 'Active Pup Bundle',
      img: getImage(images.activePup),
      color: 'orange'
    },
    'chill-pup-bundle': {
      name: 'Chill Pup Bundle',
      img: getImage(images.chillPup),
      color: 'teal'
    },
    'pretty-pup-bundle': {
      name: 'Pretty Pup Bundle',
      img: getImage(images.prettyPup),
      color: 'pink'
    }
  }

  const isSubscribeOption = productKey === 'active-pup-bundle' || productKey === 'pretty-pup-bundle'

  return productKey && product ? (
    <Container borderColor={products[productKey].color} onClick={handleAddToCart}>
      <GatsbyImage
        image={products[productKey].img}
        alt={`${products[productKey].name} bottle`}
        imgStyle={{ objectFit: 'contain' }}
      />
      <RightGrid>
        <Size16P>Recommended for you</Size16P>
        <FlexRow>
          <LargeP>{products[productKey].name}</LargeP>
          <GreySmallP>{isSwap && isSubscribeOption ? 'Subscription' : '1-pack'}</GreySmallP>
        </FlexRow>

        <FlexRow>
          <AtcText>{isSwap ? 'Swap ->' : 'Quick Add ->'}</AtcText>
          <StyledMonoP>${price}</StyledMonoP>
        </FlexRow>
      </RightGrid>
    </Container>
  ) : null
}

export default Recommended
