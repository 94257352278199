import qs from 'query-string'
import { v4 as uuidv4 } from 'uuid'

declare global {
  interface Window {
    [key: string]: any
  }
}

const ELEVAR_LOCALSTORAGE_KEY = '___ELEVAR_GTM_SUITE--lastCollectionPathname'

const validWindow = typeof window !== 'undefined' && !window.location.pathname.includes('giveaways')
const isProduction = process.env.NODE_ENV === 'production'

/** ***************************************************************************
  getGoogleClientId - triggers on cart initialization in actions/checkout.tsx
**************************************************************************** */

export const getGoogleClientId = (): Promise<string> => {
  return new Promise((resolve) => {
    let loaded = false

    window.dataLayer = window.dataLayer || []
    window.gtag =
      window.gtag ||
      function gtag() {
        window.dataLayer.push(arguments)
      }
    window.gtag('js', new Date())

    window.gtag(
      (function () {
        // this function is called after GA library initializes
        loaded = true

        if (!window || !window.ga || !window.ga.getAll) return resolve('')

        const trackers = window.ga.getAll()
        const tracker = trackers[0]
        const clientId: string = tracker && tracker.get('clientId')

        return resolve(clientId)
      })()
    )

    setTimeout(function () {
      if (!loaded) resolve('')
    }, 1500)
  })
}

/** ***************************************************************************
  initializeGaEnhancedEcommerce - triggers on cart initialization in
  actions/checkout.tsx.
**************************************************************************** */

export const initializeGaEnhancedEcommerce = async () => {
  const valid = validWindow && isProduction
  if (!valid) return null
  return await getGoogleClientId()
}

/** ***************************************************************************
  getQueryStringParameters - triggers on cart initialization in
  actions/checkout.tsx. Used to assign all query string parameters as
  customAttributes inside the cart.
**************************************************************************** */

export const getQueryStringParameters = () => {
  return validWindow ? qs.parse(window.location.search.substr(1)) : ''
}

/** ***************************************************************************
  formatProduct - iterates through products and construct the necessary
  product object that gets pushed to the dataLayer
**************************************************************************** */

const formatProduct = (
  productMap: any,
  slug: string,
  list: string,
  imagePath?: string,
  idx?: number,
  variantId?: string,
  price?: number
) => {
  if (!slug || !productMap?.bySlug) return {}

  const product = productMap.bySlug[slug]
  const imageSrc = `https://www.petfinn.com${imagePath}`

  const productName = slug.split('-').join(' ').toUpperCase()

  return {
    name: productName,
    id: slug,
    product_id: product?.single?.productId.decoded,
    variant_id: variantId || product?.single?.variantId.decoded,
    price: price || product?.single?.pricing?.oneTime?.price,
    position: idx || 0,
    list: list || '',
    brand: 'finn',
    category: '',
    image: imagePath ? imageSrc : ''
  }
}

/** ***************************************************************************
  pushToDataLayer - helper function
**************************************************************************** */

const pushToDataLayer = (dataLayerPayload: any) => {
  if (validWindow && isProduction) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(dataLayerPayload)
  } else {
    console.log(dataLayerPayload)
  }
}

/** ***************************************************************************
  setCollectionsPathname & fetchCollectionsPathname - helper functions
**************************************************************************** */

const setCollectionsPathname = () => {
  try {
    localStorage.setItem(ELEVAR_LOCALSTORAGE_KEY, window.location.pathname)
    console.log(`Setting Collections path: ${window.location.pathname}`)
  } catch (e) {
    console.log('localStorage.setItem() error: ', e)
  }
}

const fetchCollectionsPathname = () => {
  try {
    const collectionsPath = localStorage.getItem(ELEVAR_LOCALSTORAGE_KEY) || ''
    console.log(`Fetching Collections path: ${collectionsPath}`)
    return collectionsPath
  } catch (e) {
    console.log('localStorage.getItem() error: ', e)
    return ''
  }
}

/** ***************************************************************************
  trackPageViewEvent
**************************************************************************** */

export const trackPageViewEvent = (pathname: string) => {
  const dataLayerPayload = {
    event: 'gatsby-route-change',
    event_id: uuidv4(),
    page_location: pathname
  }

  pushToDataLayer(dataLayerPayload)
}

/** ***************************************************************************
  trackCollectionViewEvent - fires for all visits to Collections page,
  or Singles/Bundles (Homepage, PDPs, etc.)
**************************************************************************** */

export const trackCollectionViewEvent = (
  productMap: any,
  productSlugs: string[],
  isPdp?: boolean
) => {
  // hiddenProducts are still active on Shopify, but not being shown on the headless site
  const hiddenProducts = ['super-pup-bundle', 'super-pup-holiday', 'senior-pup-bundle']
  const trackedSlugs = productSlugs.filter((slug) => !hiddenProducts.includes(slug))

  const formattedProducts = trackedSlugs.map((slug, idx) =>
    formatProduct(productMap, slug, window.location.pathname, '', idx)
  )

  const dataLayerPayload = {
    event: 'dl_view_item_list',
    event_id: uuidv4(),
    ecommerce: {
      currencyCode: 'USD',
      impressions: formattedProducts
    }
  }

  // PDPs each have the 4 tins shown at the bottom, but we do not want to overwrite the
  // pathname in this case since that PDP's underlying product is already tagged with one
  if (!isPdp) setCollectionsPathname()

  pushToDataLayer(dataLayerPayload)
}

/** ***************************************************************************
  trackProductClickEvent - fires whenever link to PDP is clicked
**************************************************************************** */

export const trackProductClickEvent = (productMap: any, slug: string, isPdp?: boolean) => {
  let collectionsList = fetchCollectionsPathname()

  // Reassigns the store collectionsPathname to be the PDP since we prevented that
  // behavior when landing there to prevent overwriting it initially
  if (isPdp) {
    collectionsList = window.location.pathname
    setCollectionsPathname()
  }

  const clickedProduct = formatProduct(productMap, slug, collectionsList)

  const dataLayerPayload = {
    event: 'dl_select_item',
    event_id: uuidv4(),
    ecommerce: {
      currencyCode: 'USD',
      click: {
        actionField: { list: collectionsList },
        products: [clickedProduct]
      }
    }
  }

  pushToDataLayer(dataLayerPayload)
}

/** ***************************************************************************
  trackProductViewEvent - fires from each PDP
**************************************************************************** */

export const trackProductViewEvent = (productMap: any, slug: string, imagePath: string) => {
  const collectionsList = fetchCollectionsPathname()

  const viewedProduct = formatProduct(productMap, slug, collectionsList, imagePath)

  const dataLayerPayload = {
    event: 'dl_view_item',
    event_id: uuidv4(),
    ecommerce: {
      currencyCode: 'USD',
      detail: {
        actionField: { list: collectionsList },
        products: [
          {
            ...viewedProduct,
            inventory: '1'
          }
        ]
      }
    }
  }

  pushToDataLayer(dataLayerPayload)
}

/** ***************************************************************************
  updateProductViewEvent - fires when radio product selector clicked
**************************************************************************** */

export const updateProductViewEvent = (
  productMap: any,
  slug: string,
  variantId: string,
  price: number,
  imagePath: string
) => {
  const collectionsList = fetchCollectionsPathname()

  const updatedProduct = formatProduct(
    productMap,
    slug,
    collectionsList,
    imagePath,
    0,
    variantId,
    price
  )

  const dataLayerPayload = {
    event: 'dl_view_item',
    event_id: uuidv4(),
    ecommerce: {
      currencyCode: 'USD',
      detail: {
        actionField: { list: collectionsList },
        products: [
          {
            ...updatedProduct,
            inventory: '1'
          }
        ]
      }
    }
  }

  pushToDataLayer(dataLayerPayload)
}

/** ***************************************************************************
  trackAddToCartEvent - fires when ATC CTAs are clicked on Homepage,
  COllections, About, PDP, and inside Cart Rows via quantity toggle.
**************************************************************************** */

export const trackAddToCartEvent = (
  productMap: any,
  slug: string,
  variantId: string,
  price: number,
  frequency?: string
) => {
  const collectionsList = fetchCollectionsPathname()

  const addedProduct = formatProduct(productMap, slug, collectionsList, '', 0, variantId, price)

  const dataLayerPayload = {
    event: 'dl_add_to_cart',
    event_id: uuidv4(),
    ecommerce: {
      currencyCode: 'USD',
      add: {
        actionField: { list: collectionsList },
        products: [
          {
            ...addedProduct,
            quantity: '1',
            frequency
          }
        ]
      }
    }
  }

  pushToDataLayer(dataLayerPayload)
}

/** ***************************************************************************
  trackRemoveFromCartEvent - fires from Cart Rows via quantity toggle.
**************************************************************************** */

export const trackRemoveFromCartEvent = (
  productMap: any,
  slug: string,
  variantId: string,
  price: number
) => {
  const collectionsList = fetchCollectionsPathname()

  const removedProduct = formatProduct(productMap, slug, collectionsList, '', 0, variantId, price)

  const dataLayerPayload = {
    event: 'dl_remove_from_cart',
    event_id: uuidv4(),
    ecommerce: {
      currencyCode: 'USD',
      remove: {
        actionField: { list: collectionsList },
        products: [
          {
            ...removedProduct,
            quantity: '1'
          }
        ]
      }
    }
  }

  pushToDataLayer(dataLayerPayload)
}

/** ***************************************************************************
  trackSwapToSubscriptionEvent
**************************************************************************** */

export const trackSwapToSubscriptionEvent = (variantId: string) => {
  const dataLayerPayload = {
    event: 'swap-otp-to-subscription',
    event_id: uuidv4(),
    variant_id: variantId
  }

  pushToDataLayer(dataLayerPayload)
}

/** ***************************************************************************
  trackSwapToOtpEvent
**************************************************************************** */

export const trackSwapToOtpEvent = (variantId: string) => {
  const dataLayerPayload = {
    event: 'swap-subscription-to-otp',
    event_id: uuidv4(),
    variant_id: variantId
  }

  pushToDataLayer(dataLayerPayload)
}

/** ***************************************************************************
  trackViewCartEvent fires from (1) actions/checkout.tsx on ATC and (2) in
  NvaBar when opening the cart menu by clicking the cart icon.
**************************************************************************** */

export const trackViewCartEvent = (productMap: any, lineItems: any, subtotal: number) => {
  const cartItems = lineItems.map((lineItem: any, idx: number) => {
    const fullVariantId = lineItem.variant.id // e.g. "gid://shopify/ProductVariant/39848003141714"
    const variantIdBreakdown = fullVariantId.split('/')
    const variantId = variantIdBreakdown[variantIdBreakdown.length - 1]

    const variantMap = productMap?.byVariantId
    const slug = variantMap ? variantMap[variantId].slug : 'unknown'

    const collectionsList = fetchCollectionsPathname()

    const formattedProduct = formatProduct(
      productMap,
      slug,
      collectionsList,
      '',
      idx,
      variantId,
      lineItem.variant.price
    )

    return {
      ...formattedProduct,
      quantity: lineItem.quantity,
      variant: lineItem.title.toUpperCase()
    }
  })

  const dataLayerPayload = {
    event: 'dl_view_cart',
    event_id: uuidv4(),
    cart_total: subtotal,
    ecommerce: {
      currencyCode: 'USD',
      actionField: { list: 'Shopping Cart' },
      impressions: cartItems
    }
  }

  pushToDataLayer(dataLayerPayload)
}

/** ***************************************************************************
  These are used only for tracking consultation events
**************************************************************************** */

export const trackConsultationView = (screen: string) => {
  // Sends "Virtual Page View" representing the consultation screen
  const page = `/consultation/${screen}`

  const dataLayerPayload = {
    event: 'dl_view_consultation',
    event_id: uuidv4(),
    page_location: page
  }

  pushToDataLayer(dataLayerPayload)
}

export const trackCustomClickEvent = (name: string) => {
  const dataLayerPayload: { [key: string]: string } = {
    event: 'dl_custom_click_event',
    event_id: uuidv4(),
    event_name: name
  }

  pushToDataLayer(dataLayerPayload)
}
