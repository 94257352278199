import { atom } from 'recoil'
import Client from './utils/client'

import {
  initializeGaEnhancedEcommerce,
  getQueryStringParameters,
  trackViewCartEvent
} from '../utils/event-tracking'
import { captureCoupon } from '../utils/session-tracking'
import { SHOPIFY_CHECKOUT_ID } from '../utils/globalVariables'

export const storeState = atom({
  key: 'store',
  default: {
    fetching: true,
    updating: false,
    checkout: {
      id: null,
      lineItems: [],
      subtotalPrice: null
    }
  }
})

interface AttributeProps {
  key: string
  value: string
}

export const initializeCheckout = async (setStore: any, location: any) => {
  try {
    // Check for an existing cart.
    const isBrowser = typeof window !== 'undefined'
    const existingCheckoutID = isBrowser ? localStorage.getItem(SHOPIFY_CHECKOUT_ID) : ''

    const setCheckoutInState = (checkout: any) => {
      if (isBrowser && checkout) localStorage.setItem(SHOPIFY_CHECKOUT_ID, checkout.id)
      setStore((prevState: any) => ({ ...prevState, checkout, fetching: false }))
    }

    const customAttributes: AttributeProps[] = []

    const gaClientId = await initializeGaEnhancedEcommerce()
    if (gaClientId) customAttributes.push({ key: 'google-clientID', value: gaClientId })

    const coupon = captureCoupon(location.search)
    if (coupon) customAttributes.push({ key: 'coupon', value: coupon })

    const queryString = getQueryStringParameters()
    if (queryString) {
      const params = Object.keys(queryString)
      const formattedAttributes: AttributeProps[] = params.map((param) => ({
        key: param,
        value: String(queryString[param])
      }))
      formattedAttributes.forEach((attr) => customAttributes.push(attr))
    }

    const addTrackingAttributes = (checkout: any) => {
      if (checkout && checkout.id && customAttributes.length) {
        return Client.checkout.updateAttributes(checkout.id, { customAttributes })
      }
      return checkout
    }

    const createNewCheckout = () =>
      Client.checkout
        .create()
        .then((checkout: any) => {
          return addTrackingAttributes(checkout)
        })
        .catch((err: any) => {
          console.error('"createNewCheckout" error:', err, Client)
          Sentry.captureException(err)
        })

    const fetchCheckout = (id: string) =>
      Client.checkout
        .fetch(id)
        .then((checkout: any) => {
          return addTrackingAttributes(checkout)
        })
        .catch((err: any) => {
          localStorage.setItem(SHOPIFY_CHECKOUT_ID, '')
          console.error('"fetchCheckout" error:', err)
          Sentry.captureException(err)
        })

    if (existingCheckoutID) {
      try {
        const checkout = await fetchCheckout(existingCheckoutID)
        // Make sure this cart hasn’t already been purchased.
        if (checkout && !checkout.completedAt) {
          setCheckoutInState(checkout)
          return
        }
      } catch (e) {
        localStorage.setItem(SHOPIFY_CHECKOUT_ID, '')
        Sentry.captureException(e)
      }
    }

    const newCheckout = await createNewCheckout()
    setCheckoutInState(newCheckout)
  } catch (err) {
    console.error('initializeCheckout', err)
    Sentry.captureException(err)
  }
}

interface SubscriptionInterface {
  sellingPlans: Array<{
    id: string
    frequency: string
  }>
  price: number
  compareAtPrice: number
  priceDiscount: number
  percentageDiscount: string
}

interface AddToCartDetailProps {
  productMap: any
  variantId: string
  quantity: number
  frequency: number | null
  checkoutId: string
  setStore: (args: any) => void
  subscription: SubscriptionInterface | null
}

export const clearCart = (currentCheckout: any, setStore: (args: any) => void) => {
  const allIds = currentCheckout.lineItems.map((item) => item.id)
  if (!allIds.length) return currentCheckout
  return Client.checkout.removeLineItems(currentCheckout.id, allIds).then((checkout) => {
    setStore((prevState: any) => ({ ...prevState, checkout }))
    return checkout
  })
}

export const addVariantToCart = async (addToCartDetail: AddToCartDetailProps) => {
  try {
    const { productMap, variantId, quantity, frequency, checkoutId, setStore, subscription } =
      addToCartDetail

    setStore((prevState: any) => ({ ...prevState, updating: true }))

    const customAttributes: Array<AttributeProps> = []

    if (subscription) {
      // Filters for selling plan ID with matching frequency (i.e. 21, 30, 45, 90)
      const [selectedSellingPlan] = subscription.sellingPlans.filter((plan: any) => {
        return plan.frequency === frequency
      })
      customAttributes.push({ key: 'selling_plan', value: String(selectedSellingPlan.id) })
      customAttributes.push({ key: 'selling_plan_frequency', value: String(frequency) })
    }

    const lineItems = [{ variantId, quantity, customAttributes }]

    return await Client.checkout.addLineItems(checkoutId, lineItems).then((checkout: any) => {
      setStore((prevState: any) => ({ ...prevState, checkout, updating: false }))
      trackViewCartEvent(productMap, checkout.lineItems, checkout.subtotalPrice)
    })
  } catch (err) {
    console.error('addVariantToCart', err)
    Sentry.captureException(err)
  }
}

// only adds if existing SKU + frequency is not already in the cart
export const smartBulkAddVariantsToCart = async ({
  setStore,
  checkout,
  items
}: {
  setStore: any
  checkout: any
  items: any
}) => {
  try {
    setStore((prevState: any) => ({ ...prevState, updating: true }))
    await clearCart(checkout, setStore)
    const encodedItems = items.map(
      (item: {
        variantId: string
        quantity: number
        frequency: number
        subscription: SubscriptionInterface
      }) => {
        const { variantId, quantity, frequency, subscription } = item

        // Filters for selling plan ID with matching frequency (i.e. 21, 30, 45, 90) based on
        // weight of dog from survey
        const [selectedSellingPlan] = subscription?.sellingPlans.filter((plan: any) => {
          return plan.frequency === String(frequency)
        }) || [null]

        return {
          variantId,
          quantity,
          ...(frequency > 0 && {
            customAttributes: [
              { key: 'selling_plan', value: String(selectedSellingPlan.id) },
              { key: 'selling_plan_frequency', value: String(frequency) }
            ]
          })
        }
      }
    )

    if (encodedItems.length !== 0) {
      return await Client.checkout.addLineItems(checkout.id, encodedItems).then((checkout: any) => {
        setStore((prevState: any) => ({ ...prevState, checkout, updating: false }))
      })
    }

    setStore((prevState: any) => ({ ...prevState, updating: false }))
  } catch (e) {
    console.error('smartBulkAddVariantsToCart', e)
    Sentry.captureException(e)
  }
}

export const removeVariantFromCart = async (
  checkoutID: string,
  lineItemID: string,
  setStore: (args: any) => void
) => {
  try {
    setStore((prevState: any) => ({ ...prevState, updating: true }))
    return await Client.checkout.removeLineItems(checkoutID, [lineItemID]).then((checkout: any) => {
      setStore((prevState: any) => ({ ...prevState, checkout, updating: false }))
    })
  } catch (err) {
    console.error('removeVariantFromCart', err)
    Sentry.captureException(err)
  }
}

export const updateCartLineItem = async (
  checkoutID: string,
  lineItemID: string,
  quantity: number,
  setStore: () => void
) => {
  try {
    setStore((prevState: any) => ({ ...prevState, updating: true }))

    return await Client.checkout
      .updateLineItems(checkoutID, [{ id: lineItemID, quantity }])
      .then((checkout) => {
        setStore((prevState: any) => ({ ...prevState, checkout, updating: false }))
      })
  } catch (err) {
    console.error('updateCartLineItem', err)
    Sentry.captureException(err)
  }
}

export const convertToSubscription = async (
  lineItem: any,
  newFrequency: string,
  store: any,
  setStore: (args: any) => void,
  subscription: any,
  productMap: any
) => {
  try {
    setStore((prevState: any) => ({ ...prevState, updating: true }))
    let attributes: Array<AttributeProps> = []

    // Filters for selling plan with frequency match
    const [selectedSellingPlan] = subscription.sellingPlans.filter(
      (plan: any) => plan.frequency === newFrequency
    )

    attributes = [
      { key: 'selling_plan', value: selectedSellingPlan.id },
      { key: 'selling_plan_frequency', value: newFrequency }
    ]

    const updatedLineItem = [
      {
        id: lineItem.id,
        variantId: lineItem.variant.id,
        customAttributes: attributes
      }
    ]

    const updatedCheckout = await Client.checkout
      .updateLineItems(store.checkout.id, updatedLineItem)
      .then((checkout: any) => {
        setStore((prevState: any) => ({ ...prevState, checkout, updating: false }))
        trackViewCartEvent(productMap, checkout.lineItems, checkout.subtotalPrice)
      })

    return updatedCheckout
  } catch (err) {
    console.error('convertToSubscription', err)
    Sentry.captureException(err)
  }
}

export const convertToOtp = async (lineItem: any, store: any, setStore: (args: any) => void) => {
  try {
    setStore((prevState: any) => ({ ...prevState, updating: true }))

    const updatedLineItem = [
      { id: lineItem.id, variantId: lineItem.variant.id, customAttributes: [] }
    ]

    return await Client.checkout
      .updateLineItems(store.checkout.id, updatedLineItem)
      .then((checkout: any) => {
        setStore((prevState: any) => ({ ...prevState, checkout, updating: false }))
      })
  } catch (err) {
    console.error('convertToOtp', err)
    Sentry.captureException(err)
  }
}

export const updateSubscriptionFrequency = async (
  lineItemId: string,
  newFrequency: string,
  store: any,
  setStore: (args: any) => void,
  subscription: SubscriptionInterface
) => {
  try {
    setStore((prevState: any) => ({ ...prevState, updating: true }))

    // Filters for selling plan with frequency match
    const [selectedSellingPlan] = subscription.sellingPlans.filter(
      (plan: any) => plan.frequency === newFrequency
    )

    const updatedLineItem = [
      {
        id: lineItemId,
        customAttributes: [
          { key: 'selling_plan', value: selectedSellingPlan.id },
          { key: 'selling_plan_frequency', value: newFrequency }
        ]
      }
    ]

    return await Client.checkout
      .updateLineItems(store.checkout.id, updatedLineItem)
      .then((checkout: any) => {
        setStore((prevState: any) => ({ ...prevState, checkout, updating: false }))
      })
  } catch (err) {
    console.error('updateSubscriptionFrequency', err)
    Sentry.captureException(err)
  }
}

export const applyPromoCode = async (
  checkout: any,
  setStore: (args: any) => void,
  promoCode: string
) => {
  try {
    return Client.checkout.addDiscount(checkout.id, promoCode).then((checkout) => {
      setStore((prevState: any) => ({ ...prevState, checkout, updating: false }))
      return checkout
    })
  } catch (err) {
    console.error('applyPromoCode', err)
    Sentry.captureException(err)
  }
}

export const removePromoCode = async (checkout: any, setStore: (args: any) => void) => {
  try {
    return Client.checkout.removeDiscount(checkout.id).then((checkout) => {
      setStore((prevState: any) => ({ ...prevState, checkout, updating: false }))
      return checkout
    })
  } catch (err) {
    console.error('removePromoCode', err)
    Sentry.captureException(err)
  }
}
