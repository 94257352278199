import React from 'react'
import styled from 'styled-components'
import { InfoCircleOutlined } from '@ant-design/icons'

import { SmallP } from 'src/components/shared/text'
import { media } from 'src/styles/util'
import afterpayLogo from 'src/assets/img/pdp/afterpay/logo-white.png'

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3px;
`

const AfterpayCopy = styled(SmallP)`
  & img {
    height: 16px;
    margin: 0 3px;
  }

  ${media.mobile`
    text-align: center;
  `}
  ${media.tablet`
    text-align: left;
  `}
`

const StyledInfoIcon = styled(InfoCircleOutlined)`
  margin-left: 3px;
  cursor: pointer;
`

interface AfterpayRowProps {
  netTotal: number
  openAfterpayModal: () => void
}

const AfterpayRow = ({ netTotal, openAfterpayModal }: AfterpayRowProps) => {
  const monthlyAmt = netTotal / 4
  const formattedAmt = monthlyAmt.toFixed(2)
  const afterpayCopy = `or 4 payments of <strong>$${formattedAmt}</strong> with <img src="${afterpayLogo}" alt="Afterpay logo" /> `

  return (
    <Container>
      <AfterpayCopy>
        <span dangerouslySetInnerHTML={{ __html: afterpayCopy }} />
        <StyledInfoIcon onClick={openAfterpayModal} />
      </AfterpayCopy>
    </Container>
  )
}

export default AfterpayRow
