import React from 'react'
import styled from 'styled-components'

import { H6, P, InternalLink, ExternalLink } from 'src/components/shared/text'
import { colors } from 'src/styles/variables'
import { media, getScreenType } from 'src/styles/util'
import { LinkComponentProps } from 'src/types/navbar'

const StyledH6 = styled(H6)<{ active: boolean }>`
  width: fit-content;
  transition: 200ms color ease-in-out;
  color: ${(props) => (props.active ? colors.orange : colors.lightText)};
  &:hover {
    border-bottom: 3px solid ${colors.lightText};
  }
  ${media.mobile`
    font-size: 42px !important;
  `}
  ${media.tablet`
    font-size: 60px !important;
  `}
`

const StyledP = styled(P)<{ active: boolean; external?: boolean }>`
  width: fit-content;
  transition: 200ms color ease-in-out;
  color: ${(props) =>
    props.active ? colors.orange : props.external ? colors.lightText : colors.slate};
  margin-left: 6px;
  &:hover {
    border-bottom: 2px solid ${colors.slate};
  }
  ${media.mobile`
    font-size: 20px;
  `}
  ${media.tablet`
    font-size: 22px;
  `}
  ${media.desktop`
    font-size: 22px;
  `}
`

const NavLink: React.FC<LinkComponentProps> = ({ item, pathname, mainItems }) => {
  const isMobile = getScreenType() === 'mobile'

  if (isMobile && item.socialMedia) return null
  const LinkItem = item.external ? ExternalLink : InternalLink

  return (
    <LinkItem to={item.url}>
      {mainItems ? (
        <StyledH6 active={pathname === item.url} onClick={close}>
          {item.label}
        </StyledH6>
      ) : (
        <StyledP active={pathname === item.url} external={!!item.socialMedia} onClick={close}>
          {item.label}
        </StyledP>
      )}
    </LinkItem>
  )
}

export default NavLink
