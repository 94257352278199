import checkCartForSubscriptions from './checkCartForSubscriptions'

export default (checkout: any) => {
  let checkoutUrl = ''
  if (checkout && checkout.id) {
    const encodedCheckoutId = encodeURIComponent(checkout.id)
    const shopifyCheckoutUrl = checkout.webUrl.replace('petfinn.myshopify.com', 'shop.petfinn.com')

    checkoutUrl =
      checkCartForSubscriptions(checkout.lineItems) && encodedCheckoutId
        ? `https://shop.petfinn.com/pages/redirect/?checkout_id=${encodedCheckoutId}`
        : shopifyCheckoutUrl
  }

  return checkoutUrl
}
