import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 5;
`

const MainWrapper: React.FC = ({ children }) => {
  return <Container>{children}</Container>
}

export default MainWrapper
