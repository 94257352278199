/* eslint-disable max-len */

export const colors = {
  // Branded
  navy: '#161345',
  lightNavy: '#D4D0FF',
  pink: '#FFCFDB',
  darkPink: '#F0B2C1',
  peach: '#FFDFD7',
  orange: '#FF7F00',
  orangeForFall: '#FC891C',
  teal: '#60C4BF',
  slate: '#999999',
  lightGrey: '#E5E5E5',
  mediumGrey: '#D6D6D6',
  lightText: '#EBEBEB',
  lemon: '#FBE122',
  agGreen: '#60b561',

  // Unbranded
  white: '#FFFFFF',
  black: '#0A0A0A',
  success: '#37b635',
  warning: '#ec1818',
  lightBlue: '#D7ECFF',
  purple: '#a9a3e3'
}

export const fonts = {
  athletics: {
    medium: 'Athletics-Medium, Roboto, Helvetica Neue, Arial, sans-serif'
  },
  larsseit: {
    normal: 'Larsseit, Roboto, Helvetica Neue, Arial, sans-serif',
    regular: 'Larsseit-Regular, Roboto, Helvetica Neue, Arial, sans-serif',
    bold: 'Larsseit-Bold, Roboto, Helvetica Neue, Arial, sans-serif',
    medium: 'Larsseit-Medium, Roboto, Helvetica Neue, Arial, sans-serif',
    light: 'Larsseit-Light, Roboto, Helvetica Neue, Arial, sans-serif'
  },
  monosten: {
    normal: 'Monosten, Roboto, Helvetica Neue, Arial, sans-serif'
  }
}
