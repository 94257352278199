import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useWindowScroll, useLockBodyScroll } from 'react-use'
import { useRecoilState } from 'recoil'
import { ShoppingFilled } from '@ant-design/icons'
import { gsap, Power3 } from 'gsap'

import PromoBar from './PromoBar'
import NavDrawer from './NavDrawer'
import CartDrawer from './CartDrawer'
import Spinner from '../shared/Spinner'
import { NavButton } from '../shared/buttons'
import { MonoP, InternalLink } from '../shared/text'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

import useQuantity from '../../hooks/useQuantity'
import { storeState, removePromoCode } from '../../actions/checkout'
import { productDetailState } from '../../actions/products'
import { trackViewCartEvent } from '../../utils/event-tracking'
import useCart from 'src/hooks/useCart'

const Header = styled.header`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  .hidden {
    transform: translate(0, -200%);
  }
`

const Nav = styled('nav')<{ transparentBg: boolean; topOfPage: boolean }>`
  background-color: ${({ transparentBg }) => (transparentBg ? 'transparent' : colors.white)};
  box-shadow: ${({ topOfPage }) => (topOfPage ? 'none' : '0 2px 2px -2px rgba(0, 0, 0, 0.2)')};
  width: 100%;
`

const HeaderInner = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  margin: 0 auto;
  ${media.mobile`
    padding: 16px 10px 16px 30px;
    grid-template-columns: 82px 1fr;
    grid-template-rows: 33px;
  `}
  ${media.tablet`
    padding: 15px 20px;
    grid-template-rows: 45px;
    grid-template-columns: 1fr 105px 1fr;
  `}
  ${media.desktop`
    padding: 15px 40px;
  `}
`

const LogoLink = styled(InternalLink)`
  transition: 200ms transform ease-in-out;
  text-align: left;
  height: auto;
  &:hover {
    transform: scale(1.02);
  }
  ${media.mobile`
    width: 82px;
    grid-column: 1;
  `}
  ${media.tablet`
    width: 100px;
    grid-column: 2;
  `}
`

const RightGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Count = styled(MonoP)`
  font-weight: bolder;
  color: ${colors.white};
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 10;

  ${media.mobile`
    top: 48%;
  `}
  ${media.tablet`
    top: 43%;
  `}
`

const CartCounter = styled.div`
  cursor: pointer;
  transition: 200ms color ease-in-out;
  position: relative;
  ${media.mobile`
    margin-right: 12px;
    & svg {
      height: 36px;
      width: 32px;
    }
  `}
  ${media.tablet`
    margin-right: 12px;
    & svg {
      height: 40px;
      width: 36px;
    }
  `}
  ${media.desktop`
    margin-right: 20px;
  `}
  &:hover {
    & svg path {
      fill: ${colors.orange};
    }
    & ${Count} {
      transition: 200ms color ease-in-out;
      color: ${colors.white};
    }
  }
`

const MobileHide = styled.div`
  ${media.mobile`
    display: none;
  `}
  ${media.tablet`
    display: inline;
  `}
`

const DesktopHide = styled.div`
  ${media.mobile`
    display: inline;
  `}
  ${media.tablet`
    display: none;
  `}
`

const DrawerMask = styled.div`
  ${media.mobile`
    display: none;
  `}
  ${media.tablet`
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 1;
    z-index: 999;
  `}
`

const AccountButtonGroup = styled(MobileHide)`
  ${media.mobile`
    display: none;
  `}
  ${media.tablet`
    display: grid;
    place-items: center;
    grid-row-gap: 3px;
    text-align: center;
    color: ${colors.navy};
  `}
`

const query = graphql`
  query {
    logoImg: file(relativePath: { regex: "/logo-navy.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 400)
      }
    }
    dog: file(relativePath: { regex: "/logos/sitting-dog.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 40)
      }
    }
  }
`

const NavBar = ({ pathname }: { pathname: string }) => {
  const images = useStaticQuery(query)

  const [navDrawerVisible, setNavDrawerVisible] = useState(false)
  const [cartDrawerVisible, setCartDrawerVisible] = useState(false)

  useLockBodyScroll(navDrawerVisible || cartDrawerVisible)
  const { y: scrollY } = useWindowScroll()
  const alwaysWhiteNavRoutes = ['/']
  const transparentBg = !scrollY && !alwaysWhiteNavRoutes.includes(pathname)
  const topOfPage = scrollY < 3

  const { updating: cartUpdating, quantity, store, items, netTotal, products } = useCart()

  useEffect(() => {
    if (cartUpdating) setCartDrawerVisible(true)
  }, [cartUpdating])

  useEffect(() => {
    gsap.from('.NavBar__Nav', { y: -125, ease: Power3.easeOut, delay: 0.25, duration: 0.75 })
  }, [])

  const handleCartOpen = () => {
    trackViewCartEvent(products, items, netTotal)
    setCartDrawerVisible(true)
  }

  const handleCartClose = () => setCartDrawerVisible(false)
  const handleNavDrawerOpen = () => setNavDrawerVisible(true)
  const handleNavDrawerClose = () => setNavDrawerVisible(false)

  return (
    <>
      <Header>
        {(navDrawerVisible || cartDrawerVisible) && <DrawerMask />}
        <PromoBar
          copy="Subscribe For Free Shipping + 20% Off"
          bg={colors.navy}
          color={colors.lightText}
          hoverColor={colors.orange}
          linkUrl="/products"
        />
        <Nav transparentBg={transparentBg} topOfPage={topOfPage} className="NavBar__Nav">
          <HeaderInner>
            <MobileHide>
              <NavButton copy="Menu" onClick={handleNavDrawerOpen} />
            </MobileHide>

            <LogoLink to="/">
              <GatsbyImage
                image={getImage(images.logoImg)}
                alt="Finn logo"
                style={{ height: '100%', justifyContent: 'flex-start' }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </LogoLink>

            <RightGroup>
              {pathname !== '/products' && (
                <MobileHide>
                  <InternalLink to="/products">
                    <NavButton copy="SHOP" />
                  </InternalLink>
                </MobileHide>
              )}
              {quantity === 0 ? null : (
                <CartCounter onClick={handleCartOpen} className="NavBar_CartCounter">
                  <ShoppingFilled />
                  {store.fetching ? (
                    <Spinner overlay size={30} color={colors.navy} />
                  ) : (
                    <Count>{quantity}</Count>
                  )}
                </CartCounter>
              )}

              <AccountButtonGroup as="a" href="https://shop.petfinn.com/account/login">
                <GatsbyImage
                  image={getImage(images.dog)}
                  alt="Sitting dog icon"
                  style={{ width: 28 }}
                  imgStyle={{ objectFit: 'contain' }}
                />
                <MonoP style={{ fontSize: 10 }}>Account</MonoP>
              </AccountButtonGroup>

              <DesktopHide>
                <NavButton copy="Menu" onClick={handleNavDrawerOpen} />
              </DesktopHide>
            </RightGroup>
          </HeaderInner>
        </Nav>

        <NavDrawer visible={navDrawerVisible} close={handleNavDrawerClose} pathname={pathname} />
        <CartDrawer visible={cartDrawerVisible} close={handleCartClose} />
      </Header>
    </>
  )
}

export default NavBar
