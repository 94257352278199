import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Ticker from 'react-ticker'

import { H6 } from 'src/components/shared/text'
import { colors } from '../../styles/variables'
import { media, getScreenType } from '../../styles/util'

import DodoLogo from '../../assets/img/press-logos/dodo-white.svg'
import ForbesLogo from '../../assets/img/press-logos/forbes-white.svg'
import SwearbyLogo from '../../assets/img/press-logos/swearby-white.svg'
import TravelLeisureLogo from '../../assets/img/press-logos/travel-leisure-white.svg'

const Container = styled('div')<{ bg: string; color: string }>`
  background-color: ${({ bg }) => colors[bg]};
  color: ${({ color }) => (color ? colors[color] : colors.navy)};
  text-align: center;
`

const LogoGroup = styled('div')<{ logoCount: number }>`
  width: auto;

  display: grid;
  place-items: center;
  grid-template-columns: repeat(${({ logoCount }) => logoCount}, auto);
  grid-column-gap: 58px;

  padding: 0 29px;

  ${media.mobile`
    width: 800px;
    height: 86px;
  `}
  ${media.desktop`
    width: 850px;
    height: 100px;
  `}
`

const LogoImg = styled('img')<{ width: number }>`
  width: ${({ width }) => width}px;
`

const logos = [
  {
    img: DodoLogo,
    alt: 'Dodo Logo',
    width: 110
  },
  {
    img: ForbesLogo,
    alt: 'Forbes Logo',
    width: 127
  },
  {
    img: SwearbyLogo,
    alt: 'Swearby Logo',
    width: 176
  },
  {
    img: TravelLeisureLogo,
    alt: 'Travel Leisure Logo',
    width: 136
  }
]

const PressBanner = ({ bg, title, color }: { bg: string; title?: string; color: string }) => {
  const [tickerSpeed, setTickerSpeed] = useState(5)

  const screen = getScreenType()
  const isMobile = screen === 'mobile' || screen === 'tablet'

  const logoCount = logos.length

  useEffect(() => {
    if (isMobile) setTickerSpeed(5)
  }, [isMobile])

  return (
    <Container bg={bg} color={color}>
      {title && <H6 style={{ paddingTop: 22 }}>{title}</H6>}
      <Ticker speed={tickerSpeed}>
        {({ index }) => (
          <LogoGroup logoCount={logoCount} key={index}>
            {logos.map(logo => (
              <LogoImg src={logo.img} alt={logo.alt} width={logo.width} key={logo.alt} />
            ))}
          </LogoGroup>
        )}
      </Ticker>
    </Container>
  )
}

export default PressBanner
