import React, { useState } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gsap, Power3 } from 'gsap'
import { Collapse } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import DottedText from '../shared/DottedText'
import { MonoP, P, SmallP } from '../shared/text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'

const { Panel } = Collapse

const Card = styled('div')<{ tallCard?: boolean }>`
  overflow: hidden;
  position: relative;

  background-color: ${colors.white};
  color: ${colors.navy};
  border-radius: 25px;

  margin: 0 auto;

  width: 100%;
  height: 100%;

  ${media.mobile`
    min-height: ${(props: { tallCard?: boolean }) => (props.tallCard ? '500px' : '450px')};
  `}
  ${media.tablet`
    max-width: 426px;
    min-height: ${(props: { tallCard?: boolean }) => (props.tallCard ? '602px' : '510px')};
  `}
  ${media.desktop`
    min-height: ${(props: { tallCard?: boolean }) => (props.tallCard ? '655px' : '585px')};
  `}

  .ant-collapse-borderless {
    background-color: transparent;
  }
`

const CardContent = styled.div`
  width: 100%;
  height: 100%;
`

const ActionLink = styled(MonoP)<{ right: string; left: string }>`
  color: ${colors.slate};
  position: absolute;
  bottom: 20px;
  right: ${(props) => props.right};
  left: ${(props) => props.left};
  transition: 250ms color ease-in-out;
  cursor: pointer;

  &:hover {
    color: ${colors.navy};
  }
`

const CopyContainer = styled('div')<{ isBundle: boolean }>`
  height: 100%;
  padding: 20px 27px ${(props) => (props.isBundle ? '60px' : '30px')} 27px;
`

const IngredientsContainer = styled.div`
  ${media.mobile`
    margin-bottom: 30px;
  `}
  ${media.desktop`
    margin-bottom: 20px;
  `}
`

const CardTitle = styled(MonoP)`
  margin-top: 10px;
  padding: 10px 0 20px 0;
  border-top: 1px solid ${colors.navy};
`

const Header = styled(P)<{ padding?: number }>`
  padding-top: ${(props) => props.padding || 10}px;
  font-family: ${fonts.larsseit.medium};

  &:first-of-type {
    padding-top: 0;
  }
`

const PanelItem = styled(Panel)`
  width: 100%;
  border: none !important;
  background-color: ${colors.white};
  margin: 20px 0 0 0;
  padding: 0;

  & * {
    border: none !important;
    margin: 0;
    padding: 0;
    color: ${colors.navy};
  }

  &.ant-collapse-item > .ant-collapse-header {
    width: 100%;
    position: relative;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0 !important;
    border-top: 1px solid ${colors.navy} !important;

    span {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      margin: 0;
      right: 0;
    }
  }

  .ant-collapse-content-box {
    width: 100%;
    border: none !important;
    margin: 0 0 0 0;
    padding: 0 20px 0 0;
  }

  .ant-collapse-content-inactive {
    will-change: display;
    display: none;
  }
`

const ExpandIcon = styled(PlusOutlined)`
  & svg {
    height: 12px;
    width: 12px;
    fill: ${colors.navy};
  }
`

const BigArrow = styled.span`
  font-size: 20px;
`

interface ImageProps {
  title: string
  src: any
  fullSizeSrc: any
  alt: string
  copy: {
    header: string
    body: string
    ingredients: {
      name: string
      amount: string
    }[]
  }[]
}

interface ProductCardProps {
  isBundle: boolean
  productSlug: string
  card: Array<ImageProps>
  cardKey: string
  idx: number
}

const ProductCard: React.FC<ProductCardProps> = ({ isBundle, productSlug, card, cardKey, idx }) => {
  const [active, setActive] = useState(0)

  const handleChange = (idx: number) => {
    gsap.fromTo(`.${cardKey}`, { opacity: 1 }, { duration: 0.15, ease: Power3.easeOut, opacity: 0 })
    setTimeout(() => {
      setActive(idx)
    }, 200)
    gsap.fromTo(
      `.${cardKey}`,
      { opacity: 0 },
      { duration: 0.25, delay: 0.25, ease: Power3.easeIn, opacity: 1 }
    )
  }

  const titleCopy = card[active].title && card[active].title
  const cardCopy = card[active].copy && card[active].copy

  // Multivitamin needs a taller card bc of how many ingredients it has (and any bundles that
  // also include Multivitamin); Probiotics needs extra space too
  const tallCards = [
    'multivitamin',
    'super-pup-bundle',
    'super-pup-holiday',
    'active-pup-bundle',
    'chill-pup-bundle',
    'pretty-pup-bundle',
    'comfy-pup-bundle',
    'starter-bundle'
  ]
  const tallCard = tallCards.includes(productSlug)

  const [open, setOpen] = useState(false)

  const activeIngredientCardImg = card[active].fullSizeSrc
  const cardImage = card[active].src

  return (
    <Card tallCard={tallCard}>
      <CardContent className={cardKey}>
        {!titleCopy && cardImage && !activeIngredientCardImg && (
          <GatsbyImage
            image={getImage(cardImage)}
            alt={card[active].alt}
            style={{
              width: '100%',
              maxHeight: 350,
              marginTop: isBundle || (cardImage && idx > 0) ? 40 : 0
            }}
            imgStyle={{ objectFit: isBundle ? 'contain' : 'cover' }}
          />
        )}
        <CopyContainer isBundle={isBundle}>
          <CardTitle>{titleCopy}</CardTitle>
          <div style={{ display: 'grid', gridRowGap: active === 2 ? 30 : 0 }}>
            {cardCopy &&
              cardCopy.map((textGroup) => {
                const activeIngredients = textGroup.header === 'Active Ingredients Per Chew'
                const inactiveIngredients = textGroup.header === 'Inactive Ingredients'

                if (activeIngredientCardImg) {
                  return (
                    <div key={textGroup.header}>
                      <Header>Active Ingredients Per Chew</Header>
                      <GatsbyImage
                        image={getImage(activeIngredientCardImg)}
                        alt={card[active].alt}
                        style={{ margin: '30px 16px' }}
                        imgStyle={{ objectFit: 'contain' }}
                      />
                      <Collapse
                        key={textGroup.header}
                        defaultActiveKey={!tallCard ? '0' : undefined}
                        bordered={false}
                        onChange={() => setOpen((prevState) => !prevState)}
                      >
                        <PanelItem
                          key={textGroup.header}
                          header={<Header>{textGroup.header}</Header>}
                          showArrow={false}
                          extra={open ? null : <ExpandIcon />}
                        >
                          <SmallP>{textGroup.body}</SmallP>
                        </PanelItem>
                      </Collapse>
                    </div>
                  )
                }

                if (activeIngredients) {
                  return (
                    <div key={textGroup.header}>
                      <Header>{textGroup.header}</Header>
                      <IngredientsContainer tallCard={tallCard}>
                        {textGroup.ingredients.map((ingredient) => (
                          <DottedText
                            key={ingredient.name}
                            left={ingredient.name}
                            right={ingredient.amount}
                          />
                        ))}
                      </IngredientsContainer>
                    </div>
                  )
                }

                if (inactiveIngredients) {
                  return (
                    <Collapse
                      key={textGroup.header}
                      defaultActiveKey={!tallCard ? '0' : undefined}
                      bordered={false}
                      onChange={() => setOpen((prevState) => !prevState)}
                    >
                      <PanelItem
                        key={textGroup.header}
                        header={<Header>{textGroup.header}</Header>}
                        showArrow={false}
                        extra={open ? null : <ExpandIcon />}
                      >
                        <SmallP>{textGroup.body}</SmallP>
                      </PanelItem>
                    </Collapse>
                  )
                }

                return (
                  <React.Fragment key={textGroup.header}>
                    <Header padding={20}>{textGroup.header}</Header>
                    <SmallP>{textGroup.body}</SmallP>
                  </React.Fragment>
                )
              })}
          </div>
        </CopyContainer>
        {isBundle && (
          <>
            {active === 0 && (
              <ActionLink left="auto" right="24px" onClick={() => handleChange(1)}>
                Benefits <BigArrow>￫</BigArrow>
              </ActionLink>
            )}
            {active === 1 && (
              <>
                <ActionLink left="24px" right="auto" onClick={() => handleChange(0)}>
                  <BigArrow>￩</BigArrow> Summary
                </ActionLink>
                <ActionLink left="auto" right="24px" onClick={() => handleChange(2)}>
                  Ingredients <BigArrow>￫</BigArrow>
                </ActionLink>
              </>
            )}
            {active === 2 && (
              <ActionLink left="24px" right="auto" onClick={() => handleChange(1)}>
                <BigArrow>￩</BigArrow> Benefits
              </ActionLink>
            )}
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default ProductCard
