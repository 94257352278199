import React from 'react'
import styled from 'styled-components'

import DrawerMenu from '../DrawerMenu'
import MenuWrapper from '../MenuWrapper'
import NavLink from './NavLink'
import MenuSocial from 'src/components/shared/MenuSocial'
import { NavButton } from 'src/components/shared/buttons'
import { colors } from 'src/styles/variables'
import { media } from 'src/styles/util'

const List = styled.div`
  color: ${colors.lightText};
  border-top: 1px solid ${colors.lightText};
  height: fit-content;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  padding: 40px 0 40px 0;
  margin-top: 20px;

  & a {
    width: fit-content;
  }

  ${media.mobile`
    grid-template-rows: repeat(5, 50px) repeat(5, 30px);
  `}
  ${media.tablet`
    grid-template-rows: repeat(5, 70px) repeat(5, 36px);
  `}
`

const CloseButton = styled(NavButton)`
  ${media.mobile`
    background-color: ${colors.lightText} !important;
  `}
`

const navDrawerItems = {
  primary: [
    { label: 'Home', url: '/', external: false },
    { label: 'Shop', url: '/products', external: false },
    { label: 'About', url: '/about', external: false },
    { label: 'Blog', url: '/blog', external: false },
    { label: 'Virtual Vet', url: '/consultation', external: false }
  ],
  secondary: [
    {
      label: 'My Account',
      url: 'https://shop.petfinn.com/account/login',
      external: true,
      socialMedia: false
    },
    { label: 'FAQ', url: '/faq', external: false },
    { label: 'Affiliate Program', url: '/affiliate', external: false },
    { label: 'Press', url: 'mailto:press@petfinn.com', external: true, socialMedia: false },
    { label: 'Terms of Use', url: '/terms', external: false },
    { label: 'Privacy', url: '/privacy', external: false },
    { label: 'Accessibility', url: '/accessibility', external: true }
  ]
}

interface NavDrawerProps {
  visible: boolean
  close: () => void
  pathname: any
}

const NavDrawer = ({ visible, close, pathname }: NavDrawerProps) => {
  return (
    <DrawerMenu placement="left" visible={visible} close={close} width="525px">
      <MenuWrapper>
        <CloseButton copy="Close" onClick={close} navDrawer />
        <List>
          {navDrawerItems.primary.map((link) => (
            <NavLink key={link.label} item={link} pathname={pathname} mainItems />
          ))}
          {navDrawerItems.secondary.map((link) => (
            <NavLink key={link.label} item={link} pathname={pathname} />
          ))}
        </List>
        <MenuSocial />
      </MenuWrapper>
    </DrawerMenu>
  )
}

export default NavDrawer
