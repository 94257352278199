import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

import { consultationAtom } from 'src/store/consultationAtom'
import { availableDashboardsAtom } from 'src/store/dashboardAtoms'
import { storeState, initializeCheckout } from '../actions/checkout'
import { productDetailState, fetchProductDetail } from '../actions/products'

const Container = styled.main`
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
`

interface RootWrapperProps {
  location: any
}

const RootWrapper: React.FC<RootWrapperProps> = ({ location, children }) => {
  const [, setStore] = useRecoilState(storeState)
  const [, setProductDetail] = useRecoilState(productDetailState)
  const [,] = useRecoilState(consultationAtom)
  const [,] = useRecoilState(availableDashboardsAtom)

  useEffect(() => {
    if (location && !location.pathname.includes('/giveaways')) {
      initializeCheckout(setStore, location)
      fetchProductDetail(setProductDetail)
    }
  }, [])

  return <Container>{children}</Container>
}

export default RootWrapper
