import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import DropHeader from '../shared/DropHeader'
import DoggoBackground from '../shared/DoggoBackground'
import { LargeP } from '../shared/text'
import { PrimaryButton } from '../shared/buttons'
import { trackCustomClickEvent } from '../../utils/event-tracking'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.orange};

  ${media.tablet`
    padding: 10px 30px 0 30px;
  `}
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;

  ${media.desktop`
    max-width: 1300px;
  `};
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  ${media.mobile`
    padding: 20px 0 100px 0;
  `}
  ${media.tablet`
    padding: 30px 0 130px 0;
  `}
`

const SubHeader = styled(LargeP)`
  ${media.mobile`
    margin: 10px 20px 30px 20px;
    font-size: 18px;
    line-height: 24px;
  `}
  ${media.tablet`
    margin: 0 30px 40px 30px;
    font-size: 28px;
    max-width: 700px;
    line-height: 36px;
  `}
  ${media.desktop`
    margin: 0 0 40px 0;
    font-size: 36px;
    line-height: 42px;
  `}
`

const GetStarted = () => {
  const handleCtaClick = () => {
    trackCustomClickEvent('clicked consultation cta')
    navigate('/consultation')
  }
  return (
    <Section>
      <Wrapper>
        <DropHeader title={'Consult the\nVirtual Vet'} />
        <DoggoBackground>
          <Container>
            <SubHeader>
              Tell us a bit about your dog and get tailor-made supplement recommendations based on
              factors like age, breed, diet, and history.
            </SubHeader>
            <PrimaryButton copy="Virtual Vet" onClick={handleCtaClick} arrow />
          </Container>
        </DoggoBackground>
      </Wrapper>
    </Section>
  )
}

export default GetStarted
