import { createGlobalStyle } from 'styled-components'

import { colors, fonts } from '../styles/variables'

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    scroll-behavior: smooth;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: overlay;
    font-family: ${fonts.larsseit.regular};
    color: ${colors.navy};
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }

  body .tl-edges {
    overflow-x: unset;
  }

  main,
  section,
  div {
    margin: 0;
    padding: 0;
  }

  a {
    color: ${colors.navy};
    text-decoration: none;
    &:hover {
      color: ${colors.orange} !important;
    }
    &:focus {
      color: inherit;
    }
  }

  img {
    max-width: 100%;
    object-fit: contain;
    position: relative;
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    color: ${colors.navy};
  }

  p {
    padding: 0;
    margin: 0 0 1rem 0;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    color: ${colors.navy};
  }

  .ant-tooltip-inner {
    font-family: ${fonts.larsseit.regular};
    background-color: ${colors.lightText};
    color: ${colors.navy};
    font-size: 16px;
    line-height: 20px;
    border-radius: 25px;
    padding: 16px 20px;
    box-shadow: none;
    margin-top: -12px;
    margin-left: 12px;
  }

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-select-item {
    font-family: ${fonts.larsseit.light} !important;
    color: ${colors.navy};
    font-size: 16px;
    padding: 10px 20px 7px 20px;
  }

  .ant-select-item-option-selected {
    background-color: transparent !important;
    font-family: ${fonts.larsseit.bold} !important;
    color: ${colors.navy} !important;
    font-weight: 400 !important;
  }

  .ant-form-item-explain {
    text-align: center;
  }

  .ant-message {
    z-index: 2147483647;
    transform: translate(-50%, 0);
    top: 130px;
    left: 50%;
    font-family: ${fonts.monosten.normal};
    text-transform: uppercase;
    color: ${colors.white};

    & .ant-message-notice-content {
      background-color: ${colors.slate};
    }

    & span.anticon {
      margin-right: 12px;
      & svg path {
        fill: ${colors.white};
      }
    }
  }

  .optimize-incumbent {
    display: grid !important;
  }

  .optimize-alternate {
    display: none !important;
  }
`

export default GlobalStyle
