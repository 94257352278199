import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import DropHeader from '../shared/DropHeader'
import BuyABundle from '../Products/BuyABundle'
import { colors } from '../../styles/variables'
import scrollToTop from '../../utils/scrollToTop'

const Section = styled.section`
  height: fit-content;
  min-height: 800px;
  width: 100%;
  background-color: ${colors.white};
  position: relative;
`

interface BundlesProps {
  title?: string
  buttonCopy?: string
  isProductLibrary?: boolean
}

const Bundles = ({ title = '', buttonCopy = '', isProductLibrary }: BundlesProps) => {
  const handleCtaClick = () => {
    navigate('/products')
    scrollToTop()
  }

  return (
    <Section className="bundleGroups">
      {title && (
        <DropHeader title={title} buttonCopy={buttonCopy} onClick={handleCtaClick} fontSize={120} tabletFontSize={88} mobileFontSize={64} />
      )}
      <BuyABundle isProductLibrary={isProductLibrary} />
    </Section>
  )
}

export default Bundles
