// these sizes are arbitrary and you can set them to whatever you wish
import { css } from 'styled-components'

interface SizesProps {
  [key: string]: number
  desktop: number
  tablet: number
  mobile: number
}

const sizes: SizesProps = {
  desktop: 1120,
  tablet: 768,
  mobile: 0
}

interface MediaProps {
  desktop: any
  tablet: any
  mobile: any
}

// iterate through the sizes and create a media template
export const media: MediaProps = Object.keys(sizes).reduce((accumulator: any, label: any) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16
  accumulator[label] = (...args: any) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)}
    }
  `

  return accumulator
}, {})

export const getScreenType = (): string => {
  const windowAvailable: boolean = typeof window !== 'undefined'

  let mediaType = 'desktop'

  if (windowAvailable) {
    const screenWidth: number = window.innerWidth
    if (screenWidth < sizes.desktop) mediaType = 'tablet'
    if (screenWidth < sizes.tablet) mediaType = 'mobile'
  }

  return mediaType
}
