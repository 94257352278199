import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getImage } from 'gatsby-plugin-image'

import { trackProductViewEvent } from 'src/utils/event-tracking'
import ImageCarousel from './ImageCarousel'
import CtaButtons from './CtaButtons'
import ProductSelector from 'src/components/PDP/AboveTheFold/ProductSelector'
import TotalReviewsAndRating from 'src/components/PDP/TotalReviewsAndRating'
import AfterpayModal from 'src/components/shared/AfterpayModal'
import Spinner from 'src/components/shared/Spinner'
import { Headline, P, MonoP } from 'src/components/shared/text'
import { colors } from 'src/styles/variables'
import { media } from 'src/styles/util'
import { SUBSCRIPTION_FREQUENCIES } from 'src/utils/globalVariables'
import { addVariantToCart } from 'src/actions/checkout'
import { trackAddToCartEvent, updateProductViewEvent } from 'src/utils/event-tracking'
import { IAboveTheFoldProps } from 'src/types/pdp'
import { IProductDataInterface } from 'src/types/product-actions'
import useCart from 'src/hooks/useCart'

const Section = styled.section`
  position: relative;

  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;

  background-color: ${colors.white};
  height: fit-content;

  ${media.mobile`
    padding: 140px 10px 36px 10px;
  `}
  ${media.tablet`
    padding: 0;
  `}
`

const Container = styled.div`
  width: 100%;
  height: fit-content;
  margin: 0 auto;

  ${media.tablet`
    padding: 180px 20px 40px 0;
    max-width: 900px;
  `}
  ${media.desktop`
    padding: 120px 20px 70px 20px;
    max-width: 1200px;
  `}
`

const Grid = styled.div`
  width: 100%;

  display: grid;
  align-items: start;
  justify-items: center;

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
  `}
  ${media.tablet`
    grid-template-columns: 0.9fr 1fr;
    grid-template-rows: auto;
    grid-gap: 10px;
  `}
  ${media.desktop`
    margin-top: 50px;
  `}
`

const CopyContainer = styled(Container)`
  width: 100%;

  ${media.mobile`
    align-items: center;
    text-align: center;
  `}
  ${media.tablet`
    padding: 10px 0 0 0;
    align-items: flex-start;
    text-align: left;
  `}

  & a {
    width: fit-content;
  }
`

const ProductName = styled(Headline)<{ longTitle: boolean }>`
  ${media.mobile`
    font-size: ${(props: { longTitle: boolean }) => (props.longTitle ? 40 : 55)}px;
    margin-bottom: 9px;
  `}
  ${media.tablet`
    font-size: ${(props: { longTitle: boolean }) => (props.longTitle ? 55 : 64)}px;
    margin-top: 0;
    margin-bottom: 10px;
  `}
  ${media.desktop`
    font-size: ${(props: { longTitle: boolean }) => (props.longTitle ? 55 : 64)}px;
  `}
`

const Description = styled(P)`
  width: 100%;

  white-space: pre-line;
  line-height: 1.3;
  margin: 0 auto;

  ${media.mobile`
    padding: 10px 10px 20px 10px;
    max-width: 350px;
    margin: 0 auto;
  `}
  ${media.tablet`
    padding: 10px 0 20px 0;
    max-width: 490px;
    margin: 0;
  `}
`

const ChewCount = styled(MonoP)`
  font-size: 16px;
  color: ${colors.slate};
  ${media.mobile`
    margin: 30px 0 10px 0;
  `}
  ${media.tablet`
    margin: 16px 0 12px 2px;
  `}
`

const MobileOnly = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  ${media.tablet`
    display: none;
  `}
  &:first-of-type {
    padding: 0;
  }
`

const LargeScreenOnly = styled.div`
  width: 100%;

  ${media.mobile`
    display: none;
  `}
  ${media.tablet`
    display: block;
  `}

  & a {
    width: fit-content;
  }
`

const AboveTheFold: React.FC<IAboveTheFoldProps> = ({ reviewStats, data }) => {
  const {
    title,
    slug,
    shortDescription,
    isBundle,
    colors: brandColors,
    carouselImages,
    outOfStock
  } = data

  const { loading, checkoutId, setStore, products } = useCart()

  const productsBySlug = products?.bySlug

  const [activeVariant, setActiveVariant] = useState<IProductDataInterface | null>(null)
  const [frequency, setFrequency] = useState(SUBSCRIPTION_FREQUENCIES[2])
  const [afterpayModalOpen, setAfterpayModalOpen] = useState(false)

  const firstCarouselImg = carouselImages[0].src
  const productImageSrc = getImage(firstCarouselImg)?.images?.fallback?.src
  const productImagePath = productImageSrc ? `https://www.petfinn.com${productImageSrc}` : ''

  const product = productsBySlug ? productsBySlug[slug] : null
  const hasSubscriptionVariant = product?.single?.pricing?.subscription?.price ?? false
  const initialSubscriptionState = hasSubscriptionVariant && isBundle

  const [isSubscription, setIsSubscription] = useState(initialSubscriptionState)

  const priceType = isSubscription ? 'subscription' : 'oneTime'
  const variant = activeVariant?.pricing[priceType]
  const activeVariantPrice = variant?.price || 0

  const isAllergyAndItch = slug === 'allergy-and-itch'

  useEffect(() => {
    if (!loading) {
      // If product has a 2-pack variant, select by default
      let newActiveVariant = product?.multipack_2 || product?.single

      /** Temp per Nick Michlewicz on 5/18/22:
          doing a paid social test across a few channels and wanted to switch the pre-selected
          default for the Allergy & Itch PDP to the 1-pack, so that it shows a lower overall
          price when people land on the page
       */
      if (isAllergyAndItch) newActiveVariant = product?.single

      setActiveVariant(newActiveVariant)
      trackProductViewEvent(products, slug, productImagePath)
    }
  }, [loading, slug, isAllergyAndItch])

  const handleAddToCart = () => {
    const activeVariantId = activeVariant?.variantId.encoded

    if (!loading && checkoutId && activeVariantId) {
      const selectedFrequency = isSubscription ? frequency : 0
      const subscription = isSubscription ? activeVariant.pricing.subscription : null

      const detail = {
        checkoutId,
        productMap: products,
        variantId: activeVariantId,
        quantity: 1,
        frequency: selectedFrequency,
        setStore,
        subscription
      }
      addVariantToCart(detail)
      trackAddToCartEvent(
        products,
        slug,
        activeVariant?.variantId.decoded,
        Number(activeVariantPrice),
        selectedFrequency
      )
    }
  }

  const handleProductViewEvent = (viewedVariant) => {
    updateProductViewEvent(
      productsBySlug,
      slug,
      viewedVariant.variantId.decoded,
      viewedVariant.price,
      productImagePath
    )
  }

  const clickQuantityButton = (packType: any) => {
    const selectedProduct = product[packType]
    setIsSubscription(false)
    setActiveVariant(selectedProduct)
    handleProductViewEvent(selectedProduct)
  }

  const handleRadioClick = (sub: boolean) => {
    setIsSubscription(sub)
    if (sub) setActiveVariant(product.single)
  }

  const longTitle = title.length > 20

  return (
    <>
      <Section>
        <Container>
          <Grid>
            <MobileOnly>
              <CopyContainer>
                <ProductName longTitle={longTitle}>{title}</ProductName>
                <a href="#reviews">
                  <TotalReviewsAndRating reviewStats={reviewStats} />
                </a>
                <br />
              </CopyContainer>
            </MobileOnly>

            <ImageCarousel carouselImages={carouselImages} />

            <CopyContainer>
              {loading && (
                <Spinner overlay={colors.white} color={colors[brandColors.bg]} size={80} />
              )}
              <LargeScreenOnly>
                <ProductName longTitle={longTitle}>{title}</ProductName>
                <a href="#reviews">
                  <TotalReviewsAndRating reviewStats={reviewStats} />
                </a>
              </LargeScreenOnly>
              <ChewCount>90 Soft Chews {isBundle && 'Per Tin'}</ChewCount>
              <Description>
                <span dangerouslySetInnerHTML={{ __html: shortDescription }} />
              </Description>

              {!loading && activeVariant && hasSubscriptionVariant && (
                <ProductSelector
                  productOptions={productsBySlug[slug]}
                  activeVariant={activeVariant}
                  frequency={frequency}
                  setFrequency={setFrequency}
                  brandColors={brandColors}
                  isBundle={isBundle}
                  isSubscription={isSubscription}
                  clickQuantityButton={clickQuantityButton}
                  handleRadioClick={handleRadioClick}
                />
              )}

              {!loading && (
                <LargeScreenOnly style={{ marginTop: 18 }}>
                  <CtaButtons
                    brandColors={brandColors}
                    price={activeVariantPrice}
                    outOfStock={outOfStock}
                    handleAddToCart={handleAddToCart}
                    hasSubscriptionVariant={hasSubscriptionVariant}
                    isSubscription={isSubscription}
                    setAfterpayModalOpen={setAfterpayModalOpen}
                  />
                </LargeScreenOnly>
              )}
            </CopyContainer>
          </Grid>
        </Container>

        {!loading && (
          <MobileOnly>
            <CtaButtons
              brandColors={brandColors}
              price={activeVariantPrice}
              outOfStock={outOfStock}
              handleAddToCart={handleAddToCart}
              hasSubscriptionVariant={hasSubscriptionVariant}
              isSubscription={isSubscription}
              setAfterpayModalOpen={setAfterpayModalOpen}
            />
          </MobileOnly>
        )}
      </Section>

      <AfterpayModal open={afterpayModalOpen} setOpen={setAfterpayModalOpen} />
    </>
  )
}

export default AboveTheFold
