import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useRecoilState } from 'recoil'
import { useMeasure } from 'react-use'

import navyBackgroundVector from 'src/assets/img/product-library/navy-background-vector.svg'
import pinkBackgroundVector from 'src/assets/img/product-library/pink-background-vector.svg'

import { H4, H5, LargeP, P, InternalLink } from '../shared/text'
import { PrimaryButton } from '../shared/buttons'

import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

import { productDetailState } from '../../actions/products'

const Section = styled('div')<{ bg: string }>`
  position: relative;
  overflow: hidden;
  z-index: 1;

  width: 100%;
  background-color: ${({ bg }) => bg || colors.navy};

  ${media.mobile`
    padding: 50px 16px 60px 16px;
  `}
  ${media.tablet`
    padding: 50px 20px 60px 20px;
  `}
  ${media.desktop`
    padding: 50px 60px 60px 60px;
  `}
`

const Card = styled.div`
  z-index: 2;

  background-color: #fff;

  height: 100%;
  width: 100%;

  border-radius: 25px;
  margin: 0 auto;

  display: grid;

  white-space: pre-line;

  ${media.mobile`
    grid-gap: 30px;
    padding: 36px 20px 40px 20px;
    place-items: center;
    text-align: center;
  `}
  ${media.tablet`
    grid-gap: 30px;
    padding: 36px 20px 40px 40px;
    grid-template-columns: 1.25fr 1fr;
    text-align: left;
    max-width: 750px;
  `}
  ${media.desktop`
    padding: 47px 20px 37px 60px;
    grid-template-columns: 1fr minmax(1fr, 600px);
    max-width: 1100px;
  `}
`

const ProductNameH4 = styled(H4)`
  line-height: 1;
`

const StyledLargeP = styled(LargeP)`
  color: ${colors.slate};
  line-height: 1.4;

  ${media.mobile`
    font-size: 14px;
  `}
  ${media.tablet`
    font-size: 18px;
  `}
  ${media.desktop`
    font-size: 22px;
    white-space: pre-line;
  `}
`

const TextBox = styled.div`
  margin: 0 auto;
  display: grid;

  ${media.mobile`
    grid-row: 2;
    justify-items: center;
    grid-row-gap: 12px;
  `}
  ${media.tablet`
    grid-row: 1;
    justify-items: start;
    grid-row-gap: 20px;
  `}
  ${media.desktop`
    grid-row-gap: 30px;
    max-width: 530px;
  `}
`

const PriceGrid = styled.div`
  font-family: Larsseit, Helvetica, sans-serif;
  width: fit-content;

  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 20px;
  align-items: end;

  & p {
    line-height: 1 !important;

    &:last-of-type {
      color: ${colors.slate};
      text-decoration: line-through;
    }
    &:first-of-type {
      color: ${colors.orange};
      text-decoration: none;
    }
  }

  ${media.mobile`
    & p {
      font-size: 36px;
      &:last-of-type {
        font-size: 28px;
      }
    }
  `}
  ${media.desktop`
    align-self: start;
    & p {
      font-size: 48px;
      &:last-of-type {
        font-size: 36px;
      }
    }
  `}
`

const ImageWrapper = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  grid-row-gap: 30px;
  text-align: center;
`

const ImageLink = styled(InternalLink)`
  width: 100% !important;
  margin: 0 auto;

  grid-row: 1;

  ${media.tablet`
    max-width: 600px;
    min-width: 200px;
    grid-row: 1;
  `}

  transition: 200ms transform ease;
  &:hover {
    transform: scale(1.02);
  }
`

const NavyVectorImg = styled.img`
  z-index: -1;

  position: absolute;
  top: 0;
  left: 0;

  ${media.mobile`
    width: 200vw;
  `}
  ${media.tablet`
    height: 300px;
    width: auto;
  `}
`

const PinkVectorImg = styled('img')<{ height: number }>`
  z-index: -2;

  position: absolute;
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;

  ${media.mobile`
    width: 200vw;
  `}
  ${media.tablet`
    width: 92vw;
  `}
  ${media.desktop`
    width: auto;
    min-height: ${({ height }) => height}px;
    object-fit: cover;
  `}
`

interface FeaturedBundleInterface {
  bg: string
  product: {
    lookupKey: string
    url: string
    name: string
    description: string
    imageCaption?: string
  }
  includeVectors?: boolean
}

const FeaturedBundle = ({ product, bg, includeVectors = false }: FeaturedBundleInterface) => {
  const [productMap] = useRecoilState(productDetailState)

  const [ref, { height }] = useMeasure()
  const adjustedHeight = height + 110 // 110px for top/bottom padding

  const slugMap = productMap?.bySlug
  if (!slugMap) return null

  const productDetail = slugMap[product.lookupKey]
  const otpPrice = productDetail?.single?.pricing?.oneTime?.price
  const compareAtPricePrice = productDetail?.single?.pricing?.oneTime?.compareAtPrice

  return (
    <Section ref={ref} bg={bg}>
      <Card>
        <TextBox>
          <InternalLink to={product.url}>
            <ProductNameH4 as="p">{product.name}</ProductNameH4>
          </InternalLink>
          <StyledLargeP>{product.description}</StyledLargeP>
          {!!otpPrice && (
            <PriceGrid>
              <H5 as="p">${otpPrice.toFixed(2)}</H5>
              {!!compareAtPricePrice && <H5 as="p">${compareAtPricePrice.toFixed(2)}</H5>}
            </PriceGrid>
          )}
          <PrimaryButton copy="Shop Now" arrow onClick={() => navigate(product.url)} />
        </TextBox>

        <ImageWrapper>
          <ImageLink to={product.url}>
            <GatsbyImage
              image={product.image}
              alt={product.name}
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </ImageLink>
          {product.imageCaption && (
            <P>
              <b>{product.imageCaption}</b>
            </P>
          )}
        </ImageWrapper>
      </Card>

      {includeVectors && (
        <>
          <NavyVectorImg src={navyBackgroundVector} aria-hidden="true" />
          <PinkVectorImg src={pinkBackgroundVector} aria-hidden="true" height={adjustedHeight} />
        </>
      )}
    </Section>
  )
}

export default FeaturedBundle
