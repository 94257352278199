import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useClickAway, useKeyPressEvent } from 'react-use'
import { gsap } from 'gsap'

import { media } from '../../styles/util'
import { colors } from '../../styles/variables'

const Drawer = styled('div')<{ isLeftMenu: boolean; width: string }>`
  z-index: 201;
  box-shadow: ${props => (props.isLeftMenu ? '1px' : '-1px')} 0 18px 3px rgba(10, 10, 10, 0.45);

  height: 100%;

  position: fixed;
  top: 0;
  left: ${props => (props.isLeftMenu ? '0' : 'auto')};
  right: ${props => (props.isLeftMenu ? 'auto' : '0')};
  opacity: 0;
  width: 100%;
  transition: 150ms all ease-in-out;

  ${media.mobile`
    max-width: 100vw;
  `}
  ${media.tablet`
    max-width: ${(props: { width: string }) => props.width};
  `}
`

const InnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background-color: ${colors.navy};
`

interface DrawerMenuProps {
  placement: 'left' | 'right'
  visible: boolean
  close: () => void
  width: string
  afterpayModalOpen?: boolean
}

const DrawerMenu: React.FC<DrawerMenuProps> = ({ placement, visible, close, width, afterpayModalOpen, children }) => {
  let drawerRef = useRef(null)
  const clickRef = useRef(null)
  const isLeftMenu = placement === 'left'

  const fromX = isLeftMenu ? -525 : 525
  const hiddenConfig = { opacity: 0, x: fromX }
  const visibleConfig = { opacity: 1, x: 0 }

  const reveal = () => {
    gsap.fromTo(drawerRef, hiddenConfig, { ...visibleConfig, duration: 0.15, ease: 'linear' })
  }

  const hide = () => {
    gsap.to(drawerRef, { ...hiddenConfig, duration: 0.15, ease: 'linear' })
  }

  /**
   *   Trigger menu close with esc key click or clicking outside menu
   */
  useKeyPressEvent('Escape', close)
  useClickAway(clickRef, () => {
    if (!afterpayModalOpen) close()
  })

  useEffect(() => {
    if (visible) reveal()
    else hide()
  }, [visible])

  return (
    <Drawer ref={el => (drawerRef = el)} isLeftMenu={isLeftMenu} width={width}>
      <InnerWrapper ref={clickRef}>{children}</InnerWrapper>
    </Drawer>
  )
}

export default DrawerMenu
