const emptyReturnValue = {
  slug: '',
  variantId: '',
  price: 0,
  sub: null,
  subPrice: null,
  subDiscount: null,
  subDiscountPercent: null
}

const formatVariant = (lineItem: any, variantMap: any) => {
  if (!lineItem || !variantMap) return emptyReturnValue

  const variant = lineItem.variant
  const price = Number(variant.price)

  const fullVariantId = variant.id // e.g. "gid://shopify/ProductVariant/39848003141714"
  const variantIdBreakdown = fullVariantId.split('/')
  const variantId = variantIdBreakdown[variantIdBreakdown.length - 1]

  const variantDetail = variantMap[variantId]
  const { slug, pricing } = variantDetail

  const sub = pricing?.subscription
  const subPrice = sub?.price
  const subDiscount = sub?.priceDiscount
  const subDiscountPercent = sub?.percentageDiscount

  return {
    slug,
    variantId,
    price,
    sub,
    subPrice,
    subDiscount,
    subDiscountPercent
  }
}

export default formatVariant
