import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gsap, Power3 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'

import EmailCapture from './EmailCapture'
import MenuSocial from '../shared/MenuSocial'
import { H6, P, SmallP, InternalLink, ExternalLink } from '../shared/text'
import { colors, fonts } from '../../styles/variables'
import { media, getScreenType } from '../../styles/util'

const FooterOuter = styled.footer`
  width: 100%;
  background-color: ${colors.navy};
  z-index: 1;
  ${media.mobile`
    padding: 40px 20px 80px 20px;
  `}
  ${media.tablet`
    padding: 60px 30px 100px 30px;
  `}
  ${media.desktop`
    position: sticky;
    bottom: 0;
    left: 0;
    padding: 120px 60px 50px 60px;
  `}
`

const FooterInner = styled.div`
  height: 100%;

  display: grid;
  margin: 0 auto;

  ${media.mobile`
    width: 100%;
    max-width: 350px;
  `}
  ${media.tablet`
    max-width: none;
    grid-template-columns: auto auto 1fr;
    grid-column-gap: 20px;
  `}
  ${media.desktop`
    width: 100%;
    max-width: 1200px;
    grid-column-gap: 70px;
  `}
`

const RightContainer = styled.div`
  height: fit-content;
  width: 100%;

  display: grid;
  color: ${colors.lightText};

  ${media.tablet`
    justify-content: center;
  `}
  ${media.desktop`
    justify-content: end;
    max-width: 525px;
    grid-gap: 40px;
  `}
`

const LogoLink = styled(Link)`
  height: auto;
  color: ${colors.lightText};
  width: 100%;
  margin: 12px auto;
`

const MobileLogoLink = styled(LogoLink)`
  max-width: 85px;
  text-align: left;
  margin: 0;

  ${media.tablet`
    display: none;
  `}
`

const List = styled.div`
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr;
  color: ${colors.lightText};
  grid-row-gap: 6px;
  ${media.mobile`
    border-top: 1px solid ${colors.lightText};
    padding-top: 40px;
    margin-top: 40px;
    grid-template-rows: repeat(5, 48px);
  `}
  ${media.tablet`
    border-top: none;
    padding: 0;
    margin-top: 0;
  `}
  ${media.desktop`
    grid-template-rows: repeat(5, 70px);
  `}
`

const ListTwo = styled(List)`
  ${media.mobile`
    border-top: none;
    padding: 20px 0 40px 0;
    grid-template-rows: repeat(5, 36px);
    margin-top: 0;
  `}
  ${media.tablet`
    padding: 10px 0;
    grid-template-rows: repeat(5, auto);
  `}
`

const StyledH6 = styled(H6)`
  transition: 200ms color ease-in-out;
  color: ${(props: { active: boolean }) => (props.active ? colors.orange : colors.lightText)};
  &:hover {
    color: ${colors.orange};
  }
  ${media.mobile`
    font-size: 48px !important;
  `}
  ${media.desktop`
    font-size: 64px !important;
  `}
`

const StyledP = styled(P)<{ active: boolean; socialMedia: any }>`
  transition: 200ms color ease-in-out;
  color: ${(props) =>
    props.active ? colors.orange : props.socialMedia ? colors.lightText : colors.slate};
  padding-left: 6px;

  &:hover {
    color: ${colors.orange};
  }
  ${media.mobile`
    font-size: 24px;
  `}
  ${media.tablet`
    font-size: 18px;
  `}
`

const LogoContainer = styled.div`
  place-items: center;

  ${media.mobile`
    display: none;
  `}
  ${media.tablet`
    display: grid;
  `}
`

const AddressGrid = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  grid-gap: 20px;

  ${media.mobile`
    padding: 10px 6px;

    & p {
      font-size: 14px;
      line-height: 23px;
    }
  `}

  & .email-copy {
    grid-column: 1 / 3;

    & a {
      font-family: ${fonts.larsseit.bold};
      color: ${colors.lightText};
    }
  }
`

const query = graphql`
  query {
    logo: file(relativePath: { regex: "/logo-white-full.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 425)
      }
    }
    logoText: file(relativePath: { regex: "/logo-white-text.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 85)
      }
    }
  }
`

const Footer = () => {
  const images = useStaticQuery(query)

  const pathname = typeof window !== 'undefined' && window.location.pathname

  const firstColumn = [
    { label: 'Home', url: '/', external: false },
    { label: 'Shop', url: '/products', external: false },
    { label: 'About', url: '/about', external: false },
    { label: 'Blog', url: '/blog', external: false },
    { label: 'Virtual Vet', url: '/consultation', external: false }
  ]
  const secondColumn = [
    {
      label: 'Instagram',
      url: 'https://www.instagram.com/petfinn/',
      external: true,
      socialMedia: true
    },
    // { label: 'Facebook', url: 'https://www.facebook.com/petfinn', external: true, socialMedia: true },
    {
      label: 'My Account',
      url: 'https://shop.petfinn.com/account/login',
      external: true,
      socialMedia: false
    },
    { label: 'FAQs', url: '/faq', external: false },
    { label: 'Affiliate Program', url: '/affiliate', external: false },
    { label: 'Careers', url: '/careers', external: false },
    { label: 'Press', url: 'mailto:press@petfinn.com', external: true, socialMedia: false },
    { label: 'Terms of Use', url: '/terms', external: false },
    { label: 'Privacy', url: '/privacy', external: false },
    { label: 'Accessibility', url: '/accessibility', external: true }
  ]

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.to('.footer', {
      scrollTrigger: '.footer',
      ease: Power3.easeOut,
      opacity: 1,
      duration: 0.25
    })
  }, [])

  interface LinkComponentProps {
    firstCol?: boolean
    item: {
      url: string
      label: string
      external: boolean
      socialMedia?: any
    }
  }

  const LinkComponent: React.FC<LinkComponentProps> = ({ item, firstCol }) => {
    const screen = getScreenType()
    const isMobile = screen === 'mobile'
    if (isMobile && item.socialMedia) return null
    const LinkItem = item.external ? ExternalLink : InternalLink
    return (
      <LinkItem to={item.url}>
        {firstCol ? (
          <StyledH6 active={pathname === item.url}>{item.label}</StyledH6>
        ) : (
          <StyledP active={pathname === item.url} socialMedia={item.socialMedia}>
            {item.label}
          </StyledP>
        )}
      </LinkItem>
    )
  }

  const d = new Date()
  const copyrightYear = d.getFullYear()

  return (
    <FooterOuter className="footer">
      <FooterInner>
        <MobileLogoLink to="/">
          <GatsbyImage
            image={getImage(images.logoText)}
            alt="Finn logo"
            style={{ width: '100%', height: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </MobileLogoLink>
        <List>
          {firstColumn.map((link) => (
            <LinkComponent key={link.url} item={link} firstCol />
          ))}
        </List>
        <ListTwo>
          {secondColumn.map((link) => (
            <LinkComponent key={link.url} item={link} />
          ))}
        </ListTwo>
        <RightContainer>
          <EmailCapture />
          <LogoContainer>
            <LogoLink to="/">
              <GatsbyImage
                image={getImage(images.logo)}
                alt="Finn logo"
                style={{ width: '100%', height: '100%', maxWidth: 425 }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </LogoLink>
          </LogoContainer>
          <AddressGrid>
            <SmallP>
              Finn Wellness, LLC
              <br />
              100 Crosby St., Ste. 506
              <br />
              New York, NY 10012
            </SmallP>
            <SmallP>© {copyrightYear} Finn Wellness</SmallP>
            <SmallP className="email-copy">
              Questions? Email us at{' '}
              <a href="mailto:support@petfinn.com" target="_blank" rel="noopener noreferrer">
                support@petfinn.com
              </a>
            </SmallP>
          </AddressGrid>
        </RightContainer>
        <MenuSocial isFooter />
      </FooterInner>
    </FooterOuter>
  )
}

export default Footer
