import React from 'react'
import styled from 'styled-components'

import ProductCard from './ProductCard'
import { Headline } from '../shared/text'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.lightText};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: 50px 20px 60px 20px;
  `}
  ${media.tablet`
    padding: 86px 20px 60px 20px;
  `}
  ${media.desktop`
    padding: 86px 20px 160px 20px;
    max-width: 1305px;
  `}
`

const Header = styled(Headline)`
  text-align: center;
  margin: 0 auto;
  white-space: pre-line;
  ${media.tablet`
    max-width: 600px;
  `}
  ${media.desktop`
    max-width: 820px;
  `}
`

const Grid = styled('div')<{ cardCols: number }>`
  width: 100%;
  display: grid;
  place-items: center;
  ${media.mobile`
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    margin-top: 50px;
  `}
  ${media.tablet`
    margin-top: 50px;
    grid-template-columns: 1fr 1fr;
  `}
  ${media.desktop`
    max-width: ${(props: { cardCols: number }) => (props.cardCols === 2 ? '900px' : '1305px')};
    grid-template-columns: repeat(${(props: { cardCols: number }) => props.cardCols}, 1fr);
    grid-gap: 28px;
    margin-top: 60px;
    grid-template-rows: auto;
  `}
`

interface ImageProps {
  alt: string
  copy: {
    header: string
    body: string
  }
}

interface CardInterface {
  src: any
  fullSizeSrc: any
  alt: string
  copy: {
    body: string
    header: string
    ingredients?: {
      amount: string
      name: string
    }
  }
}

interface ProductDetailProps {
  data: {
    slug: string
    isBundle: boolean
    oneLiner: string
    card1: Array<CardInterface>
    card2: Array<CardInterface>
    card3: Array<CardInterface>
    card4?: Array<CardInterface>
  }
}
const ProductDetail: React.FC<ProductDetailProps> = ({ data }) => {
  const { slug, isBundle, oneLiner, card1, card2, card3, card4 } = data

  const cards = [card1, card2, card3, card4].filter(Boolean)

  const cardCols = cards.length % 2 === 0 ? 2 : 3

  return (
    <Section>
      <Container>
        <Header>{oneLiner}</Header>
        <Grid cardCols={cardCols}>
          {cards.map((data: ImageProps | ImageProps[], idx: number) => {
            const cardKey = `Card${idx}`
            return (
              <ProductCard
                key={cardKey}
                isBundle={isBundle}
                productSlug={slug}
                cardKey={cardKey}
                card={data}
                idx={idx}
              />
            )
          })}
        </Grid>
      </Container>
    </Section>
  )
}

export default ProductDetail
