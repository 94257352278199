interface HealthDisorder {
  slug: string
  name: string
  description: string
  symptoms: string
  additionalInfo: string
}

interface HealthDisorders {
  [key: string]: HealthDisorder
}

export const healthDisorders: HealthDisorders = {
  'hip-dysplasia': {
    slug: 'hip-dysplasia',
    name: 'Hip Dysplasia',
    description: "a hip deformity developed during a dog's growth that can lead to arthritis",
    symptoms: 'stiffness, weakness in the hind legs, or a reluctance to run, jump, or go up stairs',
    additionalInfo:
      "Hip dysplasia develops during a puppy's growth period, as the ball and socket in their hip joints begin growing at different rates. The condition can develop in dogs as young as 5 months old, but only become noticeable as they get older."
  },
  'patella-luxation': {
    slug: 'patella-luxation',
    name: 'Patella Luxation',
    description: "a condition in which a dog's kneecap moves out of place",
    symptoms: 'repeated limping or running on three legs',
    additionalInfo:
      "The patella is your dog's kneecap, and for some pups, it may be easily dislocated as they move. As a result, your dog can have difficulty bearing weight on this knee and will transfer extra weight to other legs. About half of dogs affected have luxating patellas in both knees."
  },
  'mitral-valve-disease': {
    slug: 'mitral-valve-disease',
    name: 'Mitral valve disease',
    description: "which disrupts normal blood flow in your dog's body and forces the heart to work overtime",
    symptoms: 'a heart murmur that your vet may have detected',
    additionalInfo:
      "Mitral valve disease occurs when your dog's mitral valve doesn't properly help blood move through the heart's chambers. This causes some blood to actually move backwards and makes your dog's heart work even harder to pump blood throughout their whole body."
  },
  'osteochondritis-dissecans': {
    slug: 'osteochondritis-dissecans',
    name: 'Osteochondritis Dissecans',
    description: "an abnormal cartilage growth at the end of your pup's bones",
    symptoms: 'limping on a forelimb or swelling at the joints',
    additionalInfo:
      "Osteochondritis dissecans is a condition in which a puppy's cartilage development results in a loose flap of cartilage at the end their bones, causing joint discomfort and, in some cases, arthritis."
  },
  lcpd: {
    slug: 'lcpd',
    name: 'Legg-Calve-Perthes Disease (LCPD)',
    description: "a condition that causes lameness in your dog's hip joint",
    symptoms: 'holding one leg up while moving, having trouble getting up, or favoring one leg',
    additionalInfo:
      'Legg-Calve-Perthes disease (LCPD) occurs when the "ball" in your dog\'s ball-and-socket formation of their hip joint begins to degenerate. As the ball changes shape, it fits less comfortably in its socket, and leads to pain, discomfort, and potentially arthritis.'
  },
  hypothyroidism: {
    slug: 'hypothyroidism',
    name: 'Hypothyroidism',
    description: "a condition in which your dog's thyroid produces low levels of hormones",
    symptoms: 'haircoat changes, lethargy, weight gain, or an intolerance to cold',
    additionalInfo:
      "Hypothyroidism interferes with a dog's normal thyroid hormone production, which subsequently slows the metabolism and can cause symptoms like sluggishness and weight gain."
  },
  pannus: {
    slug: 'pannus',
    name: 'Pannus',
    description: "an eye condition that affects a dog's cornea and third eyelid",
    symptoms: 'redness, tears, or a grayish, pink film on the eyes',
    additionalInfo:
      "Pannus is a progressive autoimmune disorder that creates a grayish, pink film on the eyes and gradually makes the cornea opaque, impairing a dog's vision. While exact causes of the disorder remain unclear, related factors include intense exposure to direct sunlight, high altitudes, and airborne irritants."
  },
  dcm: {
    slug: 'dcm',
    name: 'Dilated Cardiomyopathy (DCM)',
    description: 'a heart disease in dogs that causes the heart to become enlarged and weaker',
    symptoms: 'loss of appetite, increased heart rate, or coughing',
    additionalInfo:
      "Dilated cardiomyopathy is a heart condition that impairs the heart's ability to generate enough pressure to pump blood throughout the body. This causes blood to back up in the heart chambers, which makes the heart enlarged and stops it from functioning properly."
  },
  'progressive-retinal-atrophy': {
    slug: 'progressive-retinal-atrophy',
    name: 'Progressive Retinal Atrophy',
    description: 'a genetic disease that causes retinal degeneration and vision loss over time',
    symptoms: 'dilated pupils or difficulty seeing at night or in bright light (e.g., bumping into things)',
    additionalInfo:
      "Progressive retinal atrophy (PRA) primarily impacts the cells of your dog's retinas called rods and cones, which support proper vision function. In PRA, these rods and cones either developed abnormally or begin to deteriorate and impair a dog's ability to see movement, color, and in low light conditions."
  },
  'skin-lesions': {
    slug: 'skin-lesions',
    name: 'Skin Lesions',
    description: 'a common health condition in hairless dogs',
    symptoms: 'intense itching, sores, red skin, or bald spots',
    additionalInfo:
      'While the Peruvian Inca Orchid normally has smoothe, supple skin, their lack of hair makes them more vulnerable to skin irritations, such as lesions. Health experts urge extra care for these dogs by being mindful of extended exposure to sunlight, airborne intoxicants, and frequent bathing.'
  },
  'tricuspid-valve-dysplasia': {
    slug: 'tricuspid-valve-dysplasia',
    name: 'Tricuspid Valve Dysplasia',
    description: 'a heart defect that can lead to an enlarged heart',
    symptoms: 'general weakness, exercise intolerance, or abnormal heart sounds',
    additionalInfo:
      "Tricuspid valve dysplasia is a deformity in the heart's tricuspid valve and supporting structures. The malformation can lead to a backflow of blood to the right atrium, and, in serious cases, an enlarged heart."
  },
  'degenerative-myelopathy': {
    slug: 'degenerative-myelopathy',
    name: 'Degenerative Myelopathy',
    description: 'a progressive disease of the spinal cord',
    symptoms: 'difficulty getting up, reliance on the hind feet, or weakness in the rear',
    additionalInfo:
      "Degenerative myelopathy (DM) develops in your dog's spinal cord with degeneration of white matter, which transmits movement and sensory commands between the brain and limbs. Though DM unfortunately progresses over time, it is generally a pain-free condition for your pup."
  },
  'subaortic-stenosis': {
    slug: 'subaortic-stenosis',
    name: 'Subaortic Stenosis',
    description: 'a heart defect that blocks blood flow',
    symptoms: 'lethargy, coughing, difficulty breathing, or weakness following exercise or excitement',
    additionalInfo:
      "Subaortic stenosis is an inherited heart defect in which abnormal tissue is located below the heart's aortic valve. This obstructs the heart's ability to pump blood throughout the body and puts extra strain on the muscle as it works to regulate blood flow."
  },
  'demodex-red-mite-mange': {
    slug: 'demodex-red-mite-mange',
    name: 'Demodex Red Mite Mange',
    description: 'a parasitic skin condition caused by mites',
    symptoms: 'hair loss, redness, scaling, or infections',
    additionalInfo:
      "Demodex mange (also called 'red mite' mange) is a skin condition in which tiny mites are passed to pups from their mothers within a few days of birth. These mites live deep in hair follicles and generally cause no trouble, though dogs that are older, neglected, or have a weakened immune system are at higher risk for complications."
  },
  'sebaceous-adenitis': {
    slug: 'sebaceous-adenitis',
    name: 'Sebaceous Adenitis',
    description: 'an inflammatory skin disease affecting skin glands',
    symptoms: 'spot baldness, small clumps of matted hair, or white scales on the skin',
    additionalInfo:
      "Sebaceous adenitis is a skin disease in which an inflammatory reaction is targeted against a dog's sebaceous glands, which produce the substance that promote supple skin and soft hair in your pooch. The disease generally occurs in young to middle-aged dogs and appears in over 50 breeds."
  },
  cataracts: {
    slug: 'cataracts',
    name: 'Cataracts',
    description: "an opacity in a dog's eye lens that causes blurry vision",
    symptoms: 'clumsiness, scratching/rubbing of the eyes, or a blueish, gray, or white layer in the eyes',
    additionalInfo:
      'Cataracts occurs when there is cloudiness in the lens of an eye that can prevent light from coming through to the retina and cause vision impairment. If cataracts occupies less than 30% of the lens, vision can likely remain normal; if it covers more than 60% of the eye, vision will likely be noticeably impaired.'
  },
  'patent-ductus-arteriosus': {
    slug: 'patent-ductus-arteriosus',
    name: 'Patent Ductus Arteriosus',
    description: 'a heart disorder in which there is an improper flow of blood',
    symptoms: 'coughing, exercise intolerance, shortness of breath, or abnormal heart pulsing',
    additionalInfo:
      'Patent ductus arteriosus is a heart defect in which a blood vessel that carries blood through the heart does not close shortly after birth like it should. As a result, too much blood may be carried to the lungs, causing fluid buildup and extra strain on the heart.'
  },
  dermatomyositis: {
    slug: 'dermatomyositis',
    name: 'Dermatomyositis',
    description: 'an inflammatory disease that affects the skin',
    symptoms: 'lesions, hair loss, redness, or crusting of the skin',
    additionalInfo:
      'Dermatomyositis is an inflammatory disease that primarily affects the skin (though it may also affect muscles and blood vessels) and causes dramatic inflammation in the tissue. Lesions are usually primary signs of this condition, which may be seen as crusty erosions, areas of hair loss, or ulcers.'
  },
  glaucoma: {
    slug: 'glaucoma',
    name: 'Glaucoma',
    description: "an eye condition in which there's an increase of pressure in your dog's eye",
    symptoms: 'a cloudy cornea, redness of the eye, or high pressure within the eye',
    additionalInfo:
      "Glaucoma is an eye condition associated with inadequate fluid drainage in the eye, which can cause damage to your dog's retina and optic nerve."
  },
  'atopic-dermatitis': {
    slug: 'atopic-dermatitis',
    name: 'Atopic Dermatitis',
    description: 'a skin disease caused by chronic inflammation',
    symptoms: 'licking, redness, greasy skin, or excessive scratching and itching',
    additionalInfo:
      'Atopic dermatitis is a chronic skin disease primarily caused by allergens, including those from the environment (pollen, dust, mold), food, and fleas. A few of the most commonly affected areas on dogs include ears, feet, groin, and underbelly.'
  },
  'pemphigus-foliaceus': {
    slug: 'pemphigus-foliaceus',
    name: 'Pemphigus Foliaceus',
    description: "an autoimmune disease that causes your dog's immune system to attack their skin and cells",
    symptoms: 'hair loss or scabs around the face, head, ears, or footpad',
    additionalInfo:
      "Pemphigus foliaceus occurs when your dog's body produces autoantibodies that mistakenly attack skin cells, as if they are harmful to the body. The disease is most common in middle-aged dogs but could happen at any age."
  },
  'pulmonic-stenosis': {
    slug: 'pulmonic-stenosis',
    name: 'Pulmonic Stenosis',
    description: "a heart condition in which there's improper blood flow through the pulmonary valve",
    symptoms: 'fatigue, exercise intolerance, fluid accumulation in the abdomen, or fainting',
    additionalInfo:
      'Pulmonic stenosis develops when the pulmonary valve is poorly formed and narrows the passage of blood in its valve as blood makes its way to the lungs. Conditions can vary between mild and severe, and the heart defect may also be associated with other heart conditions.'
  },
  seborrhea: {
    slug: 'seborrhea',
    name: 'Seborrhea',
    description: 'a condition that causes dry skin or greasy hair in dogs',
    symptoms: 'scales, flakiness, itchiness, or a worsening odor',
    additionalInfo:
      "Seborrhea occurs when a dog's sebaceous glands produce excess sebum, an oily substance that lubricates hair and skin. This causes the skin to be scaly, itchy, and red. There are two forms of seborrhea _ oily and dry (though the majority of dogs can have a combination of both) _ and an odor may develop due to a buildup of oil on skin and hair."
  },
  comedones: {
    slug: 'comedones',
    name: 'Comedones',
    description: "which are blackhead pimples in your dog's skin",
    symptoms: "pimples, generally found on your pup's lower chest",
    additionalInfo:
      'Comedones are generally a cosmetic issue and are "blackheads" or pimples under the skin of your dog\'s belly. Comedones may sometimes become infected and require treatment with antibiotics but can otherwise benefit from a supplementation omega-3 fatty acids.'
  },
  'follicular-dysplasia': {
    slug: 'follicular-dysplasia',
    name: 'Follicular Dysplasia',
    description: 'a skin condition caused by abnormal hair growth',
    symptoms: 'scratching, patches of hair loss, or dry, red skin',
    additionalInfo:
      'Follicular dysplasia is a skin condition caused by hair follicles that have developed abnormally and produce weakened hairs that break easily. Affected pups may also see a color change in their hair, abnormal retention, or little to no growth. The condition is commonly found on the trunk of the dog.'
  },
  mucopolysaccharidoses: {
    slug: 'mucopolysaccharidoses',
    name: 'Mucopolysaccharidoses',
    description: 'a group of metabolic disorders',
    symptoms: 'slow growth, large chest, corneal opacity, or weakness in the hind legs',
    additionalInfo:
      'Mucopolysaccharidoses are a group of metabolic disorders that can cause abnormal bodily deformities. The disorder is characterized by the accumulation of glycosaminoglycans which help build bones, cartilage, skin, tendons, and the fluid responsible for lubricating joints. The disease is rare, and unfortunately there is yet to be a cure.'
  },
  'elbow-dysplasia': {
    slug: 'elbow-dysplasia',
    name: 'Elbow Dysplasia',
    description: "a condition caused by multiple developmental abnormalities that affect a dog's elbow joint",
    symptoms: 'difficulty getting up, swollen joints, pain in the forelimbs, or stiffness following exercise',
    additionalInfo:
      "Elbow dysplasia develops when the three bones that comprise a dog's elbow _ the radius, ulna, and humerus _ do not develop in a way that they can fit together. This causes abnormal weight distribution on certain areas of the joint which leads to discomfort, pain, and possibly arthritis."
  },
  epilepsy: {
    slug: 'epilepsy',
    name: 'Epilepsy',
    description: 'a chronic condition that causes seizures',
    symptoms: 'loss of voluntary control, usually combined with muscle twitching, jerking, or convulsing',
    additionalInfo:
      'Epilepsy is a neurological disorder that causes dogs to have seizures. These seizure episodes occur upon abnormal, uncontrolled bursts of activity in the brain and affect how your pup looks and behaves for a short period of time. Each seizure can last anywhere from less than a minute to several minutes long.'
  },
  'lundehund-syndrome': {
    slug: 'lundehund-syndrome',
    name: 'Lundehund Syndrome',
    description: 'a digestive disorder specific to this breed',
    symptoms: 'diarrhea, loss of appetite, a swollen abdomen, or swollen legs',
    additionalInfo:
      "Lundehund syndrome is a combination of multiple stomach and intestinal diseases, characterized by the dog's inability to absorb nutrients and protein from food. This affects all Norwegian Lundehunds, though some dogs may be either asymptomatic or exhibit very mimimal signs."
  },
  'collie-eye-anomaly': {
    slug: 'collie-eye-anomaly',
    name: 'Collie Eye Anomaly',
    description: 'an inherited eye condition',
    symptoms: 'eyeballs appearing smaller than normal or vision impairment',
    additionalInfo:
      'Collie eye anomaly (CEA) is characterized by an improperly developed choroid, which is a layer of tissue in the eye that supplies blood and nutrients to the retina. This makes it more difficult for eyes in affected dogs to absorb light and receive an adequate blood supply. While CEA is named after a specific breed, it can also affect many other herding dogs.'
  }
}
