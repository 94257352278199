import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { gsap, Power3 } from 'gsap'

import { P } from '../shared/text'
import { fonts } from '../../styles/variables'
import { media } from '../../styles/util'

const WhiteContainer = styled.div`
  position: relative;
  z-index: 200;

  display: flex;
  place-items: center;

  width: 100%;
  height: 48px;

  background-color: white;
`

const Container = styled(Link)<{ bg: string; color: string; hovercolor: string }>`
  width: 100%;
  height: 100%;

  display: flex;
  place-items: center;

  background-color: ${(props) => props.bg};

  &:link {
    color: ${(props) => props.color};
  }

  &:hover {
    color: ${(props) => props.hovercolor} !important;
  }
`

const CopyP = styled(P)`
  visibility: hidden;

  width: 100%;

  font-family: ${fonts.larsseit.medium};
  text-transform: uppercase;
  text-align: center;

  ${media.mobile`
    font-size: 16px;
    white-space: pre-line;
    line-height: 1.2;
  `}
  ${media.tablet`
    font-family: ${fonts.larsseit.bold};
    font-size: 18px;
    letter-spacing: 1px;
    white-space: normal;
    line-height: 1;
  `}
  ${media.desktop`
    font-size: 18px;
  `}
`

interface PromoBarProps {
  copy: string
  bg: string
  color: string
  hoverColor: string
  linkUrl: string
}

const PromoBar = (props: PromoBarProps) => {
  const { copy, bg, color, hoverColor, linkUrl } = props

  useEffect(() => {
    const tl = gsap.timeline()
    tl.from('.PromoBar_Container', { y: -50, ease: Power3.easeOut, delay: 0.5, duration: 0.75 })
    tl.to('.PromoBar_CopyP', { autoAlpha: 1 }, '<')
  }, [])

  return (
    <WhiteContainer>
      <Container
        bg={bg}
        color={color}
        hovercolor={hoverColor}
        to={linkUrl}
        className="PromoBar_Container"
      >
        <CopyP className="PromoBar_CopyP">{copy}</CopyP>
      </Container>
    </WhiteContainer>
  )
}

export default PromoBar
