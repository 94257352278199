import React from 'react'
import styled from 'styled-components'
import { InfoCircleOutlined } from '@ant-design/icons'

import { StretchCTAButton } from 'src/components/shared/buttons'
import { P } from 'src/components/shared/text'
import { colors } from 'src/styles/variables'
import { media } from 'src/styles/util'

import AfterpayLogo from 'src/assets/img/pdp/afterpay/logo.png'

const AfterpayCopy = styled(P)`
  & img {
    height: 20px;
    margin: 0 3px;
  }

  ${media.mobile`
    text-align: center;
    white-space: pre-line;
    margin: 16px auto 30px auto;
  `}
  ${media.tablet`
    text-align: left;
    margin: 12px 0 0 12px;
  `}
  ${media.desktop`
    white-space: normal;
  `}
`

const StyledInfoIcon = styled(InfoCircleOutlined)`
  margin-left: 3px;
  cursor: pointer;
`

interface CtaButtonsProps {
  brandColors: {
    bg: string
    color: string
  }
  price: number
  hasSubscriptionVariant: boolean
  isSubscription: boolean
  handleAddToCart: () => void
  setAfterpayModalOpen: (open: boolean) => void
  outOfStock?: boolean
}

const CtaButtons: React.FC<CtaButtonsProps> = (props) => {
  const {
    price,
    outOfStock,
    handleAddToCart,
    hasSubscriptionVariant,
    isSubscription,
    setAfterpayModalOpen,
    brandColors: { bg, color }
  } = props

  const CTACopy = price ? `$${price.toFixed(2)}  ·  Buy Now` : 'Buy Now'
  const afterpayAmount = price / 4
  const formattedAfterpayAmount = afterpayAmount.toFixed(2)
  const afterpayCopy = `or 4 payments of <strong>$${formattedAfterpayAmount}</strong>\n with <img src="${AfterpayLogo}" alt="Afterpay logo" /> `

  return (
    <>
      {outOfStock ? (
        <StretchCTAButton copy="Out of Stock" bg={colors.mediumGrey} color="white" />
      ) : (
        <StretchCTAButton
          copy={CTACopy}
          bg={colors[bg]}
          color={colors[color]}
          onClick={handleAddToCart}
          arrow
        />
      )}
      {!hasSubscriptionVariant && <br />}
      {!isSubscription && (
        <AfterpayCopy>
          <span dangerouslySetInnerHTML={{ __html: afterpayCopy }} />
          <StyledInfoIcon onClick={() => setAfterpayModalOpen(true)} />
        </AfterpayCopy>
      )}
    </>
  )
}

export default CtaButtons
