import React from 'react'
import styled from 'styled-components'

import { LargeP, P, MonoP } from './text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'

const ArrowSVG = styled.svg`
  margin-left: 9px;
  fill: inherit;
  margin-bottom: -9px;
  ${media.mobile`
    height: 26px;
    width: 26px;
  `}
  ${media.tablet`
    height: 18px;
    width: 18px;
  `}
  ${media.desktop`
    height: 24px;
    width: 24px;
  `}
`

const ArrowSVGPath = styled.path`
  fill: inherit;
`

const Button = styled('button')<{ bg?: string; color?: string; noScale?: boolean }>`
  background-color: ${props => props.bg || colors.navy};
  border: 1px solid ${props => props.bg || colors.navy};
  color: ${props => props.color || colors.white};
  fill: ${props => props.color || colors.white};
  border-radius: 60px;
  outline: none;
  margin: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 100ms all ease-in-out;
  white-space: pre-wrap;
  width: fit-content;

  ${media.mobile`
    padding: 12px 24px 15px 24px;
  `}
  ${media.desktop`
    padding: 15px 36px 17px 36px;
  `}

  &:hover {
    ${media.desktop`
      transform: ${(props: { noScale?: boolean }) => (props.noScale ? 'none' : 'scale(1.07)')};
    `}
  }
`

const StretchBtn = styled(Button)`
  ${media.mobile`
    width: 100%;
    padding: 12px 27px 14px 27px;
  `}
  ${media.tablet`
    width: fit-content;
    padding: 12px 27px;
    font-size: 26px;
  `}
`

const Text = styled(LargeP)`
  display: inline-block;
  line-height: 1 !important;
  ${media.mobile`
    font-size: 20px;
  `}
  ${media.tablet`
    font-size: 24px;
  `}
  ${media.desktop`
    font-size: 24px;
  `}
`

const StretchText = styled(Text)`
  font-family: ${fonts.larsseit.regular};
  font-size: 26px !important;
`

const SecondaryBtn = styled(Button)`
  background-color: ${({ bg }) => bg || 'transparent'};
  border: 1px solid ${props => (props.bg ? props.bg : props.color || colors.navy)};
  color: ${props => props.color || colors.navy};

  transition: 150ms all ease-in-out;

  ${media.desktop`
    padding: 13px 20px 15px 20px;
  `}

  & ${ArrowSVGPath} {
    fill: ${props => props.color || colors.navy};
  }
`

const SmallSecondaryBtn = styled(SecondaryBtn)`
  ${media.mobile`
    padding: 9px 15px;
  `}
  ${media.tablet`
    height: 45px;
  `}
  ${media.desktop`
    padding: 9px 15px;
  `}
`

const NavBtn = styled(Button)<{ navDrawer?: boolean; dark?: boolean; color?: string }>`
  border-radius: 50px;
  border-color: ${props => props.color || colors.navy};
  background-color: ${props =>
    props.color ? props.color : props.navDrawer ? colors.white : 'transparent'};
  transition: 200ms all ease-in-out;
  height: fit-content;
  width: fit-content;
  ${media.mobile`
    padding: 9px 24px 7px 24px;
    margin-right: 12px;
  `}
  ${media.tablet`
    height: 45px;
  `}
  ${media.desktop`
    padding: 15px 30px 13px 30px;
    margin-right: 20px;
  `}
  &:hover {
    transform: scale(1);
    background-color: ${props => (props.dark ? colors.white : colors.navy)};
    border-color: ${props => (props.navDrawer ? colors.white : colors.navy)};
    ${media.desktop`
      padding: 15px 30px 13px 30px;
      margin-right: 20px;
    `}
    & p {
      font-size: 16px !important;
      color: ${props => (props.dark ? colors.navy : colors.lightText)};
    }
  }
  & p {
    font-size: 16px !important;
    color: ${colors.navy};
  }
`

const ActionBtn = styled(SecondaryBtn)`
  color: ${colors.slate};
  border: 1px solid ${colors.slate};
  transition: 150ms all ease-in-out;
  line-height: 1 !important;
  margin: 6px;
  &:hover {
    transform: scale(1);
    background-color: ${colors.navy};
    color: ${colors.lightText};
    border-color: ${colors.navy};
    padding: 9px 12px;
  }
  ${media.mobile`
    padding: 9px 12px;our
  `}
  ${media.desktop`
    padding: 9px 12px;
  `}
`

const ConsultationBtn = styled(SecondaryBtn)`
  ${media.mobile`
    padding: 12px 24px 15px 24px;
  `}
  ${media.desktop`
    padding: 10px 13px 10px 17px;
  `}
`

interface ButtonProps {
  copy: string
  type?: 'button' | 'submit' | 'reset' | undefined
  onClick?: (props: any) => void
  bg?: string
  color?: string
  arrow?: boolean
  dark?: boolean
  navDrawer?: boolean
  noScale?: boolean
  className?: string
}

const ArrowIcon = () => (
  <ArrowSVG viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ArrowSVGPath d="M6.8 0L13.5 6.1L6.8 12.2L5.8 11L10.2 7H0V5.2H10.2L5.7 1.2L6.8 0Z" />
  </ArrowSVG>
)

export const PrimaryButton: React.FC<ButtonProps> = ({
  copy,
  onClick,
  arrow,
  bg,
  color,
  type,
  noScale = false,
  className
}) => {
  return (
    <Button
      type={type || 'button'}
      onClick={onClick}
      bg={bg}
      color={color}
      noScale={noScale}
      className={className}
    >
      <Text>{copy}</Text>
      {arrow && <ArrowIcon />}
    </Button>
  )
}

export const StretchCTAButton: React.FC<ButtonProps> = ({
  copy,
  onClick,
  arrow,
  bg,
  color,
  type,
  className
}) => {
  return (
    <StretchBtn
      type={type || 'button'}
      onClick={onClick}
      bg={bg}
      color={color}
      className={className}
    >
      <StretchText>{copy}</StretchText>
      {arrow && <ArrowIcon />}
    </StretchBtn>
  )
}

export const SecondaryButton: React.FC<ButtonProps> = ({
  copy,
  onClick,
  arrow,
  type,
  color,
  bg,
  className
}) => {
  return (
    <SecondaryBtn
      type={type || 'button'}
      bg={bg}
      color={color}
      onClick={onClick}
      className={className}
    >
      <Text>{copy}</Text>
      {arrow && <ArrowIcon />}
    </SecondaryBtn>
  )
}

export const SmallSecondaryButton: React.FC<ButtonProps> = ({
  copy,
  onClick,
  arrow,
  type,
  color
}) => {
  return (
    <SmallSecondaryBtn type={type || 'button'} color={color} onClick={onClick}>
      <P>{copy}</P>
      {arrow && <ArrowIcon />}
    </SmallSecondaryBtn>
  )
}

export const NavButton: React.FC<ButtonProps> = ({
  copy,
  onClick,
  color,
  navDrawer,
  type,
  arrow
}) => {
  return (
    <NavBtn type={type || 'button'} onClick={onClick} color={color} navDrawer={navDrawer}>
      <MonoP>{copy}</MonoP>
      {arrow && <ArrowIcon />}
    </NavBtn>
  )
}

export const ActionButton: React.FC<ButtonProps> = ({ copy, onClick, type }) => {
  return (
    <ActionBtn type={type || 'button'} onClick={onClick}>
      <MonoP>{copy}</MonoP>
    </ActionBtn>
  )
}

export const ConsultationButton: React.FC<ButtonProps> = ({ copy, onClick, arrow, type }) => {
  return (
    <ConsultationBtn type={type || 'button'} onClick={onClick}>
      <Text>{copy}</Text>
      {arrow && <ArrowIcon />}
    </ConsultationBtn>
  )
}
