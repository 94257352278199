import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const LOCALSTORAGE_KEY = 'persisted-atoms'

const { persistAtom } = recoilPersist({
  key: LOCALSTORAGE_KEY
})

export const availableDashboardsAtom = atom({
  key: 'AvailableDashboardsState',
  default: [],
  effects_UNSTABLE: [persistAtom]
})
