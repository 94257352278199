import axios from 'axios'
import * as rax from 'retry-axios'

rax.attach()

const raxApiRetryOptions = {
  retry: 5, // number of retry when facing 4xx or 5xx
  noResponseRetries: 5, // number of retry when facing connection error
  onRetryAttempt: (err: any) => {
    const cfg = rax.getConfig(err)
    console.log(`Retry attempt #${cfg?.currentRetryAttempt}`) // track current trial
  }
}

const fetchShopifyProductDetail = async () => {
  const shopifyApiEndpoint = `https://petfinn.myshopify.com/api/2022-01/graphql.json`
  const query = `
    {
      products(first:100) {
        edges {
          node {
            id
            title
            variants(first: 10) {
              edges {
                node {
                  id
                  compareAtPrice
                  priceV2 {
                    amount
                  }
                  sellingPlanAllocations(first:10) {
                    edges {
                      node {
                        sellingPlan {
                          id
                          name
                        }
                        priceAdjustments {
                          price {
                            amount
                          }
                          compareAtPrice {
                            amount
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  const options = {
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN
    },
    raxConfig: raxApiRetryOptions,
    timeout: 5000
  }

  const { data: shopifyData } = await axios.post(shopifyApiEndpoint, { query }, options)
  return shopifyData.data.products.edges.map((edge) => edge.node)
}

export default fetchShopifyProductDetail
