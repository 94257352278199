import React from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'
import { media } from 'src/styles/util'

import AfterpayDesktop from 'src/assets/img/pdp/afterpay/desktop-modal.png'
import AfterpayMobile from 'src/assets/img/pdp/afterpay/mobile-modal.png'

import { IAfterpayModalProps } from 'src/types/pdp'

const DesktopImgWrapper = styled.div`
  ${media.mobile`
    display: none;
  `}
  ${media.tablet`
    display: block;
    width: 600px
  `}
  ${media.desktop`
    width: 60vw;
    max-width: 700px;
  `}
`

const MobileImgWrapper = styled(DesktopImgWrapper)`
  ${media.mobile`
    display: block;
    height: 100vh;
    width: 100%;
 `}
  ${media.tablet`
    display: none
  `}
`

const AfterpayModal: React.FC<IAfterpayModalProps> = ({ open, setOpen }) => {
  const closeModal = () => setOpen(false)

  return (
    <Modal
      visible={open}
      onCancel={closeModal}
      closable={false}
      maskClosable
      centered
      footer={null}
      bodyStyle={{
        width: 'auto',
        padding: 0
      }}
    >
      <DesktopImgWrapper>
        <img src={AfterpayDesktop} alt="Afterpay infographic" onClick={closeModal} />
      </DesktopImgWrapper>
      <MobileImgWrapper>
        <img src={AfterpayMobile} alt="Afterpay infographic" onClick={closeModal} />
      </MobileImgWrapper>
    </Modal>
  )
}

export default AfterpayModal
