import React, { useEffect } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import { Parallax } from 'react-scroll-parallax'

import { H1 } from './text'
import { SecondaryButton } from './buttons'
import { media, getScreenType } from '../../styles/util'
import { colors } from '../../styles/variables'

const Wrapper = styled.div`
  width: 100%;
  background-color: transparent;
  z-index: 10;
  position: relative;

  .isPageHeader {
    ${media.mobile`
      text-align: center;
      margin-top: 100px;
    `}
    ${media.desktop`
      margin-top: 120px;
      text-align: left;
    `}
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${(props) => props.color || colors.navy};
  margin: 0 auto;
  white-space: pre-line;
  opacity: 0;

  ${media.mobile`
    padding: 40px 10px 0 10px;
    justify-content: center;

    & > h1 {
      text-align: center;
      font-size: ${(props: { mobileFontSize?: number }) =>
        `${props.mobileFontSize}px` || 'initial'} !important;
    }
  `}
  ${media.tablet`
    text-align: center;
    padding: 40px 10px 0 10px;
    justify-content: ${(props: { leftAlign: boolean }) =>
      props.leftAlign ? 'space-between' : 'center'};

    & > h1 {
      width: 100%;
      font-size: ${(props: { tabletFontSize?: number }) => `${props.tabletFontSize}px`} !important;
    }
  `}
  ${media.desktop`
    max-width: 1440px;
    justify-content: ${(props: { leftAlign: boolean; hasButton: boolean }) =>
      props.leftAlign || props.hasButton ? 'space-between' : 'center'};

    padding: 70px 20px 0 20px;

    & > h1 {
      text-align: ${(props: { leftAlign: boolean; hasButton: boolean }) =>
        props.leftAlign || props.hasButton ? 'left' : 'center'};
      font-size: ${(props: { fontSize?: number }) => `${props.fontSize}px` || 'initial'} !important;
    }
  `}
`

const ButtonWrapper = styled.div`
  min-width: 190px;

  ${media.mobile`
    display: none;
  `}
  ${media.desktop`
    display: block;
  `};
`

interface DropHeaderProps {
  id?: string
  title: string
  titleMobile?: string // In case Mobile needs special line breaks
  titleTablet?: string // In case Tablet needs special line breaks
  isPageHeader?: boolean // Indicates it is the main page header (as opposed to a section)
  leftAlign?: boolean // Indicates it is the main page header (as opposed to a section)
  color?: string
  buttonCopy?: string
  onClick?: () => void
  className?: string // optional classname to enable wrapper styling using Styled-Components
  fontSize?: number
  tabletFontSize?: number
  mobileFontSize?: number
}

const DropHeader: React.FC<DropHeaderProps> = (props) => {
  const {
    id,
    title,
    titleMobile,
    titleTablet,
    leftAlign = false,
    isPageHeader,
    color,
    buttonCopy,
    onClick,
    className,
    fontSize,
    mobileFontSize,
    tabletFontSize
  } = props

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.to('.dropheader__container', {
      scrollTrigger: `.${className}` || '.dropheader__container',
      opacity: 1,
      duration: 2,
      delay: isPageHeader ? 1 : 0
    })
  }, [])

  const screenType = getScreenType()
  const isMobile = screenType === 'mobile'
  const isTablet = screenType === 'tablet'

  let displayedTitle = title
  if (titleTablet && isTablet) displayedTitle = titleTablet
  if (titleMobile && isMobile) displayedTitle = titleMobile

  return (
    <Wrapper>
      <Parallax y={[-10, 10]}>
        <Container
          color={color}
          leftAlign={!!buttonCopy || leftAlign}
          hasButton={!!buttonCopy}
          className={`dropheader__container ${isPageHeader ? 'isPageHeader' : ''} ${className}`}
          fontSize={fontSize}
          tabletFontSize={tabletFontSize}
          mobileFontSize={mobileFontSize}
        >
          <H1 id={id}>{displayedTitle}</H1>
          {buttonCopy && (
            <ButtonWrapper>
              <SecondaryButton copy={buttonCopy} onClick={onClick} arrow />
            </ButtonWrapper>
          )}
        </Container>
      </Parallax>
    </Wrapper>
  )
}

export default DropHeader
