import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { useRecoilState } from 'recoil'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gsap, Power3 } from 'gsap'

import { H4, H6, P, LargeP } from '../shared/text'
import { PrimaryButton } from '../shared/buttons'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'

import { productDetailState } from '../../actions/products'

import { trackCollectionViewEvent, trackProductClickEvent } from '../../utils/event-tracking'

const wiggle = keyframes`
  0% { transform: rotate(0deg); }
  30% { transform: rotate(2deg) scale(1.1); }
  70% { transform: rotate(-2deg) scale(1.1); }
  100% { transform: rotate(0deg); }
`

const Section = styled.div`
  width: 100%;
  position: relative;

  ${media.mobile`
    padding: 40px 0;
  `}
  ${media.tablet`
    padding: 40px 40px 60px 40px;
  `}
  ${media.desktop`
    padding: 40px 30px 100px 30px;
  `}
`

const Grid = styled('div')<{ cols: number }>`
  width: 100%;
  margin: 0 auto;
  display: grid;
  height: fit-content;

  ${media.mobile`
    align-items: center;
    text-align: center;
  `}
  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 20px;
    grid-gap: 50px 20px;
    max-width: 650px;
  `}
  ${media.desktop`
    grid-template-columns: repeat(${(props) => props.cols}, 1fr);
    width: 100%;
    margin-top: 60px;
    grid-gap: 30px;

    max-width: 1400px;
  `}
`

const CircularBadge = styled.div`
  background-color: ${colors.orange};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  z-index: 1;
  position: absolute;
  top: 15px;
  right: -12px;
  color: ${colors.navy} !important;
  font-family: ${fonts.larsseit.medium};
  font-size: 14px;
  line-height: 16px;
`

const OutOfStockBadge = styled(CircularBadge)`
  background-color: ${colors.teal};
  top: -26px;
  right: 0;
`

const ProductGroup = styled('div')<{ noBorder: boolean }>`
  display: grid;
  place-items: center;
  grid-row-gap: 14px;

  text-align: center;
  margin: 0 auto;

  height: 100%;
  width: 100%;

  & img {
    transition: 200ms all ease;
  }

  ${media.mobile`
    padding: 40px 12px 32px 12px;
    border: ${(props) => (props.noBorder ? 'none' : `1px solid ${colors.lightText}`)};
    height: 100%;
  `}
  ${media.tablet`
    padding: 0 20px;
    border: none;
    height: fit-content;
    grid-template-rows: auto auto auto minmax(100px, auto) auto;
    margin-top: 0;
  `}
  ${media.desktop`
    max-width: 100%;
    padding: 0;
  `}
`

const ProductName = styled(H4)`
  text-align: center;
  ${media.mobile`
    font-size: 42px;
  `}
`

const PriceGrid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`

const PriceText = styled(H6)`
  line-height: 1 !important;
  color: ${colors.orange};
  ${media.desktop`
    font-size: 32px !important;
  `}
`

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  height: auto;
  margin: 0 auto;
  position: relative;

  ${media.mobile`
    width: 80vw;
  `}
  ${media.tablet`
    width: 265px;
  `}
  ${media.desktop`
    width: 100%;
  `}

  &:hover {
    animation: ${wiggle} 250ms;
  }
`

const Description = styled(P)`
  color: ${colors.slate};
  line-height: 1.4 !important;
  text-align: center;
  max-width: 300px;
`

const StruckText = styled(LargeP)`
  color: ${colors.slate};
  text-decoration: line-through;
  margin-left: 12px;
  padding-bottom: 4px;
  line-height: 1 !important;
`

const Title = styled(H4)`
  text-align: center;
  margin-top: 42px;
  ${media.tablet`
    margin-top: 20px;
  `}
  ${media.desktop`
    margin-top: 0;
  `}
`

const PointerWrapper = styled.div`
  width: 100%;
  cursor: pointer;

  &:hover {
    color: ${colors.orange};
  }
`

const query = graphql`
  query {
    chillPup: file(relativePath: { regex: "/tins/chill-pup-bundle.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    activePup: file(relativePath: { regex: "/tins/active-pup-bundle.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    prettyPup: file(relativePath: { regex: "/tins/pretty-pup-bundle.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    comfyPup: file(relativePath: { regex: "/tins/comfy-pup-bundle.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`

const BuyABundle = ({
  isProductLibrary,
  title,
  noBorder,
  showBadge,
  isAlternate
}: {
  isProductLibrary?: boolean
  title?: string
  noBorder?: boolean
  showBadge?: boolean
  isAlternate?: boolean
}) => {
  const images = useStaticQuery(query)
  const [productMap] = useRecoilState(productDetailState)
  const slugMap = productMap?.bySlug

  useEffect(() => {
    if (slugMap) {
      const from = { opacity: 0, y: 50 }
      const to = { ease: Power3.easeOut, opacity: 1, duration: 1, y: 0 }
      gsap.fromTo('.Products_BuyABundle_TheChillPup', from, { ...to, delay: 0.25 })
      gsap.fromTo('.Products_BuyABundle_ThePrettyPup', from, { ...to, delay: 0.3 })
      gsap.fromTo('.Products_BuyABundle_TheActivePup', from, { ...to, delay: 0.35 })
      gsap.fromTo('.Products_BuyABundle_TheComfyPup', from, { ...to, delay: 0.4 })
    }
  }, [slugMap])

  const chillPup = {
    name: 'Chill Pup',
    mobileName: 'Chill Pup',
    img: getImage(images.chillPup),
    slug: 'chill-pup-bundle',
    class: 'Products_BuyABundle_TheChillPup',
    description:
      'Support your pup through stressful moments with Calming Aid + daily Multivitamin / Probiotic',
    badge: 'Most Popular'
  }

  const products = [
    {
      name: 'Pretty Pup',
      mobileName: 'Pretty Pup',
      img: getImage(images.prettyPup),
      slug: 'pretty-pup-bundle',
      class: 'Products_BuyABundle_ThePrettyPup',
      description:
        'Treat your pooch to a luxurious coat with daily Skin & Coat + Multivitamin / Probiotic'
    },
    {
      name: 'Active Pup',
      mobileName: 'Active Pup',
      img: getImage(images.activePup),
      slug: 'active-pup-bundle',
      class: 'Products_BuyABundle_TheActivePup',
      description:
        'Nurture joint mobility and tissue repair with daily Hip & Joint + Multivitamin / Probiotic'
    },
    {
      name: 'Comfy Pup',
      mobileName: 'Comfy Pup',
      img: getImage(images.comfyPup),
      slug: 'comfy-pup-bundle',
      class: 'Products_BuyABundle_TheComfyPup',
      description:
        'Improve your dog’s digestion, immunity, and daily stress levels with Digestive Probiotics + Calming Aid.'
    }
  ]

  let displayedProducts = products

  if (!isAlternate) displayedProducts = [chillPup, ...products]

  const { fetching } = productMap

  useEffect(() => {
    if (!isProductLibrary && !fetching && productMap) {
      const productSlugs = products.map((product) => product.slug)
      trackCollectionViewEvent(productMap, productSlugs)
    }
  }, [fetching, isProductLibrary])

  const handleLinkClick = (slug: string) => {
    if (!fetching) {
      trackProductClickEvent(productMap, slug)
    }
    navigate(`/products/${slug}`)
  }

  return (
    <Section>
      {Boolean(title) && <Title>{title}</Title>}
      <Grid cols={displayedProducts.length}>
        {slugMap &&
          displayedProducts.map((product) => {
            const productDetail = slugMap[product.slug]
            const OTPPrice = productDetail?.single?.pricing?.oneTime?.price || 0
            const variantId = productDetail?.single?.variantId
            const showCTA = variantId && isProductLibrary

            return (
              <ProductGroup noBorder={noBorder} className={product.class} key={product.name}>
                <PointerWrapper onClick={() => handleLinkClick(product.slug)}>
                  <ImgWrapper>
                    {Boolean(showBadge) && Boolean(product.badge) && (
                      <CircularBadge>{product.badge}</CircularBadge>
                    )}
                    {Boolean(product.outOfStock) && (
                      <OutOfStockBadge>
                        Sold <br />
                        Out
                      </OutOfStockBadge>
                    )}
                    <GatsbyImage
                      image={product.img}
                      alt={`${product.name} product bundle`}
                      style={{ height: '100%', width: '100%' }}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </ImgWrapper>
                </PointerWrapper>
                <PointerWrapper onClick={() => handleLinkClick(product.slug)}>
                  <ProductName as="p">{product.name}</ProductName>
                </PointerWrapper>

                {!!OTPPrice && (
                  <PriceGrid>
                    <PriceText>${OTPPrice.toFixed(2)}</PriceText>
                    <StruckText>$56.00</StruckText>
                  </PriceGrid>
                )}

                <Description>{product.description}</Description>

                {showCTA && (
                  <>
                    {product.outOfStock ? (
                      <PrimaryButton copy="Out of Stock" bg={colors.mediumGrey} />
                    ) : (
                      <PrimaryButton copy="Shop Now" arrow onClick={() => navigate(product.slug)} />
                    )}
                  </>
                )}
              </ProductGroup>
            )
          })}
      </Grid>
    </Section>
  )
}

export default BuyABundle
