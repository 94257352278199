/* eslint-disable no-template-curly-in-string */
import { colors } from '../../styles/variables'
import { breeds } from './breeds'
import { healthDisorders } from './health-disorders'

import SphinxRightOrange from '../../assets/img/dog-icons/sphinx-right-orange.svg'
import BulldogRightPink from '../../assets/img/dog-icons/bulldog-right-pink.svg'
import BulldogRightNavy from '../../assets/img/dog-icons/bulldog-right-navy.svg'
import HuskyRightPink from '../../assets/img/dog-icons/husky-right-pink.svg'
import HuskyRightNavy from '../../assets/img/dog-icons/husky-right-navy.svg'
import LabRightNavy from '../../assets/img/dog-icons/lab-right-navy.svg'
import PuppyRightPink from '../../assets/img/dog-icons/puppy-right-pink.svg'
import PuppyRightNavy from '../../assets/img/dog-icons/puppy-right-navy.svg'
import JumperLeftTeal from '../../assets/img/dog-icons/jumper-left-teal.svg'
import SnifferLeftTeal from '../../assets/img/dog-icons/sniffer-left-teal.svg'

const hisOrHer = (gender: string) => (gender === 'male' ? 'his' : 'her')
const himOrHer = (gender: string) => (gender === 'male' ? 'him' : 'her')
const ageCopy = (monthsOld: number, gender: string) => {
  if (monthsOld <= 12) return 'just a puppy'
  if (monthsOld <= 24) return 'a teenager'
  if (monthsOld <= 96) return `in ${hisOrHer(gender)} prime`
  return 'a wise, ol doggo'
}

interface Breed {
  value: string
  text: string
  fun_fact: string
  health_disorder_1: string
  health_disorder_2: string
}

export const screens = [
  {
    name: 'welcomeScreen',
    type: 'transition',
    mainCopy: 'Give your sidekick something good.',
    subCopy: 'Superior pet health kicks off with our two-minute quiz.',
    nextCopy: 'Get Started',
    background: colors.orange,
    dogIcon: SphinxRightOrange
  },
  {
    name: 'nameAndEmail',
    mainCopy: 'Let’s get to know you! What’s your name and email?',
    type: 'textInputFields',
    section: 'profile',
    background: colors.orange,
    fields: [
      {
        id: 'first_name',
        placeholder: 'Your First Name',
        type: 'text',
        required: true
      },
      {
        id: 'last_name',
        placeholder: 'Your Last Name',
        type: 'text',
        required: true
      },
      {
        id: 'email',
        placeholder: 'Your Email',
        type: 'email',
        required: true
      }
    ]
  },
  {
    name: 'petName',
    mainCopy: `Great to meet ya! What’s your dog’s name?`,
    type: 'textInputFields',
    section: 'profile',
    background: colors.orange,
    fields: [
      {
        id: 'pet_name',
        placeholder: 'Fido, Snoopy, Wishbone, etc.',
        type: 'text',
        required: true
      }
    ]
  },
  {
    name: 'gender',
    dynamicCopy: `Is \${pet_name} a good boy or a good girl?`,
    type: 'radioButton',
    section: 'profile',
    background: colors.orange,
    options: [
      {
        text: 'Good Boy',
        value: 'male'
      },
      {
        text: 'Good Girl',
        value: 'female'
      }
    ]
  },
  {
    name: 'weight',
    dynamicCopy: `How much does \${pet_name} weigh?`,
    subCopy: 'This doesn’t have to be exact, but should be as close as possible.',
    type: 'numberInput',
    section: 'profile',
    inputUnits: 'lbs',
    max: 350,
    background: ({ gender }: { gender: string }) => (gender === 'female' ? colors.pink : colors.navy),
    textColor: ({ gender }: { gender: string }) => (gender === 'female' ? colors.navy : colors.lightText),
    dogIcon: ({ gender }: { gender: string }) => (gender === 'female' ? BulldogRightPink : BulldogRightNavy)
  },
  {
    name: 'body_size',
    mainCopy: ({ gender }: { gender: string }) => `How would you describe ${hisOrHer(gender)} body size?`,
    subCopy: 'This doesn’t have to be exact, but should be as close as possible',
    type: 'radioButton',
    section: 'profile',
    background: ({ gender }: { gender: string }) => (gender === 'female' ? colors.pink : colors.navy),
    textColor: ({ gender }: { gender: string }) => (gender === 'female' ? colors.navy : colors.lightText),
    dogIcon: ({ gender }: { gender: string }) => (gender === 'female' ? HuskyRightPink : HuskyRightNavy),
    max: 350,
    options: [
      {
        text: 'Very Thin',
        value: 'very_thin',
        additionalInfo: 'Rib cage is clearly visible. No discernible belly fat.'
      },
      {
        text: 'Just Right',
        value: 'just_right',
        additionalInfo: 'Outline of rib cage can be seen and felt. Belly is tucked in.'
      },
      {
        text: 'Pudgy',
        value: 'pudgy',
        additionalInfo: 'Waistline is starting to disappear, ribs are difficult to feel.'
      },
      {
        text: 'Heavy',
        value: 'heavy',
        additionalInfo: `Waistline is not visible. No abdominal tuck. Can’t feel ribs.`
      }
    ]
  },
  {
    name: 'age',
    dynamicCopy: `When was \${pet_name} born?`,
    type: 'ageDropdown',
    section: 'profile',
    background: ({ gender }: { gender: string }) => (gender === 'female' ? colors.pink : colors.navy),
    textColor: ({ gender }: { gender: string }) => (gender === 'female' ? colors.navy : colors.lightText),
    dogIcon: ({ gender }: { gender: string }) => (gender === 'female' ? PuppyRightPink : PuppyRightNavy)
  },
  {
    name: 'transitionProfileToBreed',
    type: 'transition',
    mainCopy: ({ pet_name, age, gender }: { pet_name: string; age: number; gender: string }) =>
      `So ${pet_name} is ${ageCopy(age, gender)}, eh? Well we’re liking the sound of ${himOrHer(gender)} already!`,
    transitionDuration: 4000,
    section: 'profile',
    background: colors.lightText,
    hideBackButton: true,
    dogIcon: LabRightNavy,
    spinningDogBone: true
  },
  {
    name: 'breedPartOne',
    dynamicCopy: `Can you tell us what breed \${pet_name} is?`,
    subCopy: `This doesn’t have to be exact, but should be as close as possible.`,
    type: 'breedDropdown',
    section: 'breed',
    background: colors.teal,
    dogIcon: JumperLeftTeal,
    options: [
      {
        value: 'mixed-breed',
        text: 'Mixed Breed'
      },
      {
        value: 'unknown',
        text: 'I Don’t Know'
      },
      {
        value: 'not-listed',
        text: 'Not Listed'
      },
      ...breeds
    ]
  },
  {
    name: 'breedPartTwo',
    dynamicCopy: `Do you know what breed \${pet_name} is most closely related to?`,
    type: 'breedDropdown',
    section: 'breed',
    background: colors.teal,
    dogIcon: SnifferLeftTeal,
    skip: ({ breed }: { breed: string }) => breed !== 'unknown' && breed !== 'mixed-breed' && breed !== 'not-listed',
    options: [
      {
        value: 'unknown',
        text: 'I Don’t Know'
      },
      ...breeds
    ]
  },
  {
    name: 'transitionBreedFunFact',
    type: 'transition',
    dynamicCopy: ({ breed }: { breed: string }) => {
      const breedInfo = breeds.find(b => breed === b.value)
      if (breedInfo) return breedInfo.fun_fact
      return `No problem! Every pup is unique, but we still love them all the same.`
    },
    transitionDuration: ({ breed }: { breed: string }) => (breeds.find(b => breed === b.value) ? 6000 : 3000),
    section: 'breed',
    background: colors.lightText,
    hideBackButton: true,
    spinningDogBone: true
  },
  {
    name: 'transitionBreedToHealthQuestions',
    type: 'transition',
    dynamicCopy: `Now that we know each other a little better, let’s see how else we can support \${pet_name}’s wellness.`,
    transitionDuration: 4000,
    section: 'breed',
    background: colors.lightText,
    hideBackButton: true
  },
  {
    name: 'health_disorder_1_response',
    dynamicCopy: ({ breed, health_disorder_1 }: { breed: string; health_disorder_1: string }) => {
      const { description, symptoms } = healthDisorders[health_disorder_1]
      const foundBreed = breeds.find((b: Breed) => breed === b.value)
      return !foundBreed
        ? ''
        : `${foundBreed.text}s are generally susceptible to \${name}, ${description}. Has \${pet_name} displayed any symptoms, like ${symptoms}?`
    },
    dynamicCopyData: ({ health_disorder_1 }: { health_disorder_1: string }) => {
      const { name, additionalInfo } = healthDisorders[health_disorder_1]
      return {
        name: name.toLowerCase(),
        additionalInfo
      }
    },
    type: 'radioButton',
    section: 'breed',
    background: colors.teal,
    options: [
      {
        text: 'I’m Not Sure',
        value: 'not-sure'
      },
      {
        text: 'Not Yet',
        value: 'not-yet'
      },
      {
        text: 'Just Started',
        value: 'just-started'
      },
      {
        text: 'Yes, Unfortunately',
        value: 'yes'
      }
    ],
    skip: ({ breed }: { breed: string }) => breed === 'unknown' || breed === 'mixed-breed'
  },
  {
    name: 'health_disorder_2_response',
    dynamicCopy: ({ breed, health_disorder_2 }: { breed: string; health_disorder_2: string }) => {
      const { description, symptoms } = healthDisorders[health_disorder_2]
      const foundBreed = breeds.find((b: Breed) => breed === b.value)
      return !foundBreed
        ? ''
        : `Got it. Some ${foundBreed.text}s might also develop \${name}, ${description}. Has \${pet_name} displayed symptoms, like ${symptoms}?`
    },
    dynamicCopyData: ({ health_disorder_2 }: { health_disorder_2: string }) => {
      const { name, additionalInfo } = healthDisorders[health_disorder_2]
      return {
        name: name.toLowerCase(),
        additionalInfo
      }
    },
    type: 'radioButton',
    section: 'breed',
    background: colors.teal,
    options: [
      {
        text: 'I’m not sure',
        value: 'not-sure'
      },
      {
        text: 'Not Yet',
        value: 'not-yet'
      },
      {
        text: 'Just Started',
        value: 'just-started'
      },
      {
        text: 'Yes, unfortunately',
        value: 'yes'
      }
    ],
    skip: ({ breed }: { breed: string }) => breed === 'unknown' || breed === 'mixed-breed'
  },
  {
    name: 'food_allergies',
    dynamicCopy: `Does \${pet_name} have any food allergies? Select all that apply.`,
    type: 'checkBox',
    section: 'lifestyle',
    background: colors.orange,
    options: [
      {
        text: 'Beef',
        value: 'beef'
      },
      {
        text: 'Cheese',
        value: 'cheese'
      },
      {
        text: 'Bacon',
        value: 'bacon'
      },
      {
        text: 'Dairy',
        value: 'dairy'
      },
      {
        text: 'Soy',
        value: 'soy'
      },
      {
        text: 'Grain',
        value: 'grain'
      },
      {
        text: 'Corn',
        value: 'corn'
      },
      {
        text: 'Chicken',
        value: 'chicken'
      },
      {
        text: 'Egg',
        value: 'egg'
      },
      {
        text: 'Fish',
        value: 'fish'
      },
      {
        text: 'Other',
        value: 'other'
      },
      {
        text: 'None',
        value: 'none'
      }
    ]
  },
  {
    name: 'environmental_allergies',
    dynamicCopy: `Is \${pet_name} sensitive to any environmental allergens? Select all that apply.`,
    type: 'checkBox',
    section: 'lifestyle',
    background: colors.orange,
    options: [
      {
        text: 'Pollen',
        value: 'pollen'
      },
      {
        text: 'Mold',
        value: 'mold'
      },
      {
        text: 'Dust',
        value: 'dust'
      },
      {
        text: 'Fleas',
        value: 'fleas'
      },
      {
        text: 'Other',
        value: 'other'
      },
      {
        text: 'None',
        value: 'none'
      }
    ]
  },
  {
    name: 'food_diet',
    dynamicCopy: `What type of food diet does \${pet_name} have? Select all that apply.`,
    type: 'checkBox',
    section: 'lifestyle',
    background: colors.orange,
    options: [
      {
        text: 'Home-cooked',
        value: 'home-cooked'
      },
      {
        text: 'Dry-Kibble',
        value: 'dry-kibble'
      },
      {
        text: 'Wet Food',
        value: 'wet-food'
      },
      {
        text: 'Fresh Food',
        value: 'fresh-food'
      },
      {
        text: 'Raw Diet',
        value: 'raw-diet'
      },
      {
        text: 'Grain-Free',
        value: 'grain-free'
      },
      {
        text: 'Other',
        value: 'other'
      }
    ]
  },
  {
    name: 'noise_reaction',
    dynamicCopy: `How does \${pet_name} react to unexpected or loud noises, like fireworks, thunderstorms, or that dreaded vacuum cleaner?`,
    type: 'radioButton',
    section: 'lifestyle',
    background: colors.orange,
    options: [
      {
        text: 'No problem',
        value: 'no-problem'
      },
      {
        text: 'Somewhat of a problem',
        value: 'somewhat-problem'
      },
      {
        text: 'Fairly large problem',
        value: 'fairly-large-problem'
      },
      {
        text: 'Big problem',
        value: 'big-problem'
      }
    ]
  },
  {
    name: 'hours_alone',
    dynamicCopy: `For how many hours a day is \${pet_name} normally left alone?`,
    type: 'radioButton',
    section: 'lifestyle',
    background: colors.orange,
    options: [
      {
        text: '0-2 hours',
        value: '0-2'
      },
      {
        text: '3-4 hours',
        value: '3-4'
      },
      {
        text: '5-7 hours',
        value: '5-7'
      },
      {
        text: '8+ hours',
        value: '8plus'
      }
    ]
  },
  {
    name: 'support_areas',
    dynamicCopy: `Are there any other areas where you think \${pet_name} could use a little extra support?`,
    type: 'checkBox',
    section: 'lifestyle',
    background: colors.orange,
    options: [
      {
        text: 'Stress & Anxiety',
        value: 'stress-anxiety'
      },
      {
        text: 'Mobility',
        value: 'mobility'
      },
      {
        text: 'Skin & Coat Health',
        value: 'skin-coat'
      },
      {
        text: 'Heart Health',
        value: 'heart'
      },
      {
        text: 'Digestion',
        value: 'digestion'
      },
      {
        text: 'Hyperactivity',
        value: 'hyperactivity'
      },
      {
        text: 'Eye Health',
        value: 'eye'
      },
      {
        text: 'Allergies',
        value: 'allergies'
      },
      {
        text: 'Hip & Joint Support',
        value: 'hip-joint'
      },
      {
        text: 'Brain Health',
        value: 'brain'
      },
      {
        text: 'None',
        value: 'none'
      }
    ]
  },
  {
    name: 'finalTransition',
    type: 'finalTransition',
    dynamicCopy: `That’s it! Please wait a moment while we analyze how we can best support \${pet_name}’s health.`,
    section: 'result',
    background: colors.white,
    hideBackButton: true
  },
  {
    name: 'consultationResults',
    type: 'consultationResults',
    mainCopy: 'All done! Take a look:',
    background: colors.white,
    hideBackButton: true
  }
]
