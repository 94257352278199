import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import qs from 'query-string'

import { H6, LargeP, Error } from '../shared/text'
import { SmallSecondaryButton } from '../shared/buttons'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

const Container = styled.div`
  width: 100%;

  ${media.mobile`
    margin: 20px 0 10px 0;
    padding: 0 4px;
  `}
  ${media.tablet`
    margin: 0 0 10px 0;
  `}
  ${media.desktop`
    margin: 0;
    padding: 0;
    max-width: 425px;
  `}
`

const Header = styled(H6)`
  ${media.desktop`
    margin: 0 auto 20px auto;
  `}
`

const Form = styled.form`
  display: grid;
  margin: 15px auto 0 auto;
  ${media.mobile`
    grid-template-columns: 1fr;
  `}
  ${media.desktop`
    grid-template-columns: 1fr auto;
    height: 32px;
    grid-gap: 16px;
  `}
`

const Input = styled.input`
  height: 30px;
  background-color: transparent;
  color: ${colors.lightText};
  border-style: solid;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #fff;
  padding: 8px 12px 6px 12px;
  outline: none;
  &::placeholder {
    color: ${colors.slate};
  }

  &:focus {
    background-color: transparent;
    border-bottom: 1px solid ${colors.orange};
  }

  ${media.mobile`
    padding: 9px 0;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 16px;
  `}
  ${media.desktop`
    width: 100%;
    padding: 15px 0;
    font-size: 18px;
    line-height: 22px;
    height: 45px;
    margin-bottom: 0;
  `}
`

const SuccessText = styled(LargeP)`
  color: ${colors.orange};
`

const EmailCapture = () => {
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')

  const { register, handleSubmit } = useForm()

  const submitEmail = async (data: any) => {
    const klaviyoData = {
      g: 'HwQJWD',
      $fields: '$source',
      ...data,
      $source: 'OnSiteFooter'
    }
    const stringifiedData = qs.stringify(klaviyoData)
    const response = await axios({
      method: 'post',
      url: '//manage.kmail-lists.com/ajax/subscriptions/subscribe',
      data: stringifiedData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    })
    if (response.data.success) setSuccess("Thanks, you're signed up!")
    else setError('Uh oh, something went wrong. Please try again.')
  }

  return (
    <Container>
      <Header>Join the pack</Header>
      <Form onSubmit={handleSubmit(submitEmail)}>
        {success ? (
          <SuccessText>Thanks, welcome to the pack!</SuccessText>
        ) : (
          <>
            <input type="hidden" name="g" value="HwQJWD" />
            <Input
              type="email"
              placeholder="Enter your email"
              {...register('email', { required: true })}
            />
            <SmallSecondaryButton copy="Submit" type="submit" arrow color="#fff" />
            <Error>{error}</Error>
          </>
        )}
      </Form>
    </Container>
  )
}

export default React.memo(EmailCapture)
