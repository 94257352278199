import React from 'react'
import styled from 'styled-components'
import useReviews from 'src/hooks/useReviews'

import AboveTheFold from './AboveTheFold'
import Badges from './Badges'
import ProductDetail from './ProductDetail'
import Dosage from '../shared/Dosage'
import VetEndorsement from 'src/components/shared/VetEndorsement'
import HowWereDifferent from './HowWereDifferent'
import Reviews from './Reviews'
import FAQ from './FAQ'
import { Singles, Bundles } from '../OurProducts'
import { colors } from 'src/styles/variables'
import { IPdpDataInterface } from 'src/types/pdp-template'

const Wrapper = styled.div`
  width: 100%;
`

const PDP = ({ data }: { data: IPdpDataInterface }) => {
  const reviewsHook = useReviews()

  const { bg, color } = data.colors

  return (
    <Wrapper>
      <AboveTheFold reviewStats={reviewsHook.reviewStats} data={data} />
      <Badges />
      <ProductDetail data={data} />
      <Dosage
        bg={colors[bg]}
        color={colors[color]}
        oneLiner="Our suggested daily intake for pups, from Yorkies to Great Danes and all the fuzzballs in between."
      />
      {!data.isBundle && <Bundles title="Save on Bundles" buttonCopy="Shop Now" />}
      <VetEndorsement productReview={data.vetQuote} />
      <HowWereDifferent slug={data.slug} />
      <Reviews reviewsHook={reviewsHook} />
      <FAQ questionList={data.faqs} />
      <Singles title="More Products" buttonCopy="Shop Now" isPdp />
    </Wrapper>
  )
}

export default PDP
