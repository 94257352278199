import React from 'react'
import styled from 'styled-components'

import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { colors } from 'src/styles/variables'

const SpinnerContainer = styled('div')<{ overlay: any }>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  background: ${props => props.overlay || 'none'};
  opacity: 0.8;
  z-index: 10;
  * {
    color: ${props => props.color || colors.navy};
  }
`

const FullPageContainer = styled.div`
  height: 100vh;
  background-color: white;
`

const Icon = styled(LoadingOutlined)`
  font-size: ${props => props.size}px;
`

interface SpinnerProps {
  color?: string
  size?: number
  overlay?: string | boolean
}

const Spinner: React.FC<SpinnerProps> = ({ size = 32, color, overlay }) => (
  <SpinnerContainer overlay={overlay} color={color}>
    <Spin indicator={<Icon spin size={size} />} />
  </SpinnerContainer>
)

export const FullPageSpinner: React.FC<SpinnerProps> = () => (
  <FullPageContainer>
    <Spinner color={colors.navy} />
  </FullPageContainer>
)

export default Spinner
