import React from 'react'
import styled from 'styled-components'

import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  z-index: 100001;

  color: ${colors.lightText};

  display: grid;
  grid-template-columns: 1fr;

  ${media.mobile`
    width: 100%;
    grid-template-rows: 40px 1fr;
    padding: 40px 20px 40px 20px;
  `}
  ${media.tablet`
    grid-template-rows: 45px 1fr auto;
    padding: 40px;
    grid-row-gap: 40px;
  `}
  ${media.desktop`
    padding: 64px 40px 40px 40px;
    grid-row-gap: 20px;
  `}
`

const MenuWrapper = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

export default React.memo(MenuWrapper)
