import { useEffect, useState } from 'react'
import axios from 'axios'

// When new products/SKUs are added, update this hash table so that reviews can be pulled in
const reviewProducts = {
  4418322301010: 'Hip & Joint',
  4501744123986: 'Skin & Coat',
  4501743239250: 'Multivitamin',
  4501744386130: 'Calming Aid',
  4879590228050: 'Active Pup Bundle',
  4715572592722: 'Senior Pup Bundle',
  4715589206098: 'Super Pup Bundle',
  4878540800082: 'Pretty Pup Bundle',
  4754716328018: 'Chill Pup Bundle'
}

interface JunipCustomer {
  id: number
  created_at: string
  first_name: string
  last_name: string
  updated_at: string
}

interface JunipProductReview {
  id: number
  body: string
  created_at: string
  updated_at: string
  customer: JunipCustomer
  customer_id: number
  down_vote_count: number
  featured: boolean
  photo_urls: { large: string; thumbnail: string }[]
  product_id: number
  rating: number
  response: string
  title: string
}

interface PagePointer {
  before?: string
  after?: string
}

interface ReviewStats {
  totalReviewCount: number
  averageRating: number
}

const junipFetch = axios.create({
  baseURL: 'https://api.juniphq.com/',
  headers: {
    'Junip-Store-Key': 'FrKnPokoncXn6y8NzwyNuGpC'
  }
})

const updateObjectInArray = (oldArr: JunipProductReview[], id: number, newObj: JunipProductReview): JunipProductReview[] =>
  oldArr.map(item => {
    if (item.id !== id) return item
    return newObj
  })

const PAGE_SIZE = 5 // number of reviews to be displayed at a time

export default () => {
  const [reviews, setReviews] = useState([])
  const [loading, setLoading] = useState(true)
  const [reviewStats, setReviewStats] = useState<ReviewStats>({ totalReviewCount: 0, averageRating: 0 })
  const [pagePointers, setPagePointers] = useState<PagePointer>({})
  const [reviewFilters, setReviewFilters] = useState({})

  const PRODUCT_QUERY = `/v1/products?filter%5Bremote_ids%5D=${Object.keys(reviewProducts).join(',')}`
  const filterProductArray = Object.keys(reviewFilters).join(',')
  const REVIEWS_QUERY = `/v1/product_reviews?include=customer&page%5Bsize%5D=${PAGE_SIZE}${
    filterProductArray ? `&filter%5Bproduct_remote_ids%5D=${filterProductArray}` : ''
  }`

  useEffect(() => {
    const initalizeReviews = async (): Promise<void> => {
      const { data: reviewData } = await junipFetch.get(REVIEWS_QUERY) // review content
      const { data: productData } = await junipFetch.get(PRODUCT_QUERY) // total number of reviews + stars

      const totalReviewCount = productData.products.reduce((sum, product) => sum + product.rating_count, 0)
      const totalStars = productData.products.reduce((sum, product) => sum + product.rating_count * (product.rating_average || 0), 0)
      setReviewStats({
        totalReviewCount,
        averageRating: totalStars / totalReviewCount
      })
      setReviews(reviewData.product_reviews)
      if (reviewData?.meta?.page) setPagePointers(reviewData.meta.page)
      setLoading(false)
    }
    initalizeReviews()
  }, [])

  useEffect(() => {
    const filterReviews = async (): Promise<void> => {
      const { data: reviewData } = await junipFetch.get(REVIEWS_QUERY) // review content
      setReviews(reviewData.product_reviews)
      setPagePointers(reviewData.meta.page)
    }
    if (!loading) filterReviews()
  }, [reviewFilters])

  async function fetchReviews(direction: string): Promise<void> {
    const cursor = pagePointers[direction]
    const { data: reviewData } = await junipFetch.get(`${REVIEWS_QUERY}&page%5B${direction}%5D=${cursor}`)
    setPagePointers(reviewData.meta.page)
    setReviews(reviewData.product_reviews)
  }

  async function vote(id: number, direction: string): Promise<void> {
    const {
      data: { product_review }
    } = await junipFetch.put(`/v1/product_reviews/${id}/${direction}?include=customer`)
    const updatedReviews = updateObjectInArray(reviews, id, product_review)
    setReviews(updatedReviews)
  }

  const toggleFilter = remoteProductId => {
    const updatedFilters = { ...reviewFilters }
    if (updatedFilters[remoteProductId]) {
      delete updatedFilters[remoteProductId]
    } else {
      updatedFilters[remoteProductId] = reviewProducts[remoteProductId]
    }
    setReviewFilters(updatedFilters)
  }
  const filterProduct = ({ key }) => {
    toggleFilter(key)
  }

  return { reviews, reviewStats, pagePointers, reviewFilters, filterProduct, vote, fetchReviews, reviewProducts, loading }
}
