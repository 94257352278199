import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const LOCALSTORAGE_KEY = 'persisted-atoms'

const { persistAtom } = recoilPersist({
  key: LOCALSTORAGE_KEY
})

export { screens } from './screens'
export { healthDisorders } from './health-disorders'
export { breeds } from './breeds'

export const consultationAtom = atom({
  key: 'consultation',
  default: {
    currentStep: 0,
    answers: {
      first_name: null,
      last_name: null,
      email: null
    }
  },
  effects_UNSTABLE: [persistAtom]
})
