import { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'

import getProductAttributes from 'src/utils/getProductAttributes'
import getCheckoutUrl from 'src/utils/getCheckoutUrl'
import { productDetailState } from 'src/actions/products'
import { storeState, removePromoCode } from 'src/actions/checkout'
import useQuantity from './useQuantity'

const useCart = () => {
  const [store, setStore] = useRecoilState(storeState)
  const [products] = useRecoilState(productDetailState)

  const [checkoutUrl, setCheckoutUrl] = useState('')
  const [subscriptionSavings, setSubscriptionSavings] = useState(0)
  const [discounts, setDiscounts] = useState([])
  const [discountSavings, setDiscountSavings] = useState(0)

  const { fetching, updating, checkout } = store
  const checkoutId = checkout?.id || ''
  const updatedAt = checkout?.updatedAt || ''
  const items = checkout?.lineItems || []
  const discountCodes = checkout?.discountApplications || []

  const grossTotal = Number(checkout?.lineItemsSubtotalPrice?.amount) || 0
  const netTotal = Number(checkout?.subtotalPrice) || 0
  const actualTotal = grossTotal - (subscriptionSavings || discountSavings)

  const freeShipping = netTotal >= 30 || !!subscriptionSavings

  const loading = products.fetching || fetching

  const [, quantity] = useQuantity(items)

  // Tracks checkoutUrls
  useEffect(() => {
    if (!checkoutId) return
    const url = getCheckoutUrl(checkout)
    setCheckoutUrl(url)
  }, [checkoutId, updatedAt])

  // Removes certain promo codes each time a user visits site
  useEffect(() => {
    if (!checkoutId) return
    const removablePromoCodes = ['CALMTV', 'TVBUNDLE', 'SAVE50'] // Remove these if on main site
    const currentCode = discounts.length > 0 ? discounts[0].code : ''
    if (removablePromoCodes.includes(currentCode)) {
      removePromoCode(checkout, setStore)
    }
  }, [checkoutId, updatedAt])

  // Calculates savings from subscriptions
  useEffect(() => {
    if (!checkoutId || loading) return
    setSubscriptionSavings(0)
    items.forEach((lineItem: any) => {
      const { selling_plan: sellingPlan } = getProductAttributes(lineItem?.customAttributes)
      const isSubscription = Boolean(sellingPlan)
      if (!isSubscription) return

      const fullVariantId = lineItem.variant.id
      const variantIdBreakdown = fullVariantId.split('/')
      const variantId = variantIdBreakdown[variantIdBreakdown.length - 1]
      const variantDetail = products.byVariantId[variantId]
      const discount = variantDetail.pricing.subscription.priceDiscount
      const itemSavings = discount * lineItem.quantity
      setSubscriptionSavings((prevSavings) => prevSavings + itemSavings)
    })
  }, [loading, checkoutId, updatedAt])

  // Calculates savings from promo codes
  useEffect(() => {
    if (!checkoutId) return
    setDiscountSavings(0)
    const formattedDiscounts = discountCodes.map((discount: any) => {
      const { code, value } = discount
      const percentage = value.percentage / 100
      const amount = percentage * grossTotal
      setDiscountSavings((prevSavings) => prevSavings + amount)
      return { code, amount: amount.toFixed(2) }
    })
    setDiscounts(formattedDiscounts)
  }, [loading, checkoutId, updatedAt])

  return {
    loading,
    updating,
    checkoutId,
    checkoutUrl,
    empty: quantity === 0,
    items,
    quantity,
    grossTotal,
    netTotal,
    actualTotal,
    discounts,
    subscriptionSavings,
    discountSavings,
    freeShipping,
    checkout,
    store,
    setStore,
    products
  }
}

export default useCart
