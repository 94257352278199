import React from 'react'
import styled from 'styled-components'
import { Carousel as AntdCarousel } from 'antd'
import 'antd/dist/antd.css'

import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

const Wrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
  align-items: center;

  ${media.mobile`
    & .slick-list {
      width: 100%;
      padding: 0 2vw !important;
    }

    & .slick-slide {
      height: fit-content !important;

      & > div {
        height: 95% !important;
      }
    }
  `}
  ${media.tablet`
    & .slick-list {
      padding: 0 20px !important;
    }
    & .slick-slide {
      height: fit-content !important;
    }
  `}
  ${media.desktop`
    & .slick-list {
      padding: 0 50px !important;
    }

    & .slick-slide {
      height: 500px !important;
    }
  `}

  & ul {
    height: fit-content;
    display: flex;
    justify-content: center;
    margin: 0 auto -6px auto !important;

    & > li {
      min-height: 12px;
      min-width: 12px;
      max-height: 12px;
      max-width: 12px;
      margin: 0 3px;
      border-radius: 50%;
      cursor: pointer;
      border: 1px solid ${colors.navy};

      & button {
        min-height: 12px;
        min-width: 12px;
        max-height: 12px;
        max-width: 12px;
        border-radius: 50% !important;
        border: 1px solid ${colors.navy};
      }
    }
  }

  & li.slick-active button {
    background-color: ${colors.navy} !important;
  }
`

interface CarouselProps {
  speed?: number
}

const Carousel: React.FC<CarouselProps> = React.forwardRef((props, ref) => {
  const { children, speed = 3000 } = props
  return (
    <Wrapper>
      <AntdCarousel
        autoplay
        centerMode
        swipe
        autoplaySpeed={speed}
        effect="fade"
        lazyLoad="ondemand"
        style={{ height: '100%', width: '100%' }}
        ref={el => (ref.current = el)}
      >
        {children}
      </AntdCarousel>
    </Wrapper>
  )
})

export default Carousel
