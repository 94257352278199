import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gsap, Power3 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'

import DropHeader from '../shared/DropHeader'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'
import { Headline, LargeP } from '../shared/text'

const Section = styled.section`
  width: 100%;
  height: fit-content;
  background-color: ${colors.navy};

  ${media.mobile`
    padding: 20px 20px 57px 20px;
  `}
  ${media.tablet`
    padding: 0 30px 57px 30px;
  `}
`

const ImageWrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  ${media.mobile`
    height: 275px;
  `}
  ${media.tablet`
    height: 400px;
  `}
  ${media.desktop`
    height: 515px;
    max-width: 1300px;
    margin-top: -40px;
  `}
`

const Textbox = styled.div`
  width: 100%;
  margin: 0 auto;
  color: white;
  text-align: center;
  display: grid;
  place-items: center;

  ${media.mobile`
    padding: 28px 0 36px 0;
    grid-gap: 30px;
  `}
  ${media.tablet`
    max-width: 1050px;
    padding: 71px 0 41px 0;
    grid-gap: 42px;

    & p {
      max-width: 950px;
    }
  `}
`

const TextHeader = styled(Headline)`
  ${media.mobile`
    font-size: 30px;
    line-height: 40px;
  `}
  ${media.tablet`
    font-size: 46px;
    line-height: 56px;
  `}
`

const query = graphql`
  query {
    image: file(relativePath: { regex: "/homepage/be-the-hero.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1100)
      }
    }
    dog: file(relativePath: { regex: "/logos/sitting-dog-teal.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 40)
      }
    }
  }
`

const TheFinnWay = () => {
  const images = useStaticQuery(query)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.fromTo(
      '.textHeader',
      { y: 20, opacity: 0 },
      { scrollTrigger: '.textHeader', duration: 1, ease: Power3.easeOut, y: 0, opacity: 1 }
    )
    gsap.fromTo(
      '.textBody',
      { y: 20, opacity: 0 },
      {
        scrollTrigger: '.textBody',
        delay: 0.25,
        duration: 1,
        ease: Power3.easeOut,
        y: 0,
        opacity: 1
      }
    )
  }, [])

  return (
    <Section>
      <DropHeader title="Be the Hero Your Dog Deserves" color={colors.teal} />
      <ImageWrapper>
        <GatsbyImage
          image={getImage(images.image)}
          alt="Girl and dog"
          style={{ height: '100%', width: '100%' }}
          imgStyle={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
        />
      </ImageWrapper>
      <Textbox>
        <TextHeader className="textHeader">
          The love we get from our dogs is like nothing else on Earth. Sometimes it feels like
          there's no way we could ever do enough to give it back.
        </TextHeader>
        <LargeP className="textBody">
          So Finn developed easy-to-follow wellness routines to give our dogs the happiest and
          healthiest lives possible. The quality of care we would choose to give ourselves, for the
          friends who love us unconditionally.
        </LargeP>
        <GatsbyImage
          image={getImage(images.dog)}
          alt="Dog sitting"
          style={{ width: 40 }}
          imgStyle={{ objectFit: 'contain' }}
        />
      </Textbox>
    </Section>
  )
}

export default TheFinnWay
