import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Switch } from 'antd'
import { navigate } from 'gatsby'

import { MonoP, LargeP, SmallP } from '../../shared/text'
import { colors, fonts } from 'src/styles/variables'
import { media } from 'src/styles/util'
import formatVariant from 'src/utils/formatVariant'

import {
  removeVariantFromCart,
  updateCartLineItem,
  convertToSubscription,
  convertToOtp,
  updateSubscriptionFrequency
} from 'src/actions/checkout'

import getProductAttributes from 'src/utils/getProductAttributes'
import {
  trackAddToCartEvent,
  trackRemoveFromCartEvent,
  trackSwapToSubscriptionEvent,
  trackSwapToOtpEvent
} from 'src/utils/event-tracking'
import useCart from 'src/hooks/useCart'

import { SUBSCRIPTION_FREQUENCIES } from 'src/utils/globalVariables'

const Row = styled.div`
  width: 100%;
  margin-bottom: 24px;

  border: none;
  border-bottom: 1px solid ${colors.lightText};
  padding: 0 0 24px 0;

  &:last-child {
    border-bottom: none;
  }
`

const MainContent = styled.div`
  width: 100%;
  height: 100%;
  display: grid;

  ${media.mobile`
    grid-template-columns: 50px 1fr;
    grid-column-gap: 10px;
  `}
  ${media.tablet`
    grid-template-columns: 80px 1fr;
    grid-column-gap: 20px;
  `}
`

const Header = styled(MonoP)`
  color: ${colors.lightText};
  height: fit-content;
  display: inline-block;
`

const ClickableText = styled(Header)<{ scale?: string }>`
  cursor: pointer;
  transition: 150ms transform ease-in-out;
  height: fit-content;
  line-height: 1;
  &:hover {
    transform: scale(${(props) => props.scale || '1'});
    color: ${colors.orange};
  }
`

const RemoveX = styled.span`
  font-size: 20px;
`

const TextRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 14px 0;
  &:first-of-type {
    align-items: flex-start;
  }

  .ant-switch-checked {
    background-color: ${colors.peach};

    &::after {
      background-color: ${colors.orange};
    }
  }
`

const NameText = styled(LargeP)`
  font-family: ${fonts.larsseit.regular};
  color: ${colors.lightText};
  line-height: 1.3 !important;
  padding-right: 9px;
  display: inline-block;
  width: fit-content;
  white-space: pre-line;
`

const GreySpan = styled(SmallP)`
  width: 100%;
  color: ${colors.slate};
  line-height: 1 !important;
  display: inline-block;
  width: fit-content;
`

const SubscribeText = styled(LargeP)`
  font-family: ${fonts.larsseit.medium};
  font-size: 16px;
  color: ${colors.orange};

  ${media.mobile`
    max-width: 200px;
    font-size: 16px;
  `}
  ${media.tablet`
    max-width: none;
    font-size: 16px;
  `}
  ${media.desktop`
    font-size: 16px;
  `}
`

const Quantity = styled(MonoP)<{ orangeText?: boolean }>`
  color: ${({ orangeText }) => (orangeText ? colors.orange : colors.lightText)};
  font-size: 16px !important;
`

const QuantityCounter = styled.div`
  width: fit-content;
  display: grid;
  place-items: center;
  grid-template-columns: 30px auto 30px;
`

const CartImg = styled.img`
  width: 100%;
  height: 80px;
  margin-right: 10px;
  cursor: pointer;
`

const Toggle = styled(Switch)`
  background-color: ${colors.lightText};
  height: 16px;
  width: 28px;
  margin-bottom: 3px;

  & svg {
    fill: ${colors.orange};
  }
  ::after {
    background-color: ${colors.slate};
  }
`

const SubFrequencyGrid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 6px;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
`

const SubFrequencyButton = styled('div')<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 7px;
  padding: 7px 0 5px 0;
  text-transform: uppercase;
  background-color: ${(props: { active: boolean }) =>
    props.active ? colors.orange : 'transparent'};
  border: 1px solid ${(props: { active: boolean }) => (props.active ? colors.orange : colors.slate)};
  color: ${(props: { active: boolean }) => (props.active ? colors.navy : colors.slate)};
  &:hover {
    color: ${(props: { active: boolean }) => (props.active ? colors.white : colors.orange)};
    border: 1px solid ${colors.orange};
  }
  p {
    ${media.mobile`
     font-size: 11px !important;
    `}
    ${media.tablet`
      font-size: 12px !important;
    `}
  }
`

const FrequencyText = styled(MonoP)`
  color: ${colors.slate};
  padding-bottom: 6px;
`

interface CartRowInterface {
  lineItem: any
  products: any
  close: () => void
}

const CartRow: React.FC<CartRowInterface> = ({ lineItem, products, close }) => {
  const { selling_plan_frequency: subFrequency } = getProductAttributes(lineItem?.customAttributes)
  const { loading, checkoutId, store, setStore } = useCart()
  const [frequency, setFrequency] = useState(subFrequency)

  const lineItemVariant = lineItem?.variant
  const variantMap = products?.byVariantId

  const { slug, sub, price, subDiscountPercent, subDiscount, variantId } = formatVariant(
    lineItem,
    variantMap
  )

  const isCalmingAid = slug === 'calming-aid'

  const removeFromCart = () => {
    if (checkoutId && variantId) {
      removeVariantFromCart(checkoutId, lineItem.id, setStore)
      trackRemoveFromCartEvent(products, slug, variantId, price)
    }
  }

  // When cart opens, makes image scale large then return to normal
  useEffect(() => {
    if (!variantId) removeFromCart()
  }, [variantId])

  if (loading || !checkoutId) return null

  // Updates lineItem quantity
  const updateCart = (adjustment: number) => {
    if (!checkoutId) return
    const newQuantity = lineItem.quantity + adjustment
    updateCartLineItem(checkoutId, lineItem.id, newQuantity, setStore)
  }

  // Updates subscription frequency
  const handleFrequencyClick = (newFrequency: string) => {
    if (frequency) {
      // Only fires if lineItem is already a subscription, otherwise handleToggle with send
      // frequency when the one-time lineItem is converted
      updateSubscriptionFrequency(lineItem.id, newFrequency, store, setStore, sub)
    } else {
      setFrequency(newFrequency)
    }
  }

  const cartImgClickHandler = () => {
    if (slug) {
      navigate(`/products/${slug}`)
      close()
    }
  }

  const handleQuantityUpdate = (adj: number) => {
    updateCart(adj)
    trackAddToCartEvent(products, slug, variantId, price)
  }

  const handleToggleClick = () => {
    if (!frequency) {
      const newFrequency = '30'
      convertToSubscription(lineItem, newFrequency, store, setStore, sub, products)
      trackSwapToSubscriptionEvent(variantId)
    } else {
      convertToOtp(lineItem, store, setStore)
      setFrequency(0)
      trackSwapToOtpEvent(variantId)
    }
  }

  const subscriptionAdjustment = frequency ? subDiscount : 0
  const displayedPrice = price - subscriptionAdjustment

  const toggleLabel = frequency
    ? `${subDiscountPercent} savings applied!`
    : `Subscribe and save ${subDiscountPercent} →`

  return (
    <Row>
      <MainContent>
        <CartImg
          src={lineItemVariant.image.src}
          alt={lineItem.title}
          onClick={cartImgClickHandler}
        />
        <div
          style={{ width: '100%', height: '100%', display: 'grid', alignItems: 'space-between' }}
        >
          <TextRow>
            <div>
              <NameText>{lineItem.title}</NameText>
              <GreySpan>{frequency ? 'Subscription' : 'One Time'}</GreySpan>
            </div>
            <ClickableText scale="1.5" onClick={removeFromCart}>
              <RemoveX>×</RemoveX>
            </ClickableText>
          </TextRow>

          <TextRow>
            <QuantityCounter>
              <ClickableText onClick={() => handleQuantityUpdate(-1)}>-</ClickableText>
              <Quantity>{lineItem.quantity}</Quantity>
              <ClickableText onClick={() => handleQuantityUpdate(1)}>+</ClickableText>
            </QuantityCounter>
            <Quantity>${(lineItem.quantity * displayedPrice).toFixed(2)}</Quantity>
          </TextRow>

          {subDiscountPercent && !isCalmingAid && (
            <TextRow>
              <SubscribeText>{toggleLabel}</SubscribeText>
              <Toggle
                size="small"
                checked={Boolean(frequency)}
                disabled={loading}
                onClick={handleToggleClick}
              />
            </TextRow>
          )}

          {frequency && (
            <>
              <FrequencyText>Delivery Frequency</FrequencyText>
              <SubFrequencyGrid>
                {SUBSCRIPTION_FREQUENCIES.map((days) => (
                  <SubFrequencyButton
                    key={days}
                    onClick={() => handleFrequencyClick(days)}
                    active={frequency === days}
                  >
                    <MonoP>{`${days} Days`}</MonoP>
                  </SubFrequencyButton>
                ))}
              </SubFrequencyGrid>
            </>
          )}
        </div>
      </MainContent>
    </Row>
  )
}

export default CartRow
