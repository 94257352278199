import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'src/layouts'
import PDP from 'src/components/PDP'
import SEO from 'src/components/shared/SEO'

import { IPdpTemplateProps } from 'src/types/pdp-template'

const PdpTemplate: React.FC<IPdpTemplateProps> = ({ data, location }) => {
  const { title, shortDescription, seo } = data?.productsJson

  return (
    <>
      <SEO
        title={seo?.title || title}
        description={seo?.description || shortDescription}
        keywords={seo?.keywords || []}
        location={location}
      />

      <Layout location={location}>
        <PDP data={data.productsJson} />
      </Layout>
    </>
  )
}

export default PdpTemplate

export const query = graphql`
  query ($slug: String!) {
    productsJson(slug: { eq: $slug }) {
      slug
      outOfStock
      title
      isBundle
      colors {
        bg
        color
      }
      seo {
        title
        description
        keywords
      }
      faqs {
        answer
        question
      }
      vetQuote {
        author
        quote
        imageKey
      }
      shortDescription
      oneLiner
      junip {
        productIDs
        remoteProductIDs
      }
      card1 {
        alt
        copy {
          ingredients {
            amount
            name
          }
          body
          header
        }
        src {
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
        fullSizeSrc {
          childImageSharp {
            gatsbyImageData(width: 400)
          }
        }
      }
      card2 {
        alt
        title
        copy {
          ingredients {
            amount
            name
          }
          body
          header
        }
        src {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
        fullSizeSrc {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
      card3 {
        alt
        title
        copy {
          ingredients {
            amount
            name
          }
          body
          header
        }
        src {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
        fullSizeSrc {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
      card4 {
        alt
        title
        copy {
          body
          header
        }
        src {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
        fullSizeSrc {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
      carouselImages {
        alt
        src {
          childImageSharp {
            gatsbyImageData(width: 1400)
          }
        }
      }
    }
  }
`
