// [slug, isSwap]
const upsellMap: { [key: string]: any } = {
  oneTime: {
    'calming-aid': ['chill-pup-bundle', true],
    'hip-and-joint': ['calming-aid', false],
    'skin-and-coat': ['calming-aid', false],
    multivitamin: ['calming-aid', false],
    'digestive-probiotics': ['calming-aid', false],
    'chill-pup-bundle': ['hip-and-joint', false],
    'active-pup-bundle': ['calming-aid', false],
    'pretty-pup-bundle': ['calming-aid', false],
    'comfy-pup-bundle': ['hip-and-joint', false]
  },
  subscription: {
    'calming-aid': [null, false],
    'hip-and-joint': ['active-pup-bundle', true],
    'skin-and-coat': ['pretty-pup-bundle', true],
    multivitamin: ['calming-aid', false],
    'digestive-probiotics': ['calming-aid', false],
    'chill-pup-bundle': ['hip-and-joint', false],
    'active-pup-bundle': ['calming-aid', false],
    'pretty-pup-bundle': ['calming-aid', false],
    'comfy-pup-bundle': ['calming-aid', false]
  },
  multipack: {
    'calming-aid': ['multivitamin', false],
    'hip-and-joint': ['calming-aid', false],
    'skin-and-coat': ['calming-aid', false],
    multivitamin: ['calming-aid', false],
    'digestive-probiotics': ['calming-aid', false]
  }
}

export default (lineItems: any, productMap: any) => {
  // Only display Recommended if cart has a single lineItem
  const hasSingleLineItem = lineItems.length === 1
  const variantIdMap = productMap?.byVariantId

  if (!hasSingleLineItem || !variantIdMap) return null

  const fullVariantId = lineItems[0].variant.id // e.g. "gid://shopify/ProductVariant/39848003141714"
  const variantIdBreakdown = fullVariantId.split('/')
  const variantId = variantIdBreakdown[variantIdBreakdown.length - 1]
  const variantDetails = variantIdMap[variantId]
  if (!variantDetails) return null

  const { slug, isSubscription } = variantDetails

  const productType = isSubscription ? 'subscription' : 'oneTime'

  const upsell = upsellMap[productType][slug]

  return upsell && upsell[0] ? upsell : null
}
