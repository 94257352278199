import { atom } from 'recoil'
import * as Sentry from '@sentry/react'

import decodeId from '../utils/decodeId'
import {
  SellingPlanAllocationInterface,
  RawProductDataInterface,
  ProductMapInterface,
  IProductDataInterface
} from 'src/types/product-actions'
import fetchShopifyProductDetail from './utils/fetchShopifyProductDetail'

export const productDetailState = atom({
  key: 'productDetail',
  default: {
    fetching: true
  }
})

// Used to map "special" product offerings that might only have OTP available (so the
// productKey needs to correspond to the normal product to fetch the normal subscription
const SPECIAL_PRODUCT_KEY_MAP: { [key: string]: string } = {
  'super-pup-+-free-calming-aid-(holiday-special)': 'super-pup-holiday',
  'starter-bundle-+-free-skin-and-coat-(holiday-special)': 'starter-bundle-holiday',
  'free-dogs-of-finn-2022-calendar': '2022-calendar-free',
  'tv-special-(3-tins-for-20%-off-+-free-allergy-and-itch)': 'tv',
  'tv-special-(2x-calming-aid-+-free-multivitamin)': 'calmtv'
}

// Converts product "title" in Shopify into slug format
const buildProductSlug = (productTitle: string) => {
  let slug = productTitle.toLowerCase().replace('&', 'and').split(' ').join('-')

  const manuallySetLookupKey = SPECIAL_PRODUCT_KEY_MAP[slug] || null
  if (manuallySetLookupKey) slug = manuallySetLookupKey

  return slug
}

// Parses slug to determine if product is a single pack or 2-pack option
const determineSlugAndPackType = (currentSlug: string) => {
  let slug = currentSlug
  let packType = 'single'
  let packQuantity = '1'

  const multipackRegex = /(.+)-(\d)-pack$/
  const multipack = slug.match(multipackRegex)

  if (multipack) {
    ;[, slug, packQuantity] = multipack
    packType = `multipack_${packQuantity}`
  }

  return [slug, packType]
}

const buildSellingPlansArray = (sellingPlanAllocations: Array<SellingPlanAllocationInterface>) => {
  const formattedSellingPlans = sellingPlanAllocations.map((sellingPlanAllocation) => {
    const { id, name } = sellingPlanAllocation.node.sellingPlan
    const frequency = name.split(' ')[2]
    return { id: decodeId(id), frequency }
  })
  return formattedSellingPlans.sort((a, b) => a.frequency - b.frequency)
}

const formatProduct = (rawProductData: RawProductDataInterface) => {
  const rawSlug = buildProductSlug(rawProductData.title)
  const [slug, packType] = determineSlugAndPackType(rawSlug)
  const variant = rawProductData.variants.edges[0].node

  const sellingPlanAllocations = variant.sellingPlanAllocations?.edges
  const hasSellingPlan = sellingPlanAllocations.length > 0

  let subscription
  if (hasSellingPlan) {
    const sellingPlanAllocation = sellingPlanAllocations[0].node
    const sellingPlanDetail = sellingPlanAllocation.priceAdjustments[0]

    const subscriptionPrice = Number(sellingPlanDetail.price.amount)
    const subscriptionCompareAtPrice = Number(sellingPlanDetail.compareAtPrice.amount)

    const subscriptionDiscount = subscriptionCompareAtPrice - subscriptionPrice
    const roundedDiscount = Math.round(100 * subscriptionDiscount) / 100
    const percentageDiscount = (roundedDiscount / subscriptionCompareAtPrice) * 100

    subscription = {
      sellingPlans: buildSellingPlansArray(sellingPlanAllocations),
      price: subscriptionPrice,
      compareAtPrice: subscriptionCompareAtPrice,
      priceDiscount: roundedDiscount,
      percentageDiscount: `${percentageDiscount}%`
    }
  }

  const formattedProduct = {
    slug,
    packType,
    productId: {
      encoded: rawProductData.id,
      decoded: decodeId(rawProductData.id)
    },
    variantId: {
      encoded: variant.id,
      decoded: decodeId(variant.id)
    },
    pricing: {
      oneTime: {
        price: Number(variant.priceV2.amount),
        compareAtPrice: variant.compareAtPrice ? Number(variant.compareAtPrice) : null
      },
      subscription
    }
  }

  return formattedProduct
}

const formatRawShopifyData = (rawData: Array<RawProductDataInterface>) => {
  return rawData.map((rawProduct) => formatProduct(rawProduct))
}

export const fetchProductDetail = async (setProductDetail: (arg: any) => void) => {
  try {
    const productMap: ProductMapInterface = {
      bySlug: {},
      byVariantId: {}
    }

    const rawShopifyData = await fetchShopifyProductDetail()
    const shopifyData = formatRawShopifyData(rawShopifyData)

    shopifyData.forEach((formattedProduct: IProductDataInterface) => {
      const { slug, variantId, packType } = formattedProduct

      if (!productMap.bySlug[slug]) productMap.bySlug[slug] = {}

      // Adds separate product detail for each pack type (i.e. single, multipack_2)
      productMap.bySlug[slug][packType] = formattedProduct

      // Allows for product lookup using encoded and decoded variant IDs
      productMap.byVariantId[variantId.encoded] = formattedProduct
      productMap.byVariantId[variantId.decoded] = formattedProduct
    })

    return setProductDetail({ ...productMap, fetching: false })
  } catch (err) {
    Sentry.captureException(err)
  }
}
