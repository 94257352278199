import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { CloseOutlined } from '@ant-design/icons'
import { fonts, colors } from '../../styles/variables'
import { media } from '../../styles/util'

interface FilterTagProps {
  clickHandler: () => void
  text: string
  id: number
}

const FilterTagContainer = styled.div`
  border-radius: 50px;
  font-family: ${fonts.monosten.normal};
  font-size: 16px;
  line-height: 16px;
  color: ${colors.slate};
  border: 1px solid ${colors.slate};
  background-color: white;
  text-transform: uppercase;
  padding: 14px 26px 12px 12px;
  margin: 10px 10px 10px 0;
  position: relative;
  ${media.tablet`
    padding: 14px 26px 12px 18px;
    margin: 10px 20px 10px 0;
  `}
`

const FilterTag: React.FC<FilterTagProps> = ({ clickHandler, text, id }) => {
  const toggle = () => {
    clickHandler({ key: id })
  }
  return (
    <FilterTagContainer>
      {text}
      <CloseOutlined onClick={toggle} style={{ fontSize: '12px', position: 'absolute', top: '14px', right: '10px', cursor: 'pointer' }} />
    </FilterTagContainer>
  )
}
export default FilterTag
