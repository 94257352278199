import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { fonts, colors } from '../../styles/variables'
import { media } from '../../styles/util'

// H1. 128/128pt
export const H1 = styled.h1`
  font-family: ${fonts.athletics.medium};
  color: inherit;
  margin: 0;
  padding: 0;
  line-height: 1;
  ${media.mobile`
    font-size: 64px;
  `}
  ${media.tablet`
    font-size: 88px;
  `}
  ${media.desktop`
    font-size: 133px;
  `}
`

// H3/Home headline. 64/64pt
export const Headline = styled(H1)`
  color: inherit;
  ${media.mobile`
    font-size: 36px;
  `}
  ${media.tablet`
    font-size: 40px;
  `}
  ${media.desktop`
    font-size: 64px;
  `}
`

// H4/Footer Nav. 52/64pt
export const H4 = styled.h4`
  font-family: ${fonts.athletics.medium};
  color: inherit;
  margin: 0;
  padding: 0;
  ${media.mobile`
    font-size: 36px;
    line-height: 44px;
  `}
  ${media.tablet`
    font-size: 42px;
    line-height: 50px;
  `}
  ${media.desktop`
    font-size: 52px;
    line-height: 60px;
  `}
`

// H5/Consultation Main Copy
export const H5 = styled.h5`
  font-family: ${fonts.athletics.medium};
  color: inherit;
  margin: 0;
  padding: 0;
  ${media.mobile`
    font-size: 40px;
    line-height: 46px;
  `}
  ${media.desktop`
    font-size: 52px;
    line-height: 60px;
  `}
`

// Quote/Dek Style/Product Names. 36/46pt
export const H6 = styled.p`
  font-family: ${fonts.athletics.medium};
  color: inherit;
  margin: 0;
  padding: 0;
  ${media.mobile`
    font-size: 28px;
    line-height: 36px;
  `}
  ${media.tablet`
    font-size: 30px;
    line-height: 36px;
  `}
  ${media.desktop`
    font-size: 36px;
    line-height: 46px;
  `}
`

//  Big Article Titles
export const Title = styled.h1`
  font-family: ${fonts.larsseit.regular};
  color: inherit;
  margin: 0;
  padding: 0;
  font-size: 36px;
  line-height: 46px;
`

//  Large Body. 24/36pt
export const LargeP = styled.p`
  font-family: ${fonts.larsseit.regular};
  color: inherit;
  text-align: inherit;
  margin: 0;
  padding: 0;
  ${media.mobile`
    font-size: 18px;
    line-height: 30px;
  `}
  ${media.desktop`
    font-size: 24px;
    line-height: 36px;
  `}
`

//  Medium Body. 18/28pt
export const P = styled(LargeP)`
  color: inherit;
  margin: 0;
  padding: 0;
  ${media.mobile`
    font-size: 18px;
    line-height: 28px;
  `}
  ${media.tablet`
    font-size: 18px;
    line-height: 22px;
  `}
  ${media.desktop`
    font-size: 18px;
    line-height: 30px;
  `}
`

//  Small text. 14/23pt
export const SmallP = styled(LargeP)`
  color: inherit;
  ${media.mobile`
    font-size: 12px;
    line-height: 18px;
  `}
  ${media.tablet`
    font-size: 12px;
    line-height: 18px;
  `}
  ${media.desktop`
    font-size: 14px;
    line-height: 23px;
  `}
`

export const Error = styled(SmallP)`
  color: ${colors.warning};
  height: 30px;
`

// Small Technical Detail. 13/13pt
export const MonoP = styled.p`
  font-family: ${fonts.monosten.normal};
  text-transform: uppercase;
  color: inherit;
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 1;
  letter-spacing: 0.5px;
`

const StyledInternalLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  transition: 100ms color ease-in-out;
  &:hover {
    color: ${colors.orange};
  }
`

export const ALink = styled.a`
  text-decoration: none;
  color: inherit;
  transition: 100ms color ease-in-out;
  &:hover {
    color: ${colors.orange};
  }
`

interface LinkProps {
  to: string
  sameWindow?: boolean
  className?: string
  state?: any
}

export const ExternalLink: React.FC<LinkProps> = ({ to, children, sameWindow, className }) => (
  <ALink
    href={to}
    target={sameWindow ? '_self' : '_blank'}
    rel="noreferrer noopener"
    className={className}
  >
    {children}
  </ALink>
)

export const InternalLink: React.FC<LinkProps> = ({ to, children, state, className }) => {
  return (
    <StyledInternalLink to={to} state={state} className={className}>
      {children}
    </StyledInternalLink>
  )
}
