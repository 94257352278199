import React from 'react'
import * as Sentry from '@sentry/react'
import 'whatwg-fetch'

import './src/styles/global.css'

import { RecoilRoot } from 'recoil'
import { initializeState } from './src/store/persist-localstorage'

export const wrapRootElement = ({ element }) => (
  <RecoilRoot initializeState={initializeState}>{element}</RecoilRoot>
)

export const onClientEntry = () => {
  Sentry.init({
    environment: process.env.NODE_ENV || 'development',
    // Netlify - https://docs.netlify.com/configure-builds/environment-variables/#build-metadata
    release: __SENTRY_RELEASE__,
    dsn: __SENTRY_DSN__,
    enabled: process.env.NODE_ENV === 'production',
    denyUrls: ['giveaways']
  })
  window.Sentry = Sentry
}
