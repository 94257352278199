import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { H6 } from '../../shared/text'
import { PrimaryButton } from '../../shared/buttons'
import { colors } from '../../../styles/variables'

import Dog from '../../../assets/img/logos/finn-dog-orange.svg'

const Container = styled.div`
  height: 85%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: pre-line;
`

const Doggo = styled.img`
  width: 135px;
  height: auto;
  padding: 20px 20px 30px 0;
`

const BigLink = styled(Link)`
  padding: 30px 20px 20px 20px;
`

const EmptyCart = ({ close }: { close: () => void }) => (
  <Container>
    <Doggo src={Dog} alt="Finn dog logo" />
    <H6>{`Your cart is empty!\nFill it with healthy treats.`}</H6>
    <BigLink to="/products" onClick={close}>
      <PrimaryButton bg={colors.orange} color={colors.navy} copy="Shop Now" arrow />
    </BigLink>
  </Container>
)

export default EmptyCart
