interface Breed {
  value: string
  text: string
  fun_fact: string
  health_disorder_1: string
  health_disorder_2: string
}

export const breeds: Breed[] = [
  {
    value: 'affenpinscher',
    text: 'Affenpinscher',
    fun_fact: 'We love Affenpinschers! Did you know they are sometimes called the Monkey Dog because of their similar faces?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'follicular-dysplasia'
  },
  {
    value: 'afghan-hound',
    text: 'Afghan Hound',
    fun_fact: 'We love Afghan Hounds! Did you know they are as fast as purebred racehorses, and can run up to 40 mph?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'airedale-terrier',
    text: 'Airedale Terrier',
    fun_fact: 'We love Airedale Terriers! Did you know they are the largest of all Terriers?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'akita',
    text: 'Akita',
    fun_fact:
      "We love Akitas! Did you know they were status symbols throughout the 17th century in Japan? Only wealthy individuals owned Akitas, and their collar was a symbol of the owner's rank in society.",
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'pemphigus-foliaceus'
  },
  {
    value: 'alaskan-malamute',
    text: 'Alaskan Malamute',
    fun_fact: 'We love Alaskan Malamutes! Did you know they are an odorless breed and will even clean themselves like a cat?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'american-english-coonhound',
    text: 'American English Coonhound',
    fun_fact: 'We love American English Coonhounds! Did you know they can climb trees?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'american-eskimo-dog',
    text: 'American Eskimo',
    fun_fact: 'We love American Eskimo dogs! Did you know they were used to walk a tightrope in the circus?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'american-foxhound',
    text: 'American Foxhound',
    fun_fact: 'We love American Foxhounds! Did you know they are believed to have come to the U.S. in the 1650s?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'american-hairless-terrier',
    text: 'American Hairless Terrier',
    fun_fact: 'We love American Hairless Terriers! Did you know they are the first hairless breed to come from the US?',
    health_disorder_1: 'lcpd',
    health_disorder_2: 'patella-luxation'
  },
  {
    value: 'american-staffordshire-terrier',
    text: 'American Staffordshire Terrier',
    fun_fact: 'We love American Staffordshire Terriers! Did you know they actually hail from Great Britain?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'american-water-spaniel',
    text: 'American Water Spaniel',
    fun_fact: 'We love American Water Spaniels! Did you know they are the State dog of Wisconsin?',
    health_disorder_1: 'mitral-valve-disease',
    health_disorder_2: 'hip-dysplasia'
  },
  {
    value: 'anatolian-shepherd-dog',
    text: 'Anatolian Shepherd',
    fun_fact: 'We love Anatolian Shepherd dogs! Did you know they are actually protected as an Endangered Species in the U.S.?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'australian-cattle-dog',
    text: 'Australian Cattle Dog',
    fun_fact: 'We love Australian Cattle Dogs! Did you know one lived for 29 years, marking the longest lifespan of a dog?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'australian-shepherd',
    text: 'Australian Shepherd',
    fun_fact: "We love Australian Shepherds! Did you know it's common for them to have two different color eyes?",
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'australian-terrier',
    text: 'Australian Terrier',
    fun_fact: 'We love Australian Terriers! Did you know that despite their name, the breed actually originated in England?',
    health_disorder_1: 'lcpd',
    health_disorder_2: 'patella-luxation'
  },
  {
    value: 'azawakh',
    text: 'Azawakh',
    fun_fact: 'We love Azawakhs! Did you know they are an ancient breed from West Africa?',
    health_disorder_1: 'hypothyroidism',
    health_disorder_2: 'epilepsy'
  },
  {
    value: 'barbet',
    text: 'Barbet',
    fun_fact: 'We love Barbets! Did you know they have been used to hunt in the water since the 14th century?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'epilepsy'
  },
  {
    value: 'basenji',
    text: 'Basenji',
    fun_fact: 'We love Basenji dogs! Did you know they are used in Kenya to lure lions out of caves?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'basset-hound',
    text: 'Basset Hound',
    fun_fact: 'We love Basset Hounds! Did you know they are one of the best sniffer dogs with over 220 million smell receptors? ',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'glaucoma'
  },
  {
    value: 'beagle',
    text: 'Beagle',
    fun_fact: 'We love Beagles! Did you know beagles have 220 million scent receptors in their nose, compared to 5 million in humans?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'bearded-collie',
    text: 'Bearded Collie',
    fun_fact: "We love the Bearded Collie! Did you know they are one of Britain's oldest dog breeds?",
    health_disorder_1: 'hypothyroidism',
    health_disorder_2: 'pemphigus-foliaceus'
  },
  {
    value: 'beauceron',
    text: 'Beauceron',
    fun_fact: 'We love Beaucerons! Did you know they have double dewclaws that support them with better traction on uneven terrains? ',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'dermatomyositis'
  },
  {
    value: 'bedlington-terrier',
    text: 'Bedlington Terrier',
    fun_fact: 'We love Bedlington Terriers! Did you know they can live for up to 18 years?',
    health_disorder_1: 'progressive-retinal-atrophy',
    health_disorder_2: 'patella-luxation'
  },
  {
    value: 'belgian-laekenois',
    text: 'Belgian Laekenois',
    fun_fact: 'We love Belgian Laekenois! Did you know they are the rarest of the Belgian sheepdogs?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'belgian-malinois',
    text: 'Belgian Malinois',
    fun_fact: 'We love Belgian Malinois dogs! Did you know they are used in skydiving military operations?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'belgian-sheepdog',
    text: 'Belgian Sheepdog',
    fun_fact:
      'We love Belgian Sheepdogs! Did you know their alternate moniker is \\"Groenendael\\" which is named after a Belgian village?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'belgian-tervuren',
    text: 'Belgian Tervuren',
    fun_fact: 'We love Belgian Tervurens! Did you know they are used as police dogs in Belgium?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'bergamasco-sheepdog',
    text: 'Bergamasco',
    fun_fact: 'We love Bergamascos! Did you know their coat has three layers?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'berger-picard',
    text: 'Berger Picard',
    fun_fact: 'We love Berger Picards! Did you know they love to perform in front of an audience and can easily pick up tricks?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'bernese-mountain-dog',
    text: 'Bernese Mountain Dog',
    fun_fact: 'We love Bernese Mountain Dogs! Did you know they can haul up to 10 times their own weight?',
    health_disorder_1: 'osteochondritis-dissecans',
    health_disorder_2: 'subaortic-stenosis'
  },
  {
    value: 'bichon-frise',
    text: 'Bichon Frise',
    fun_fact:
      'We love Bichon Frises! Did you know they were brought to mainland Europe in the 1300s after sailors rediscovered the breed during their travels?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'cataracts'
  },
  {
    value: 'black-russian-terrier',
    text: 'Black Russian Terrier',
    fun_fact: 'We love Black Russian Terriers! Did you know they were military dogs for the Soviet Union?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'black-and-tan-coonhound',
    text: 'Black and Tan Coonhound',
    fun_fact:
      'We love Black and Tan Coonhounds! They have amazing senses of smell -- did you know they can smell every ingredient while you are cooking?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'bloodhound',
    text: 'Bloodhound',
    fun_fact: 'We love Bloodhounds! Did you know they have an incredible sense of smell, and can follow a 300 hour-old scent trail?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'cataracts'
  },
  {
    value: 'bluetick-coonhound',
    text: 'Bluetick Coonhound',
    fun_fact: 'We love Bluetick Coonhound! Did you know the females are significantly smaller than the males?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'boerboel',
    text: 'Boerboel',
    fun_fact: 'We love Boerboels! Did you know their skin is dark to protect them from the intense African Sun?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'subaortic-stenosis'
  },
  {
    value: 'border-collie',
    text: 'Border Collie',
    fun_fact: 'We love Border Collies! Did you know a Collie holds the world record for fastest skateboarder amongst all dogs? ',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'border-terrier',
    text: 'Border Terrier',
    fun_fact:
      "We love Border Terriers! Did you know they are featured in two famous comedy movies, There's Something About Mary and Anchorman: The Legend of Ron Burgundy? ",
    health_disorder_1: 'lcpd',
    health_disorder_2: 'pulmonic-stenosis'
  },
  {
    value: 'borzoi',
    text: 'Borzoi',
    fun_fact: 'We love Borzois! Did you know the breed was first officially recorded by Russia in 1650?',
    health_disorder_1: 'hypothyroidism',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'boston-terrier',
    text: 'Boston Terrier',
    fun_fact: 'We love Boston Terriers! Did you know they are nicknamed \\"the American Gentlemen\\" because of their tuxedo coat?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'cataracts'
  },
  {
    value: 'bouvier-des-flandres',
    text: 'Bouvier des Flandres',
    fun_fact: 'We love Bouvier des Flandres dogs! Did you know they originated from Belgium?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'subaortic-stenosis'
  },
  {
    value: 'boxer',
    text: 'Boxer',
    fun_fact: 'We love Boxers! Did you know they were used in both World Wars to carry supplies and locate wounded warriors?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'subaortic-stenosis'
  },
  {
    value: 'boykin-spaniel',
    text: 'Boykin Spaniel',
    fun_fact: 'We love Boykin Spaniels! Did you know they also go by several nicknames, like Swamp Poodles and Little Brown Dogs?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'pulmonic-stenosis'
  },
  {
    value: 'briard',
    text: 'Briard',
    fun_fact: 'We love Briards! Did you know the breed owns the record for largest litter recorded with 17 pups?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'brittany',
    text: 'Brittany',
    fun_fact: "We love Brittany's! Did you know they're a popular hunting dog and can retrieve from both land and water?",
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'brussels-griffon',
    text: 'Brussels Griffon',
    fun_fact: 'We love Brussels Griffons! Did you know they were originally bred to hunt rats?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'bull-terrier',
    text: 'Bull Terrier',
    fun_fact: 'We love Bull Terriers! Did you know that obsessive behavior is common with them, like chasing their tail and shadows?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'mitral-valve-disease'
  },
  {
    value: 'bulldog',
    text: 'Bulldog',
    fun_fact: 'We love Bulldogs! Did you know the bulldog is one of the most popular college mascots with at least 15 colleges?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'subaortic-stenosis'
  },
  {
    value: 'bullmastiff',
    text: 'Bullmastiff',
    fun_fact:
      'We love Bullmastiffs! Did you know they were brought to the U.S. from Europe by John D. Rockefeller to guard his country estate?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'pulmonic-stenosis'
  },
  {
    value: 'cairn-terrier',
    text: 'Cairn Terrier',
    fun_fact: 'We love Cairn Terriers! Did you know this is the breed of Toto in the The Wizard of Oz?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'patella-luxation'
  },
  {
    value: 'canaan-dog',
    text: 'Canaan Dog',
    fun_fact: 'We love Canaan dogs! Did you know they are army dogs and have served in the Israeli Defence Forces?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'cane-corso',
    text: 'Cane Corso',
    fun_fact: 'We love Cane Corsos! Did you know they were popular dogs during the Roman Empire and were part of Roman armies? ',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'mitral-valve-disease'
  },
  {
    value: 'cardigan-welsh-corgi',
    text: 'Cardigan Welsh Corgi',
    fun_fact:
      'We love Cardigan Welsh Corgis! Did you know that despite their small legs, they are natural herding dogs and excel in agility training?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'cavalier-king-charles-spaniel',
    text: 'Cavalier King Charles Spaniel',
    fun_fact:
      'We love Cavaliers King Charles Spaniels! Did you know their name comes from King Charles II, who had three spaniels by his side at all times?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'mitral-valve-disease'
  },
  {
    value: 'chesapeake-bay-retriever',
    text: 'Chesapeake Bay Retriever',
    fun_fact: 'We love Chesapeake Bay Retrievers! Did you know they are excellent duck hunters and can retrieve up to 300 ducks per day?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'chihuahua',
    text: 'Chihuahua',
    fun_fact: 'We love Chihuahuas! Did you know they have the biggest brain amongst all dogs relative to their size?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'mitral-valve-disease'
  },
  {
    value: 'chinese-crested',
    text: 'Chinese Crested',
    fun_fact: 'We love Chinese Crested dogs! Did you know they emit high levels of heat and people have used them as bed warmers? ',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'chinese-shar-pei',
    text: 'Chinese Shar-Pei',
    fun_fact: 'We love Chinese Shar-Peis! Did you know they can have a lavender or blue tongue?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'seborrhea'
  },
  {
    value: 'chinook',
    text: 'Chinook',
    fun_fact: 'We love Chinooks! Did you know they are an all-purpose sled dog?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'epilepsy'
  },
  {
    value: 'chow-chow',
    text: 'Chow Chow',
    fun_fact: 'We love Chow Chows! Did you know they have two extra teeth compared to other breeds?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'pemphigus-foliaceus'
  },
  {
    value: 'cirneco-delletna',
    text: "Cirneco dell'Etna",
    fun_fact: "We love Cirneco dell'Etnas! Did you know they are one of the oldest dog breeds and may go back as far as 5th century BC?",
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'clumber-spaniel',
    text: 'Clumber Spaniel',
    fun_fact: 'We love Clumber Spaniels! Did you know they are depicted in art as far back as the 1700s?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'cocker-spaniel',
    text: 'Cocker Spaniel',
    fun_fact: 'We love Cocker Spaniels! Did you know they came over on the Mayflower in 1620?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'collie',
    text: 'Collie',
    fun_fact: 'We love Collies! Did you know they have double coats to keep them warm in the winter?',
    health_disorder_1: 'pemphigus-foliaceus',
    health_disorder_2: 'patent-ductus-arteriosus'
  },
  {
    value: 'coton-de-tulear',
    text: 'Coton De Tulear',
    fun_fact: 'We love Coton De Tulears! Did you know they are the Royal Dog of Madagascar?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'curly-coated-retriever',
    text: 'Curly-Coated Retriever',
    fun_fact: 'We love Curly-Coated Retrievers! Did you know they are assumed to be the oldest of all retriever breeds?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'dachshund',
    text: 'Dachshund',
    fun_fact: 'We love Dachshunds! Did you know the first Olympic mascot was a dachshund named Waldi?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'mitral-valve-disease'
  },
  {
    value: 'dalmatian',
    text: 'Dalmatian',
    fun_fact: 'We love Dalmatians! Did you know they even have spots in their mouth?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'dcm'
  },
  {
    value: 'doberman-pinscher',
    text: 'Doberman Pinscher',
    fun_fact:
      "We love Doberman Pinschers! Did you know they're often used for professional jobs like police work, therapy, and as guide dogs?",
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'dcm'
  },
  {
    value: 'dogo-argentino',
    text: 'Dogo Argentino',
    fun_fact: 'We love Dogo Argentinos! Did you know 10% of the breed is born deaf?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'dogue-de-bordeaux',
    text: 'Dogue de Bordeaux',
    fun_fact: 'We love Dogue de Bordeaux dogs! Did you know their origins are traced back to France as early as the 14th century?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'dcm'
  },
  {
    value: 'dutch-shepherd',
    text: 'Dutch Shepherd',
    fun_fact: 'We love Dutch Shepherds! Did you know they remain a rare breed of dogs?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'atopic-dermatitis'
  },
  {
    value: 'english-cocker-spaniel',
    text: 'English Cocker Spaniel',
    fun_fact: 'We love English Cocker Spaniels! Did you know that scientists used a Cocker Spaniel as the first cancer-detecting dog? ',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'english-foxhound',
    text: 'English Foxhound',
    fun_fact: 'We love English Foxhounds! Did you know they are rarely kept as house pets, and otherwise used for foxhunting?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'english-setter',
    text: 'English Setter',
    fun_fact: 'We love English Setters! Did you know President Franklin D. Roosevelt had an English Setter in the White House named Winks?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'english-springer-spaniel',
    text: 'English Springer Spaniel',
    fun_fact: 'We love English Springer Spaniels! Did you know they appear in 16th century art?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'english-toy-spaniel',
    text: 'English Toy Spaniel',
    fun_fact: 'We love English Toy Spaniels! Did you know their flat noses comes from Pugs?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'seborrhea'
  },
  {
    value: 'entlebucher-mountain-dog',
    text: 'Entlebucher Mountain Dog',
    fun_fact:
      "We love Enllebucher Mountain dogs! Did you know they're named after a mountainous district in Switzerland where the breed originated?",
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'patella-luxation'
  },
  {
    value: 'field-spaniel',
    text: 'Field Spaniel',
    fun_fact: 'We love Field Spaniels! Did you know they were bred to retrieve game from land and water?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'finnish-spitz',
    text: 'Finish Spitz',
    fun_fact: 'We love Finish Spitz! Did you know they have been recorded barking up to 160 times per minute?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'patella-luxation'
  },
  {
    value: 'finnish-lapphund',
    text: 'Finnish Lapphund',
    fun_fact: 'We love Finnish Lapphunds! Did you know they were originally bred to herd reindeer?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'flat-coated-retriever',
    text: 'Flat-Coated Retriever',
    fun_fact: 'We love Flat-Coated Retrievers! Did you know they actually used to be called the Wavy-Coated Retrievers?',
    health_disorder_1: 'hypothyroidism',
    health_disorder_2: 'dcm'
  },
  {
    value: 'french-bulldog',
    text: 'French Bulldog',
    fun_fact: 'We love Frenchies! Did you know that they can \\"talk\\" using a complex system of yawns, yips, and gargles?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'demodex-red-mite-mange'
  },
  {
    value: 'german-pinscher',
    text: 'German Pinscher',
    fun_fact: 'We love German Pinschers! Did you know the breed almost became extinct following both of the World Wars?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'german-shepherd-dog',
    text: 'German Shepherd',
    fun_fact: 'We love German Shepherds! Did you know they were the first breed to be used as service dogs?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'degenerative-myelopathy'
  },
  {
    value: 'german-shorthaired-pointer',
    text: 'German Shorthaired Pointer',
    fun_fact: 'We love GSPs! Did you know the Air Force and TSA use GSPs for their ability to smell explosives?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'dcm'
  },
  {
    value: 'german-wirehaired-pointer',
    text: 'German Wirehaired Pointer',
    fun_fact: 'We love German Wirehaired Pointers! Did you know they have a water-resistant coat?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'giant-schnauzer',
    text: 'Giant Schnauzer',
    fun_fact:
      "We love Giant Schnauzers! Did you know that because of their high intelligence and need to work, they're often used as police, military, and search and rescue dogs?",
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'golden-retriever',
    text: 'Golden Retriever',
    fun_fact: 'We love Goldens! Did you know the first image ever uploaded to Instagram was a picture of a Golden Retriever?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'subaortic-stenosis'
  },
  {
    value: 'gordon-setter',
    text: 'Gordon Setter',
    fun_fact: 'We love Gordon Setters! Did you know they are traced back as early as the 1600s in England and Scotland?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'grand-basset-griffon-vendeen',
    text: 'Grand Basset Griffon Vendeen',
    fun_fact:
      'We love Grand Basset Griffon Vendeens! Did you know they were only recognized as a breed by the American Kennel Club in 2018?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'great-dane',
    text: 'Great Dane',
    fun_fact: 'We love Great Danes! Did you know there are drawings of them on Egyptian monuments dating back to 3000 B.C.? ',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'dcm'
  },
  {
    value: 'great-pyrenees',
    text: 'Great Pyrenees',
    fun_fact: 'We love Great Pyrenees dogs! Did you know they are nocturnal by nature, and were bred to guard animal flocks at night?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'greater-swiss-mountain-dog',
    text: 'Greater Swiss Mountain Dog',
    fun_fact: 'We love Greater Swiss Mountain dogs! Did you know they are capable of pulling loads over 3,000 lbs?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'osteochondritis-dissecans'
  },
  {
    value: 'greyhound',
    text: 'Greyhound',
    fun_fact: 'We love Greyhounds! Did you know they can run as fast as 45 mph?',
    health_disorder_1: 'pannus',
    health_disorder_2: 'comedones'
  },
  {
    value: 'harrier',
    text: 'Harrier',
    fun_fact: 'We love Harriers! Did you know they are incredibly rare in the United States?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'epilepsy'
  },
  {
    value: 'havanese',
    text: 'Havanese',
    fun_fact: 'We love Havaneses! Did you know they are the only dog breed native to Cuba?',
    health_disorder_1: 'lcpd',
    health_disorder_2: 'mitral-valve-disease'
  },
  {
    value: 'ibizan-hound',
    text: 'Ibizan Hound',
    fun_fact: 'We love Ibizan Hounds! Did you know they were owned by Pharaohs in Ancient Egypt? ',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'icelandic-sheepdog',
    text: 'Icelandic Sheepdog',
    fun_fact: 'We love Icelandic Sheepdogs! Did you know they were brought to Iceland by the Vikings who first settled there?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'patella-luxation'
  },
  {
    value: 'irish-red-and-white-setter',
    text: 'Irish Red and White Setter',
    fun_fact: 'We love Irish Red and White Setters! Did you know they were bred to hunt birds?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'irish-setter',
    text: 'Irish Setter',
    fun_fact:
      'We love Irish Setters! Did you know multiple US presidents had them as pets in the White House, like Harry Truman, Ronald Reagan, and Richard Nixon?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'irish-water-spaniel',
    text: 'Irish Water Spaniel',
    fun_fact: 'We love Irish Water Spaniels! Did you know their tails act like rudders when in water to make them better swimmers?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'follicular-dysplasia'
  },
  {
    value: 'irish-wolfhound',
    text: 'Irish Wolfhound',
    fun_fact: 'We love Irish Wolfhounds! Did you know they are the tallest dogs in the world?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'italian-greyhound',
    text: 'Italian Greyhound',
    fun_fact: 'We love Italian Greyhounds! Did you know they are one of the oldest dog breeds?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'keeshond',
    text: 'Keeshond',
    fun_fact: 'We love Keeshonds! Did you know they dig holes in the summer to stay cool?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'seborrhea'
  },
  {
    value: 'kerry-blue-terrier',
    text: 'Kerry Blue Terrier',
    fun_fact: 'We love Kerry Blue Terriers! Did you know the Kerry originated in Ireland?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'komondor',
    text: 'Komondor',
    fun_fact:
      'We love Komondork! Did you know their coats are used as camouflage when they are protecting flocks of sheep from predators? ',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'cataracts'
  },
  {
    value: 'kuvasz',
    text: 'Kuvasz',
    fun_fact: 'We love Kuvasz dogs! Did you know they originally came from Tibet?',
    health_disorder_1: 'osteochondritis-dissecans',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'labrador-retriever',
    text: 'Labrador Retriever',
    fun_fact: 'We love labs! Did you know labs of all three colors can be born in the same litter?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'tricuspid-valve-dysplasia'
  },
  {
    value: 'lagotto-romagnolo',
    text: 'Lagotto Romagnolo',
    fun_fact: 'We love Lagotto Romagnolos! Did you know they are believed to be the ancestor for all water dogs?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'cataracts'
  },
  {
    value: 'lancashire-heeler',
    text: 'Lancashire Heeler',
    fun_fact: 'We love Lancashire Heelers! Did you know they have been around since the 1600s?',
    health_disorder_1: 'progressive-retinal-atrophy',
    health_disorder_2: 'collie-eye-anomaly'
  },
  {
    value: 'leonberger',
    text: 'Leonberger',
    fun_fact:
      "We love Leonbergers! Did you know that despite their size they're actually a sensitive breed and can get distressed when people argue?",
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'lhasa-apso',
    text: 'Lhasa Apso',
    fun_fact: 'We love Lhasa Apsos! Did you know they came to the U.S as gifts from the Dalai Lama?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'cataracts'
  },
  {
    value: 'lowchen',
    text: 'Lowchen',
    fun_fact: 'We love Lowchens! Did you know they are nicknamed \\"Lion Dogs?\\"',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'maltese',
    text: 'Maltese',
    fun_fact: 'We love Malteses! Did you know they make great therapy dogs?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'mitral-valve-disease'
  },
  {
    value: 'manchester-terrier',
    text: 'Manchester Terrier',
    fun_fact: 'We love Manchester Terriers! Did you know they can live for up to 15 years?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'lcpd'
  },
  {
    value: 'mastiff',
    text: 'Mastiff',
    fun_fact: 'We love Mastiffs! Did you know they hold the record for heaviest dog at 343 pounds?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'dcm'
  },
  {
    value: 'miniature-american-shepherd',
    text: 'Miniature American Shepherd',
    fun_fact: 'We love Miniature American Shepherds! Did you know they were first bred in California to compete in rodeo circuits?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'miniature-pinscher',
    text: 'Miniature Pinscher',
    fun_fact: 'We love Miniature Pinschers! Did you know they were first bred to hunt rats?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'miniature-schnauzer',
    text: 'Miniature Schnauzer',
    fun_fact: 'We love Miniature Schnauzers! Did you know their mustaches were originally used to protect their faces against prey?',
    health_disorder_1: 'mitral-valve-disease',
    health_disorder_2: 'cataracts'
  },
  {
    value: 'mudi',
    text: 'Mudi',
    fun_fact: 'We love Mudis! Did you know they originated from Hungary?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'elbow-dysplasia'
  },
  {
    value: 'neapolitan-mastiff',
    text: 'Neapolitan Mastiff',
    fun_fact: 'We love Neapolitan Mastiffs! Did you know they are actually forbidden in Singapore and Romania?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'dcm'
  },
  {
    value: 'nederlandse-kooikerhondje',
    text: 'Nederlandse Kooikerhondje',
    fun_fact: 'We love Nederlandse Kooikerhondjes! Did you know they were bred to play as a decoy for luring and trapping ducks?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'cataracts'
  },
  {
    value: 'newfoundland',
    text: 'Newfoundland',
    fun_fact: 'We love Newfoundlands! Did you know they are great water rescue dogs?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'dcm'
  },
  {
    value: 'norrbottenspets',
    text: 'Norrbottenspets',
    fun_fact: 'We love Norrbottenspets! Did you know that Sweden and Finland have argued for centuries over where this breed originated?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'epilepsy'
  },
  {
    value: 'norwegian-buhund',
    text: 'Norwegian Buhund',
    fun_fact: 'We love Norwegian Buhunds! Did you know they have six fully developed toes?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'cataracts'
  },
  {
    value: 'norwegian-elkhound',
    text: 'Norwegian Elkhound',
    fun_fact: 'We love Norwegian Elkhounds! Did you know they are one of the oldest breeds and have been found in Viking burial grounds?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'norwegian-lundehund',
    text: 'Norwegian Lundhund',
    fun_fact: 'We love Norwegian Lundhunds! Did you know they have six toes on each paw?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'lundehund-syndrome'
  },
  {
    value: 'nova-scotia-duck-tolling-retriever',
    text: 'Nova Scotia Duck Tolling Retriever',
    fun_fact: 'We love Nova Scotia Duck Tolling Retrievers! Did you know they are the smallest of the Retriever breeds?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'old-english-sheepdog',
    text: 'Old English Sheepdog',
    fun_fact: 'We love Old English Sheepdogs! Did you know that they were originally for wealthy families in the U.S?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'otterhound',
    text: 'Otterhound',
    fun_fact:
      'We love Otterhounds! Did you know they are actually one of the most endangered dog breeds today, with fewer than 350 in the U.S.?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'elbow-dysplasia'
  },
  {
    value: 'papillon',
    text: 'Papillon',
    fun_fact: "We love Papillons! Did you know their name is French for 'butterfly'?",
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'pekingese',
    text: 'Pekingese',
    fun_fact: 'We love Pekingese dogs! Did you know they were bred in China to resemble stylized Chinese lion images?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'pembroke-welsh-corgi',
    text: 'Pembroke Welsh Corgi',
    fun_fact: 'We love Corgis! Did you know corgis have been part of the British Royal Family for over 70 years?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'cataracts'
  },
  {
    value: 'peruvian-inca-orchid',
    text: 'Peruvian Inca Orchid',
    fun_fact: 'We love Peruvian Inca Orchids! Did you know not all of them are hairless?',
    health_disorder_1: 'skin-lesions',
    health_disorder_2: 'epilepsy'
  },
  {
    value: 'petit-basset-griffon-vendeen',
    text: 'Petit Basset Griffon Vendeen',
    fun_fact:
      "We love Petit Basset Griffon Vendeens! Did you know they are called the Happy Breed since they're always in such high spirits?",
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'pharaoh-hound',
    text: 'Pharaoh Hound',
    fun_fact: 'We love Pharaohs Hounds! Did you know they blush when they are excited?',
    health_disorder_1: 'dcm',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'plott',
    text: 'Plott',
    fun_fact:
      "We love Plotts! Did you know that while they have German roots, they're one of the only breeds developed primarily in the U.S.?",
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'mucopolysaccharidoses'
  },
  {
    value: 'pointer',
    text: 'Pointer',
    fun_fact: 'We love Pointers! Did you know they think of themselves as a fellow member of the family?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'polish-lowland-sheepdog',
    text: 'Polish Lowland Sheepdog',
    fun_fact:
      'We love Polish Lowland Sheepdogs! Did you know a legendary PSD named Psyche was able to detect a bomb in Warsaw during World War II?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'pomeranian',
    text: 'Pomeranian',
    fun_fact: 'We love Poms! Did you know they hold the world record for the fastest dog to run on two paws?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'patent-ductus-arteriosus'
  },
  {
    value: 'poodle',
    text: 'Poodle',
    fun_fact: 'We love Poodles! Did you know the poodle is the only dog that comes in three sizes: standard, miniature, and toy?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'sebaceous-adenitis'
  },
  {
    value: 'portuguese-podengo',
    text: 'Portuguese Podengo',
    fun_fact: 'We love Podengos! Did you know they are the national dog breed of Portugal?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'patella-luxation'
  },
  {
    value: 'portuguese-podengo-pequeno',
    text: 'Portuguese Podengo Pequeno',
    fun_fact:
      'We love Podengos! Did you know they were used by medieval Portuguese explorers to prevent small animals from inhabiting their ships?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'patella-luxation'
  },
  {
    value: 'portuguese-water-dog',
    text: 'Portuguese Water Dog',
    fun_fact: 'We love Portuguese Water Dogs! Did you know they helped the Spanish Armada pass messages between ships in the 1500s?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'pug',
    text: 'Pug',
    fun_fact: 'We love Pugs! Did you know the perfect pug tail has two curls?',
    health_disorder_1: 'lcpd',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'puli',
    text: 'Puli',
    fun_fact: "We love Pulis! Did you know they've been used as herding dogs for over 1,000 years?",
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'pumi',
    text: 'Pumi',
    fun_fact: 'We love Pumi dogs! Did you know they are closely related to the Puli dog, and were separated as two breeds last century?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'patella-luxation'
  },
  {
    value: 'pyrenean-shepherd',
    text: 'Pyrenean Shepherd',
    fun_fact: 'We love Pyrenean Shepherds! Did you know they served in World War I as courier and search-and-rescue dogs?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'redbone-coonhound',
    text: 'Redbone Coonhound',
    fun_fact: 'We love Redbound Coonhounds! Did you know they inherited their fur coats from their red foxhound ancestors?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'rhodesian-ridgeback',
    text: 'Rhodesian Ridgeback',
    fun_fact: 'We love Rhodesian Ridgebacks! Did you know they were originally bred in Africa to help hunt lions, bears, and boars?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'atopic-dermatitis'
  },
  {
    value: 'rottweiler',
    text: 'Rottweiler',
    fun_fact: 'We love Rottweilers! Did you know their jaws can produce a bite force of up to 328 lbs?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'subaortic-stenosis'
  },
  {
    value: 'russell-terrier',
    text: 'Russell Terrier',
    fun_fact: 'We love Russel Terriers! Did you know they can jump 5 feet in the air?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'pulmonic-stenosis'
  },
  {
    value: 'russian-toy',
    text: 'Russian Toy',
    fun_fact:
      'We love Russian Toy Dogs! Did you know they were originally used as rat hunters because they are able to fit into small holes?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'saluki',
    text: 'Saluki',
    fun_fact: 'We love Salukis! Did you know they hunt by sight, rather than smell or sound like other dogs?',
    health_disorder_1: 'hypothyroidism',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'samoyed',
    text: 'Samoyed',
    fun_fact: 'We love Samoyeds! Did you know they can sing, and will often join in if you play some music or start howling yourself?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'schipperke',
    text: 'Schipperke',
    fun_fact:
      'We love Schipperkes! Did you know their name means \\"the little boatman\\"? They were bred to lend a helping paw on boats in Belgium!',
    health_disorder_1: 'lcpd',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'scottish-deerhound',
    text: 'Scottish Deerhound',
    fun_fact: 'We love Scottish Deerhounds! Did you know they were bred for noble Scottish families to use as hunting companions?',
    health_disorder_1: 'hypothyroidism',
    health_disorder_2: 'dcm'
  },
  {
    value: 'shetland-sheepdog',
    text: 'Shetland Sheepdog',
    fun_fact: 'We love Shetland Sheepdogs! Did you know they are one of the most intelligent dog breeds in the world?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'dermatomyositis'
  },
  {
    value: 'shiba-inu',
    text: 'Shiba Inu',
    fun_fact: 'We love Shibu Inus! Did you know their coats are self-cleaning, and can actually repel dirt?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'shih-tzu',
    text: 'Shih Tzu',
    fun_fact:
      "We love Shih Tzus! Did you know they were common pets for Royalty, who loved that the breed's thick fur would keep their beds warm?",
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'siberian-husky',
    text: 'Siberian Husky',
    fun_fact: "We love Siberian Huskies! Did you know that a husky's howl can be heard up to 10 miles away?",
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'silky-terrier',
    text: 'Silky Terrier',
    fun_fact: 'We love Silky Terriers! Did you know they originate from Sydney, Australia and can make great urban companions?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'lcpd'
  },
  {
    value: 'sloughi',
    text: 'Sloughi',
    fun_fact: 'We love Sloughis! Did you know they are known as the Arabian Greyhound?',
    health_disorder_1: 'progressive-retinal-atrophy',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'spanish-water-dog',
    text: 'Spanish Water Dog',
    fun_fact:
      'We love Spanish Water dogs! Did you know that despite their name, the breed was long used by farmers to herd and protect livestock?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'spinone-italiano',
    text: 'Spinone Italiano',
    fun_fact: 'We love Spinone Italianos! Did you know they appear in Italian Renaissance art? ',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'st-bernard',
    text: 'St. Bernard',
    fun_fact:
      'We love St. Bernards! Did you know they used to be mountain rescue dogs because of their keen sense of smell and direction? ',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'dcm'
  },
  {
    value: 'standard-schnauzer',
    text: 'Standard Schnauzer',
    fun_fact: 'We love Standard Schnauzers! Did you know their origin is traced back as early as the 7th century?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'sussex-spaniel',
    text: 'Sussex Spaniel',
    fun_fact: 'We love Sussex Spaniels! Did you know they were first bred as field dogs in Sussex, England during the 1800s?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'pulmonic-stenosis'
  },
  {
    value: 'swedish-vallhund',
    text: 'Swedish Vallhund',
    fun_fact: 'We love Swedish Vallhunds! Did you know they are closely related to Corgis?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'tibetan-mastiff',
    text: 'Tibetan Mastiff',
    fun_fact: "We love Tibetan Mastiffs! Did you know they were thought to be part of Genghis Khan's army?",
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'tibetan-spaniel',
    text: 'Tibetan Spaniel',
    fun_fact: 'We love Tibetan Spaniels! Did you know they were bred by Buddhist monks?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'tibetan-terrier',
    text: 'Tibetan Terrier',
    fun_fact: 'We love Tibetan Terriers! Did you know they were historically considered as good luck charms in Tibet?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'atopic-dermatitis'
  },
  {
    value: 'toy-fox-terrier',
    text: 'Toy Fox Terrier',
    fun_fact: 'We love Toy Fox Terriers! Did you know they used to perform as circus dogs?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'poodle-toy',
    text: 'Toy Poodle',
    fun_fact: 'We love Poodles! Did you know their \\"hairdo\\" protects their vital organs from the cold?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'sebaceous-adenitis'
  },
  {
    value: 'treeing-walker-coonhound',
    text: 'Treeing Walker Coonhound',
    fun_fact: 'We love American Staffordshire Terriers! Did you know they actually hail from Great Britain?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'vizsla',
    text: 'Vizsla',
    fun_fact: 'We love Vizslas! Did you know they are called \\"velcro dogs\\" because they love affection and attention?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'weimaraner',
    text: 'Weimaraner',
    fun_fact:
      'We love Weimaraners! Did you know the Germans were highly protective of this breed and formed an exclusive club of Weimaraner owners?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'dcm'
  },
  {
    value: 'welsh-springer-spaniel',
    text: 'Welsh Springer Spaniel',
    fun_fact:
      "We love Welsh Springer Spaniels! Did you know that despite their naming they're not a variant of the English Springer Spaniel?",
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'hypothyroidism'
  },
  {
    value: 'whippet',
    text: 'Whippet',
    fun_fact: 'We love Whippets! Did you know they can run up to 35 mph?',
    health_disorder_1: 'hypothyroidism',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'wirehaired-pointing-griffon',
    text: 'Wirehaired Pointing Griffon',
    fun_fact: 'We love Wirehaired Pointing Griffons! Did you know their ancestry is traced as far back as 500 BC?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'follicular-dysplasia'
  },
  {
    value: 'wirehaired-vizsla',
    text: 'Wirehaired Vizsla',
    fun_fact: 'We love Wirehaired Vizslas! Did you know they were first bred in Hungary?',
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'subaortic-stenosis'
  },
  {
    value: 'xoloitzcuintli',
    text: 'Xoloitzcuintli',
    fun_fact: "We love Xolos! Did you know they've been around for over 3,000 years?",
    health_disorder_1: 'hip-dysplasia',
    health_disorder_2: 'progressive-retinal-atrophy'
  },
  {
    value: 'yorkshire-terrier',
    text: 'Yorkshire Terrier',
    fun_fact: 'We love Yorkies! Did you know that the first therapy dog during WWII was a Yorkie?',
    health_disorder_1: 'patella-luxation',
    health_disorder_2: 'progressive-retinal-atrophy'
  }
]
