import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'

import DrawerMenu from '../DrawerMenu'
import MenuWrapper from '../MenuWrapper'
import CartRow from './CartRow'
import EmptyCart from './EmptyCart'
import Recommended from './Recommended'

import AfterpayModal from 'src/components/shared/AfterpayModal'
import AfterpayRow from 'src/components/NavBar/CartDrawer/AfterpayRow'
import Spinner from 'src/components/shared/Spinner'
import { MonoP } from 'src/components/shared/text'
import { StretchCTAButton } from 'src/components/shared/buttons'

import { colors } from 'src/styles/variables'
import determineCartUpsell from 'src/utils/determineCartUpsell'

import useCart from 'src/hooks/useCart'

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3px;
`

const Header = styled(MonoP)`
  color: ${colors.lightText};
  padding-top: 3px;
  font-size: 16px;
`

const ClickableText = styled(Header)`
  cursor: pointer;
  transition: 150ms transform ease-in-out;
  height: fit-content;
  padding-top: 6px;

  &:hover {
    color: ${colors.orange};

    & svg > path {
      fill: ${colors.orange};
    }
  }
`

const Subtotal = styled(Header)<{ strikethrough?: boolean }>`
  text-decoration-line: ${(props) => (props.strikethrough ? 'line-through;' : 'none')}
  color: ${(props) => (props.strikethrough ? colors.slate : colors.lightText)};
  margin-right: ${(props) => (props.strikethrough ? '9px' : 0)};
`

const ItemsContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 10px 10px 0 10px;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.slate};
    border-radius: 100px;
  }
`

const slideIn = keyframes`
  0% { transform: translateY(-100%) }
  100% { transform: translateY(0% }
`

const OrangeBanner = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 31px;

  display: grid;
  place-items: center;

  background-color: ${colors.orange};
  color: ${colors.navy};
  font-size: 16px;
  font-family: Larsseit-Medium, sans-serif;

  animation: ${slideIn} 0.3s;
  z-index: 10000;
`

const GreyMonoP = styled(MonoP)`
  color: ${colors.slate};
  line-height: 1 !important;
  padding: 12px 0 0 0;

  & span {
    color: ${colors.orange};
  }
`

const Discount = styled(MonoP)`
  color: ${colors.orange};
  padding: 10px 0 0 0;
`

const Footer = styled.div`
  width: 100%;
  padding: 12px 14px 14px 14px;
  border-top: 1px solid ${colors.lightText};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CheckoutLink = styled.a`
  width: 100%;
  display: grid;
  place-items: center;
  height: 50px;
  margin: 30px 0 0 0;
`

interface CartDrawerProps {
  visible: boolean
  close: () => void
}

const CartDrawer = ({ visible, close }: CartDrawerProps) => {
  const [afterpayModalOpen, setAfterpayModalOpen] = useState(false)

  const {
    updating,
    checkoutUrl,
    empty,
    grossTotal,
    netTotal,
    actualTotal,
    discounts,
    subscriptionSavings,
    freeShipping,
    products,
    items: lineItems
  } = useCart()

  const reversedItems = lineItems.slice(0).reverse()

  const openAfterpayModal = () => setAfterpayModalOpen(true)

  const upsellProduct = determineCartUpsell(lineItems, products) || [null, null]

  return (
    <>
      <DrawerMenu
        placement="right"
        visible={visible}
        close={close}
        width="525px"
        afterpayModalOpen={afterpayModalOpen}
      >
        {updating ? (
          <Spinner color={colors.orange} size={80} />
        ) : (
          <MenuWrapper>
            {freeShipping && <OrangeBanner>Congrats! You've unlocked free shipping!</OrangeBanner>}

            <HeaderRow>
              <Header>YOUR CART</Header>
              <ClickableText onClick={close} style={{ fontSize: 16 }}>
                Close{' '}
                <svg
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.8 0L13.5 6.1L6.8 12.2L5.8 11L10.2 7H0V5.2H10.2L5.7 1.2L6.8 0Z"
                    fill={colors.lightText}
                  />
                </svg>
              </ClickableText>
            </HeaderRow>
            {empty ? (
              <EmptyCart close={close} />
            ) : (
              <>
                <ItemsContainer>
                  {reversedItems.map((lineItem: any, idx: number) => (
                    <CartRow
                      key={lineItem.id}
                      lineItem={lineItem}
                      products={products}
                      idx={idx}
                      close={close}
                    />
                  ))}
                  {!empty && <Recommended lineItems={lineItems} upsellProduct={upsellProduct} />}
                </ItemsContainer>
                <Footer>
                  <HeaderRow>
                    <Header>SUBTOTAL</Header>
                    <div style={{ display: 'flex' }}>
                      {actualTotal !== grossTotal && (
                        <Subtotal strikethrough>${grossTotal.toFixed(2)}</Subtotal>
                      )}
                      <Subtotal>${actualTotal.toFixed(2)}</Subtotal>
                    </div>
                  </HeaderRow>

                  {subscriptionSavings > 0 ? (
                    <HeaderRow>
                      <GreyMonoP>SUBSCRIPTION SAVINGS</GreyMonoP>
                      <Discount>-${subscriptionSavings.toFixed(2)}</Discount>
                    </HeaderRow>
                  ) : (
                    <>
                      <AfterpayRow netTotal={netTotal} openAfterpayModal={openAfterpayModal} />
                      {discounts.map((discount: { [key: string]: string }) => (
                        <HeaderRow key={discount.code}>
                          <GreyMonoP>
                            Code "<span>{discount.code}</span>" Applied
                          </GreyMonoP>
                          <Discount>-${discount.amount}</Discount>
                        </HeaderRow>
                      ))}
                    </>
                  )}

                  {checkoutUrl && (
                    <CheckoutLink href={checkoutUrl}>
                      <StretchCTAButton
                        bg={colors.orange}
                        color={colors.navy}
                        copy="Checkout Now"
                        arrow
                      />
                    </CheckoutLink>
                  )}
                </Footer>
              </>
            )}
          </MenuWrapper>
        )}
      </DrawerMenu>

      <AfterpayModal open={afterpayModalOpen} setOpen={setAfterpayModalOpen} />
    </>
  )
}

export default CartDrawer
