import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { useRecoilState } from 'recoil'
import { getImage } from 'gatsby-plugin-image'
import { Parallax } from 'react-scroll-parallax'

import FeaturedBundle from './FeaturedBundle'
import BuyABundle from './BuyABundle'
import { Singles } from '../OurProducts'

import { H1 } from '../shared/text'
import { media } from '../../styles/util'
import { colors } from '../../styles/variables'

import { productDetailState } from '../../actions/products'

import { trackCollectionViewEvent } from '../../utils/event-tracking'

const Section = styled.div`
  padding: 90px 0 0 0;
  background-color: #fff;
  width: 100%;
  color: ${colors.navy};
`

const Container = styled.div`
  width: 100%;
  justify-content: space-around;

  ${media.mobile`
    margin-top: 30px;
  `}
  ${media.tablet`
    margin-top: 80px;
  `}

  & h2 {
    color: ${colors.navy};
    ${media.mobile`
      font-size: 42px;
      line-height: 1.35 !important;
      padding: 0 20px;
    `}
    ${media.desktop`
      font-size: 86px;
      margin-top: -30px;
    `}
  }
`

const Header = styled.div`
  width: 100%;
  text-align: center;
  position: relative;

  ${media.mobile`
    min-height: 350px;
  `}
  ${media.tablet`
    min-height: 250px;
  `}
  ${media.desktop`
    min-height: 400px;
  `}
`

const query = graphql`
  query {
    featuredProductImg: file(relativePath: { regex: "/tins/starter-bundle.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }
  }
`

const Products = ({ isAlternate }: { isAlternate?: boolean }) => {
  const { featuredProductImg } = useStaticQuery(query)
  const [productMap] = useRecoilState(productDetailState)

  const { fetching } = productMap

  useEffect(() => {
    if (!fetching && productMap?.bySlug) {
      const productSlugs = Object.keys(productMap.bySlug)
      trackCollectionViewEvent(productMap, productSlugs)
    }
  }, [fetching])

  const featuredProduct = {
    url: '/products/starter-bundle',
    lookupKey: 'starter-bundle',
    name: 'The Starter Bundle',
    description:
      'For the dog that deserves it all — our best-selling bundle features Calming Aid, Digestive Probiotics, and a daily Multivitamin which all work together to nourish your doggo from nose to tail.',
    image: getImage(featuredProductImg)
  }

  return (
    <Section>
      <Container>
        <Header>
          <Parallax y={[-30, 40]}>
            <H1>Wellness Routines</H1>
            <h2>purpose-built for your pup</h2>
          </Parallax>
        </Header>
        <FeaturedBundle product={featuredProduct} bg={colors.navy} />
        <BuyABundle isProductLibrary />
        <Singles title="Shop Supplements" isProductLibrary isAlternate={isAlternate} />
      </Container>
    </Section>
  )
}

export default Products
