import React from 'react'
import styled from 'styled-components'

import FrequencyDropdown from './FrequencyDropdown'
import { SmallP } from 'src/components/shared/text'
import { colors, fonts } from 'src/styles/variables'
import { media } from 'src/styles/util'
import Checkmark from 'src/assets/img/icons/checkmark.svg'

import { ISubscriptionDropdownProps } from 'src/types/pdp'

const CheckmarkContainer = styled.div`
  width: fit-content;

  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, auto);

  ${media.mobile`
    padding: 0 0 0 3px;
    grid-column-gap: 16px;
  `}
  ${media.desktop`
    padding: 6px 0 0 6px;
    grid-column-gap: 24px;
  `}
`

const CheckmarkLabel = styled(SmallP)`
  width: fit-content;
  text-align: left;
  font-size: 14px;
  line-height: 1.3 !important;
`

const CheckMark = styled.img`
  height: 14px;
  width: auto;

  ${media.mobile`
    margin-right: 3px;
  `}
  ${media.desktop`
    margin-right: 6px;
  `}
`

const SubscriptionDropdown: React.FC<ISubscriptionDropdownProps> = (props) => {
  const { frequency, setFrequency } = props

  return (
    <div style={{ width: '100%' }}>
      <FrequencyDropdown frequency={frequency} setFrequency={setFrequency} />
      <CheckmarkContainer>
        <CheckmarkLabel>
          <CheckMark src={Checkmark} alt="Checkmark icon" />
          Free US Shipping
        </CheckmarkLabel>
        <CheckmarkLabel>
          <CheckMark src={Checkmark} alt="Checkmark icon" />
          Change or cancel anytime
        </CheckmarkLabel>
      </CheckmarkContainer>
    </div>
  )
}

export default SubscriptionDropdown
