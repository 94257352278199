import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gsap, Power3 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'

import DropHeader from '../shared/DropHeader'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'
import { Headline, LargeP } from '../shared/text'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.teal};
  position: relative;
  ${media.mobile`
    padding: 0 25px;
  `}
  ${media.tablet`
    padding: 0 30px;
  `}
`

const ImageWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  ${media.mobile`
    height: 275px;
  `}
  ${media.tablet`
    height: 400px;
  `}
  ${media.desktop`
    height: 515px;
    max-width: 1300px;
    margin-top: -40px;
  `}
`

const Grid = styled.div`
  margin: 0 auto;
  width: 100%;
  display: grid;
  justify-content: space-around;
  ${media.mobile`
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
    padding: 50px 0 60px 0;
  `}
  ${media.tablet`
    grid-template-columns: 550px;
    grid-row-gap: 50px;
    padding: 50px 0 80px 0;
  `}
  ${media.desktop`
    max-width: 1100px;
    grid-template-columns: repeat(2, 428px);
    grid-column-gap: 80px;
    padding: 70px 40px 100px 40px;
  `}
`

const TextHeader = styled(Headline)`
  margin-bottom: 30px;
`

const query = graphql`
  query {
    image: file(relativePath: { regex: "/homepage/finn-way.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1100)
      }
    }
  }
`

const TheFinnWay = () => {
  const { image } = useStaticQuery(query)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.fromTo(
      '.textHeader',
      { y: 20, opacity: 0 },
      { scrollTrigger: '.textHeader', duration: 1, ease: Power3.easeOut, y: 0, opacity: 1 }
    )
    gsap.fromTo(
      '.textBody',
      { y: 20, opacity: 0 },
      {
        scrollTrigger: '.textBody',
        delay: 0.25,
        duration: 1,
        ease: Power3.easeOut,
        y: 0,
        opacity: 1
      }
    )
  }, [])

  return (
    <Section>
      <DropHeader title="The Finn Way" titleMobile={`The\nFinn Way`} />
      <ImageWrapper>
        <GatsbyImage
          image={getImage(image)}
          alt="Girl and dog"
          style={{ height: '100%', width: '100%' }}
          imgStyle={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
        />
      </ImageWrapper>
      <Grid>
        <div>
          <TextHeader className="textHeader">Fresh Approach</TextHeader>
          <LargeP className="textBody">
            Finding a pet supplement that ticks off all the right boxes is harder than teaching your
            dog to skateboard. Or play the piano. Or do your taxes. Finn measures up by combining
            trusted research with modern nutrition to create a standout supplement in today's
            confusing market.
          </LargeP>
        </div>
        <div>
          <TextHeader className="textHeader">Better Ingredients</TextHeader>
          <LargeP className="textBody">
            A supplement is only as good as the stuff that goes into it. We take a thoughtful
            approach to our modern product formulation and focus on using thoroughly vetted
            ingredients while steering clear of artificial additives and unnecessary fillers.
          </LargeP>
        </div>
      </Grid>
    </Section>
  )
}

export default TheFinnWay
