import React, { useRef } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import StickyBox from 'react-sticky-box'

import Carousel from 'src/components/shared/Carousel'
import { media } from 'src/styles/util'

import RightArrow128 from 'src/assets/img/icons/right-cursor-128.png'
import RightArrow32 from 'src/assets/img/icons/right-cursor-32.png'
import LeftArrow128 from 'src/assets/img/icons/left-cursor-128.png'
import LeftArrow32 from 'src/assets/img/icons/left-cursor-32.png'

import { IImageCarouselProps } from 'src/types/pdp'

const ImageWrapper = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  margin: 0 auto;
  padding-bottom: 40px;

  display: grid;
  place-items: center;

  ${media.mobile`
    min-width: 93vw;
  `}
  ${media.tablet`
    min-width: 330px;
  `}
  ${media.desktop`
    min-width: 400px;
    max-height: 600px;
  `}
`

const LeftClickBox = styled.div`
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: url(${LeftArrow128}) 128 100, url(${LeftArrow32}) 32 20, pointer;
`

const RightClickBox = styled(LeftClickBox)`
  left: auto;
  right: 0;
  cursor: url(${RightArrow128}) 128 100, url(${RightArrow32}) 32 20, pointer;
`

const Stick = styled(StickyBox)`
  width: 100%;
  height: fit-content;

  display: grid;
`

const ImageCarousel: React.FC<IImageCarouselProps> = ({ carouselImages }) => {
  const carouselRef = useRef(null)

  const handleNext = () => {
    if (carouselRef?.current) carouselRef.current.next()
  }

  const handlePrev = () => {
    if (carouselRef?.current) carouselRef.current.prev()
  }

  return (
    <Stick offsetTop={120} offsetBottom={0}>
      <Carousel speed={5000} ref={carouselRef}>
        {carouselImages.map((image) => (
          <ImageWrapper key={image.alt}>
            <LeftClickBox onClick={handlePrev} />
            <RightClickBox onClick={handleNext} />
            <GatsbyImage
              image={getImage(image.src)}
              alt={image.alt}
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </ImageWrapper>
        ))}
      </Carousel>
    </Stick>
  )
}

export default ImageCarousel
